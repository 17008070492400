.article-aside {

    padding-top: 20px;
    padding-bottom: 40px;

    &__header {
        line-height: 1.1;
    }

    &__header-inner {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: flex-end;
        flex-wrap: wrap;
        padding-bottom: 20px;
    }

    &__heading {
        font-size: 16px;
    }

    &__showall {
        font-size: 14px;
        border-bottom: 1px solid currentColor;
        padding-bottom: 3px;
        transition: border-bottom-color 0.3s $easeOutCubic;
        position: relative;
        top: 3px;
        &:hover,
        &:active {
            border-bottom-color: transparent;
        }
    }

    &__items {
        width: 100%;
        @extend %grid-item;
    }

    &__item {
        margin-bottom: 24px;
        &.-event {
            margin-bottom: 0;
            margin-top: -1px;
        }
    }

    @include smaller-than(l) {

        &__items {
            display: flex;
            width: 100%;
            flex-wrap: wrap;
            align-items: stretch;
            padding-left: 0;
            padding-right: 0;
        }

        &__item {
            display: flex;
            flex: 0 1 auto;
            margin-bottom: 24px;
            > * {
                display: flex;
                width: 100%;
            }
        }

    }

    @include bigger-than(l) {

        padding-top: 0;
        padding-bottom: 0;

        &__inner {
            position: relative;
            // The below is basically a hack to make sure whatever's overflowing (typically; slideshows) in the left side column for the article template gets visually hidden
            &::before {
                content: "";
                display: block;
                width: 50vw;
                height: 100%;
                background-color: $colorBackground;
                position: absolute;
                top: 0; left: $gutterLarge / 2;
                body.is-awards & {
                    background-color: $colorBackgroundAwards;
                }
                body.is-r21 & {
                    background-color: $colorBackgroundR21;
                }
            }
        }

        &__header {
            position: relative;
        }

        &__header-inner {
            position: absolute;
            left: 0; bottom: 100%;
            padding-bottom: 26px;
        }

        &__heading {
            font-size: 18px;
        }

        &__items {
            //margin-top: 1px;
            position: relative;
        }

        &__item {
            width: 100%;
            margin-bottom: 30px;
            padding-left: 0;
            padding-right: 0;
            &.-nth-4,
            &.-nth-5,
            &.-nth-6,
            &.-nth-7,
            &.-nth-8,
            &.-nth-9 {
                @include screenreader;
            }
        }

        /*
        *   Hide the excerpt in magazine teasers, because reasons
        *
         */
        .magazine-teaser__excerpt {
            @include screenreader;
        }

    }

}