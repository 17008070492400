.newsletter-btn {

    display: inline-block;
    width: auto;
    flex: 0 0 auto;
    padding: 0 20px;
    text-align: center;
    background: $colorWhite;
    color: $colorBlack;
    
    height: 40px;
    line-height: 36px;
    white-space: nowrap;
    border-radius: 20px;
    transition: background 0.2s ease;
    
    &:hover {
        background: $colorRedMain;
    }
}