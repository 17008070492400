.block-sponsors {

    $this: &;
    & + &:not(.has-heading) {
        padding-top: 0;
        #{$this}__items {
            border-top: none;
            padding-top: 0;
        }
    }

    img {
        display: block;
        max-width: 100px;
        height: auto;
        margin: 0 auto;
    }

    &__heading {
        color: darken($colorGreyDark, 10%);
        font-size: 18px;
        line-height: 22/18;
        margin: 10px 0 30px;
    }

    &__items {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        justify-content: space-around;
        align-items: center;

        border-top: 1px solid lighten($colorGreyDark, 20%);
        border-bottom: 1px solid lighten($colorGreyDark, 20%);
        padding-top: 40px;
        padding-bottom: 40px;

        .-total-1 &,
        .-total-2 & {
            justify-content: center;
        }

        #{$this}--nobottomborder & {
            border-bottom: none;
        }

        @include bigger-than(m) {
            padding-top: 50px;
            padding-bottom: 50px;
        }
    }

    &__item {
        display: inline-block;
        flex: 0 0 auto;
        margin: 12px 5%;
        &:last-child {
            margin-bottom: 0;
        }

        .-total-1 &,
        .-total-2 & {
            margin: 12px 10%;
        }
    }

    @include bigger-than(sm) {

        &__items {
            flex-wrap: nowrap;
        }

        &__item {
            margin-bottom: 0;
            &:first-child {
                margin-left: 0;
                padding-left: 0;
            }
            &:last-child {
                margin-right: 0;
                padding-right: 0;
            }
        }

    }

}
