/*
*   Awards hero
*   - used for the awards frontpage and all awards archive pages
*/
.awards-hero {

    &__inner {
        @include aspect(390, 210);
        position: relative;
        overflow: hidden;
        background-size: cover;
    }

    &__text {
        width: 100%;
        position: absolute;
        top: 50%; left: 50%;
        z-index: 2;
        transform: translate(-50%, -50%);
    }

    &__heading {
        display: inline-block;
        background-color: rgba($colorYellowButtonHover, 0.7);
        padding: 10px 50px 8px;
        font-size: 24px;
        @include line-height(30, 24);
    }

    &__image {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0; left: 0;
    }

    @include bigger-than(m) {

        &__inner {
            @include aspect(1400, 350);
        }

        &__heading {
            font-size: 30px;
        }

    }

    @include bigger-than(l) {

        &__heading {
            font-size: 40px;
            padding-top: 20px;
            @include line-height(49, 40);
        }

    }

}