.awards-archive-page {

    padding-bottom: 20px;

    &__nav {
        padding-top: 30px;
    }

    &__blocks {
        padding-top: 40px;
    }

}