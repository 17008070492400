.block-quote {

    /*padding-top: 30px;
    padding-bottom: 40px;*/

    &__text {
        font-size: 22px;
        margin: 0;
        @include line-height(25, 22);
    }

    &__cite {
        padding-top: 10px;
        &-line {
            color: $colorBlue;
            body.is-page & {
                color: $colorRedMain;
            }
            .-event & {
                color: $colorGreenDark;
            }
            .-marketplace & {
                color: $colorGreyDarkest;
            }
        }
        &-link &-text {
            display: inline;
            border-bottom: 2px solid currentColor;
            transition: border-color .3s $easeOutCubic;
        }
        &-link:hover &-text {
            border-bottom-color: transparent;
        }
    }

    @include bigger-than(m) {

        /*padding-top: 40px;
        padding-bottom: 50px;*/

        &__text {
            font-size: 35px;
            @include line-height(40, 35);
        }

    }

    @include bigger-than(l) {

        &__text {
            margin-left: -$gutterLarge / 2;
        }

        .block-text + & {
            padding-top: 25px;
        }

    }

    /*
    *   Modifiers
    *
    */
    $this: #{&};

    .-nested-article & {

        @include bigger-than(l) {

            &__wrapper {
                width: grid(7, 12);
                margin-left: grid(2, 12);
            }

        }
    }

}