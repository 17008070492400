.modal-slideshow {

    display: flex;
    flex-direction: column;

    width: 100%;
    height: 100%;
    position: fixed;
    top: 0; left: 0;
    color: $colorWhite;

    z-index: 100;

    user-select: none;

    &__bg {
        width: 100%;
        height: 100%;
        position: absolute;
        background-color: #000;
        z-index: 1;
        top: 0; left: 0;
    }
    
    &__header {

        display: flex;

        width: 100%;

        justify-content: space-between;
        align-items: flex-start;

        position: relative;
        z-index: 2;

        padding: 0 18px 20px 0;

    }

    &__items {

        width: 100%;
        position: relative;
        z-index: 2;

        flex: 1 1 100%;

        html.using-mouse & {
            cursor: none;
        }

    }

    &__pointer {
        display: block;
        position: absolute;
        width: 50px;
        height: 50px;
        z-index: 10;
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.3s $easeOutCubic;
        left: -25px;
        top: -80px;
        svg {
            width: 50px;
            height: 50px;
            transition: transform 0.3s $easeInOutCubic;
            transform-origin: center center;
        }
        .-next & svg {
            transform: scale(-1, 1);
        }
        html.using-mouse .-mouseover & {
            opacity: 1;
        }
    }

    &__meta {

        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: baseline;
        padding: 12px 18px 14px;
        position: relative;

        z-index: 2;

        @include bigger-than(m) {
            padding: 12px 20px 20px;
        }

        &-counter {
            text-align: right;
            margin-left: auto;
            font-size: 20px;
            color: #626262;
            white-space: nowrap;
            @include letter-spacing(0.28, 20);
            @include bigger-than(m) {
                font-size: 28px;
                @include letter-spacing(0.39, 28);
            }
        }
    }

    &__heading {
        display: block;
        font-size: 20px;
        line-height: 1;
        padding: 12px 20px 10px 18px;
        flex: 1 1 auto;
        @include letter-spacing(0.28, 20);
        @include bigger-than(m) {
            font-size: 28px;
            padding: 18px 20px 0 26px;
            @include letter-spacing(0.39, 28);
        }
    }

    &__closebtn {

        display: block;
        width: 28px;
        height: 28px;
        appearance: none;
        padding: 0;
        overflow: hidden;
        background-color: transparent;
        cursor: pointer;
        z-index: 10;
        position: relative;

        margin-left: auto;
        margin-top: 15px;

        flex: 0 0 auto;

        &-icon {
            display: block;
            width: 100%;
            height: 100%;
            transform: rotate(-45deg);
            position: absolute;
            top: 0; left: 0;
        }

        &-icon-line {
            display: block;
            width: 100%;
            height: 2px;
            background-color: currentColor;
            transform-origin: center center;
            position: absolute;
            top: 50%;
            margin-top: -1px;
            left: 0;
            transition: color .3s $easeOutCubic;
            &.-first {
                transform: rotate(90deg);
            }
        }

        &:hover &-icon-line {
            background-color: $colorRedButtonHover;
        }

        @include bigger-than(m) {
            width: 36px;
            height: 36px;
        }

    }

    &__item {

        width: 100%;
        height: 100%;
        position: absolute;
        top: 0; left: 0;
        overflow: hidden;

        img {
            display: inline-block;
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center center;
            overflow: hidden;
        }

        &-image-wrap {
            display: block;
            width: 100%;
            height: 100%;
            position: relative;
        }

        &-figure {
            display: block;
            width: 100%;
            height: 100%;
            position: relative;
            z-index: 2;
        }

        &-image {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0; left: 0;
            opacity: 0;
            transition: opacity 0.5s $easeInCubic !important;
            background-position: center center !important;
            @include gpu;
            &.lazyloaded {
                opacity: 1;
            }
        }

    }

    /*
    *   Flick you too!
    *
     */

    .flickity-enabled:focus { outline: none; }

    .flickity-viewport {
        overflow: hidden;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0; left: 0;
    }

    .flickity-slider {
        width: 100%;
        height: 100%;
    }

    .flickity-prev-next-button {
        display: block;
        padding: 0;
        width: 50%;
        height: 100%;
        position: absolute;
        top: 0;
        z-index: -1;
        background-color: transparent;
        &.next {
            right: 0;
        }
        &.previous {
            left: 0;
        }
        svg {
            display: none;
        }
    }

}
