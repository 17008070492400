/* =============================================================================
   Non-Semantic Helper Classes
   ========================================================================== */
.visuallyhidden { border: 0; clip: rect(0 0 0 0); height: 1px; margin: -1px; overflow: hidden; padding: 0; position: absolute; width: 1px; }
.visuallyhidden.focusable:active, .visuallyhidden.focusable:focus { clip: auto; height: auto; margin: 0; overflow: visible; position: static; width: auto; }
.invisible { visibility: hidden; }
.clearfix:before, .clearfix:after { content: ""; display: table; }
.clearfix:after { clear: both; }
//.clearfix { *zoom: 1; } - Note: Commented this out because it throws a warning in iOS, and is a IE6/7 hack anyway (mmikkel)
.sr-only {
	display: block;
	position: absolute;
	overflow: hidden;
	width: 1px;
	height: 1px;
	clip: rect(0 0 0 0);
	border: 0;
	padding: 0;
	margin: 0 -1px -1px 0;
}

.objectfit .fit-object  {
  width: 100%;
  height: 100%;

  &.-contain {
    object-fit: contain;
  }

  &.-cover {
    object-fit: cover;
  }
}

.no-objectfit .fit-object {
  &.-cover {
    max-width: none;
    max-height: none;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &.-contain {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}