.block-text-expandable {

    &__toggle {
        display: flex;
        margin-top: 10px;
        align-items: center;
        font-size: 16px;
        line-height: 24px;
        color: $colorRedMain;
        width: auto;
        float: left;
        clear: both;
        text-decoration: none !important;
        
        .icon {
            display: block;
            flex: 0 0 auto;
            width: 14px;
            height: 7px;
            margin-right: 8px;
            position: relative;
            top: 2px;
            svg {
                display: block;
                width: 100%;
                height: 100%;
            }
        }
        
        &--expand .icon {
            transform: rotate(-180deg);
        }
        
        &--expand {
            .block-text-expandable__content:target & {
                display: none !important;
            }
        }
    }
    
    
    &__expand-wrapper {
        overflow: hidden;
        height: 0;

        .block-text-expandable__content:target & {
            height: auto;
        }
    }
    
    &__text,
    &__image {
        margin-top: 24px;
    }
}
