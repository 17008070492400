.features-module {

    width: 100%;
    position: relative;

    @include smaller-than(m) {
        flex: 0 0 auto;
        width: calc(100% + #{($gutterSmall * 2)}) !important;
        left: -$gutterSmall;
        border-top: 1px solid $colorGreyMegamenu;
        border-bottom: 1px solid $colorGreyMegamenu;
        margin: 15px 0;
    }

    &__wrap {
        width: 100%;
        padding: 26px 0 30px;
    }

    &__header {

        width: 100%;
        margin-bottom: 20px;

        @include bigger-than(m) {
            margin-bottom: 40px;
        }

        &-wrap {

            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: relative;
            min-height: 26px;

            @include bigger-than(m) {
                > * {
                    position: relative;
                    z-index: 2;
                }
                &::before {
                    content: "";
                    display: block;
                    width: 100%;
                    height: 1px;
                    position: absolute;
                    left: 0;
                    top: 50%;
                    background-color: $colorGreyMegamenu;
                    z-index: 1;
                    transition: transform 0.3s $easeInOutCubic, width 0.3s $easeInOutCubic;
                    transform: translate(0, 0);
                    .-next-enabled & {
                        width: calc(100% - 36px);
                    }
                    .-prev-enabled & {
                        width: calc(100% - 36px);
                        transform: translate(36px, 0);
                    }
                    .-prev-enabled.-next-enabled & {
                        width: calc(100% - #{36px * 2});
                        transform: translate(36px, 0);
                    }
                }
            }

            @include smaller-than(m) {
                padding: 0 $gutterSmall;
            }

        }

        &-heading {

            order: 1;
            padding: 0 30px;
            font-size: 15px;
            @include letter-spacing(0.58, 15);
            @include line-height(18, 15);

            @include bigger-than(m) {
                font-size: 14px;
                padding: 0 20px;
                background-color: $colorBackground;
                @include letter-spacing(0.54, 14);
                @include line-height(15, 14);
            }

        }

        &-navbtn {
            display: block;
            flex: 0 0 auto;
            width: 26px;
            height: 26px;
            border-radius: 100%;
            border: 1px solid $colorGreyMedium;
            background-color: $colorBackground;
            position: relative;
            padding: 0;
            cursor: default;
            pointer-events: none;
            transition: background 0.15s $easeOutCubic, border-color 0.15s $easeOutCubic, transform 0.3s $easeInOutCubic, opacity 0.2s;

            visibility: hidden;

            .-has-slider & {
                visibility: visible;
            }

            svg {
                position: absolute;
                left: 50%;
                top: 50%;
                width: 6px;
                height: 12px;
                margin: -6px 0 0 -3px;
            }
            path {
                stroke: $colorGreyMedium;
                transition: stroke 0.1s $easeOutCubic;
            }
            .icon {
                display: block;
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0; top: 0;
            }
            &.-prev {
                order: 0;
            }
            &.-next {
                order: 2;
            }
            .-prev-enabled &.-prev,
            .-next-enabled &.-next {
                cursor: pointer;
                pointer-events: auto;
                border-color: currentColor;
                path {
                    stroke: currentColor;
                }
            }
            
            &:hover,
            &:active {
                border-color: $colorBlack !important;
                background-color: $colorBlack;
                
                path {
                    stroke: $colorWhite !important;
                }
            }
            
            
            @include bigger-than(m) {
                opacity: 0;
                border-color: $colorGreyMegamenu !important;
                path {
                    stroke: $colorGreyMedium !important;
                }
                &.-prev {
                    transform: translate(-120%, 0);
                }
                &.-next {
                    transform: translate(120%, 0);
                }
                .-prev-enabled &.-prev,
                .-next-enabled &.-next {
                    opacity: 1;
                    transform: translate(0, 0);
                }
            }
        }

    }

    &__list {

        width: 100%;
        display: flex;

        html.no-js & {
            @include scrollableX;
        }

        @include smaller-than(m) {
            padding-left: $gutterSmall;
            padding-right: $gutterSmall;
        }

        @include bigger-than(l) {
            position: relative;
            overflow: hidden;
            &::before,
            &::after{
                content: "";
                display: block;
                background-color: $colorBackground;
                width: $gutterLarge / 2;
                height: 100%;
                position: absolute;
                left: 0; top: 0;
                z-index: 2;
            }
            &::after {
                left: auto;
                right: 0;
            }
        }

        &-item {
            display: flex;
            flex: 0 0 auto;
            align-items: flex-end;
            @include smaller-than(sm) {
                width: 100% !important;
            }
        }

    }

    &__item {

        width: 100%;

        a {
            display: block;
        }
        
        &-image {
            @include aspect(216, 246);
            position: relative;
            overflow: hidden;
            &-image {
                width: 100%;
                position: absolute;
                left: 0;
                bottom: -1px;
                background-color: $colorGreyLight;
                background-size: cover;
                background-repeat: no-repeat;
            }
        }

        &-text {
            display: block;
            padding: 10px 0;

            @include bigger-than(m) {
                padding: 12px 0 0;
            }
        }

        &-meta {
            padding: 0 0 9px;
            position: relative;
            > * {
                transform: translate(0, 0);
                transition: transform 0.3s $easeOutCubic;
            }
            &::before {
                display: block;
                content: "";
                width: 8px;
                height: 8px;
                position: absolute;
                top: 50%;
                left: 0;
                margin-top: -8px;
                border-radius: 100%;
                overflow: hidden;
                background-color: $colorBlue;
                pointer-events: none;
                opacity: 0;
                transform: scale(0, 0);
                transform-origin: center center;
                transition: opacity 0.15s $easeOutCubic, transform 0.4s 0.15s $easeOutBack;
            }

            .-mouseover &,
            a:hover & {
                > * {
                    transform: translate(13px, 0);
                }
                &::before {
                    opacity: 1;
                    transform: scale(1, 1);
                }
            }

            @include bigger-than(m) {
                padding: 0 0 8px;
            }
        }

        &-heading {

            font-size: 15px;
            @include line-height(18, 15);

            @include smaller-than(m) {
                font-weight: $fontWeightRegular;
            }

            @include bigger-than(m) {
                font-size: 15px;
                @include line-height(19, 15);
            }

        }

    }

    /*
    *   A very flickle thing
    *
     */

    .flickity-enabled {
        position: relative;
    }

    .flickity-enabled:focus { outline: none; }

    .flickity-viewport {
        position: relative;
        height: 100%;
    }

    .flickity-slider {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    /* draggable */

    .flickity-enabled.is-draggable {
        -webkit-tap-highlight-color: transparent;
        tap-highlight-color: transparent;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .flickity-enabled.is-draggable .flickity-viewport {
        cursor: move;
        cursor: grab;
        cursor: -moz-grab;
        cursor: -webkit-grab;
    }

    .flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
        &, *, a {
            cursor: grabbing;
            cursor: -moz-grabbing;
            cursor: -webkit-grabbing;
        }
    }

}