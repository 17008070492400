.four-oh-four {

    &__heading {
        line-height: 1.1;
        margin: 60px 0 15px;
        display: block;
        font-size: 48px;

        @include bigger-than(s) {
            font-size: 120px;
            margin-top: 100px;
        }

        @include bigger-than(m) {
            font-size: 144px;
            margin-top: 180px;
        }
    }

    &__copy {
        font-size: 20px;
        @include line-height(26, 20);
        @include letter-spacing(0.17, 20);
        padding: 0 0 30px;

        @include bigger-than(s) {
            font-size: 24px;
            @include line-height(31, 24);
            @include letter-spacing(0.17, 24);
            padding: 0 0 50px;
        }
    }

}