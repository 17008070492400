.block-banner {

    &.-last {
        padding-bottom: 0 !important;
    }

    img, picture {
        display: block;
        width: 100%;
    }

    &__image-wrapper {
        padding-left: 0;
        padding-right: 0;
    }

    &__image {
        position: relative;
        width: 100%;
        background-size: cover;
        height: 0;
        padding-bottom: (300/380) * 100%;
        
        @include bigger-than(sp) {
            padding-bottom: (305/770) * 100%;
        }
        @include bigger-than(mp) {
            padding-bottom: (380/1400) * 100%;
        }
        
        img {
            position: absolute;
            left: 0;
            top: 0;
        }
    }

    /*
    *   Modifiers
    *
    */
    $this: #{&};

    &.-nested-article {

        @include bigger-than(l) {
            #{$this}__image-wrapper {
                max-width: calc(#{(12/16) * 100%} - #{$gutterLarge});
            }
        }

        @include bigger-than(lp) {
            #{$this}__image-wrapper {
                max-width: calc(#{(12/16) * 100%} - #{$outerMarginLarge});
            }
        }

        @include bigger-than(xl) {
            #{$this}__image-wrapper {
                // FML - refactor this shit
                padding-right: calc(((100vw - #{($widthXL + 0px) - ($outerMarginLarge * 2) - ($gutterXLarge * 2)}) + (#{(6/12) * $maxContentWidth + $gutterLarge / 2})) / 2);
                max-width: none;
            }
        }

    }

}