.r21-event-header {

    width: 100vw;
    position: relative;
    z-index: 1;

    &::before {
        content: "";
        width: 100%;
        height: 450px;
        display: block;
        position: fixed;
        top: 0; left: 0;
        z-index: 1;
        background: linear-gradient(to bottom, rgba(0,0,0,0.4) 0%,rgba(0,0,0,0) 100%);
    }

    // Makes the content below the header stay on top of the header PARTY ON
    & + * {
        background-color: $colorBackgroundR21;
        position: relative;
        z-index: 2;
    }

    &.-has-image {
        margin-top: -$headerHeightSmall;
        @include bigger-than(m) {
            margin-top: -$headerHeightMedium;
        }
    }

    &.-has-image &__inner {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        width: 100%;
        height: 100vh;
    }

    &__image {
        width: 100vw;
        flex: 1 1 100vh;
        z-index: 0;


        .-image-hidden & {
            opacity: 0 !important;
            pointer-events: none;
        }

        &-wrapper {
            z-index: -1;
            will-change: transform;
        }

        &-wrapper,
        &-image {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0; left: 0;
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;
        }

        &-image {

            will-change: opacity;

            &.lazyload,
            &.lazyloaded {
                transition: opacity 0.5s $easeInSine;
            }
            html.js & {
                visibility: hidden;
            }
            html.js .-js-initialized & {
                visibility: visible;
            }
        }

    }

    &__text {
        flex: 1 1 auto;
        padding: 20px 0 24px;
        background-color: $colorBackgroundR21;

        position: relative;
        z-index: 2;

        @include gpu;

        @include bigger-than(m) {
            padding: 25px 0 35px;
            z-index: 1;
        }

        &-inner {
            position: relative;
            min-height: 12vh;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;

            @include bigger-than(m) {
                min-height: 0;
                display: block;
            }
        }
    }

    &__heading {
        font-size: 30px;
        @include line-height(34, 30);

        @include bigger-than(m) {
            font-size: 36px;
            letter-spacing: 0;
            line-height: normal;
            padding-right: 40px;
        }
    }

    &__leading {

        font-size: 16px;
        line-height: 1.5;

        background-color: $colorBackgroundR21;
        padding-bottom: 30px;
        position: relative;
        z-index: 2;

        @include bigger-than(m) {
            font-size: 18px;
        }

        a {
            //display: inline-block;
            border-bottom: 2px solid currentColor;
            transition: color 0.15s $easeOutCubic, border-color 0.15s $easeOutCubic;
            &:hover,
            &:active {
                color: $colorRedR21;
            }
        }
    }

    &__meta {
        width: 100%;
        font-size: 14px;
        padding: 10px 0 0;
        @include line-height(18, 14);
        overflow: hidden;
        > span {
            display: flex;
            flex-wrap: wrap;
            width: auto;
            float: left;
            margin-left: -30px;
        }
        .type {
            @include letter-spacing(0.54, 14);
        }
        .date {
            @include letter-spacing(0.61, 14);
        }
        span span {
            margin-left: 15px;
            padding-left: 15px;
            position: relative;
            &::before {
                content: "|";
                display: inline-block;
                font-weight: $fontWeightLight;
                position: absolute;
                left: -1px; top: 0;
            }
        }

        @include bigger-than(m) {
            font-size: 16px;
            padding: 6px 0 0;
            @include line-height(23, 16);
            .date,
            .type {
                @include letter-spacing(0.7, 16);
            }
        }
    }

    &__scrollhelper {

        display: block;
        width: 36px;
        height: 36px;
        border-radius: 100%;
        overflow: hidden;
        background-color: $colorWhite;
        margin: 20px auto 0;

        transition: background-color 0.3s $easeOutCubic;

        path {
            stroke: $colorBlack;
            transition: stroke 0.3s $easeOutCubic;
        }

        .icon {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            transition: transform .3s;

            svg {
                display: block;
                width: 14px;
                height: 7px;
                margin-top: 1px;
                transform: translate(0, 0);
                transition: transform 0.3s $easeOutCubic;
                will-change: transform;
                flex: 0 0 auto;
                position: relative;
            }
        }

        html.no-js &:hover,
        html.js &.-hovered {
            background-color: $colorRedR21;
            svg {
                transform: translate(0, 2px);
            }
            path {
                stroke: $colorBackgroundR21;
            }
        }

        .-scrolled & .icon {
            transform: rotate(180deg);
        }

        @include smaller-than(m) {
            @include screenreader;
        }

        @include bigger-than(m) {
            width: 60px;
            height: 60px;
            position: absolute;
            top: 50%;
            right: 0;
            margin: -26px 0 0;
            padding: 0;
            .icon svg {
                width: 24px;
                height: 12px;
                margin-top: 2px;
            }

            html.no-js &:hover,
            html.js &.-hovered {
                svg {
                    transform: translate(0, 5px);
                }
            }

        }

    }

}