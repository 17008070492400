.frontpage {
    padding-bottom: 20px;
    overflow-x: hidden;

    @include bigger-than(m) {
        padding-bottom: 40px;
    }
    
    &__top {
        @include bigger-than(l) {
            margin-top: 25px;
        }

        &-inner {
            width: 100%;
            @include bigger-than(l) {
                width: 75%;
                float: left;
            }
        }

    }
    
    &__highlighted-area {
        width: 100%;
        margin-bottom: 24px;
        
        @include bigger-than(l) {
            margin-bottom: 50px;
        }
    }
    
    &__item-wrapper {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        align-items: stretch;
    }

    &__grid-item {

        display: flex;
        flex: 0 1 auto;
        margin-bottom: 24px;
        
        > * {
            display: flex;
            width: 100%;
        }

        html.js & {
            visibility: hidden;
        }
        html.js .-js-initialized & {
            visibility: visible;
        }

        @include smaller-than(sm) {
            width: 100%;
        }

        @include bigger-than(l) {
            margin-bottom: 50px;
        }

    }

    &__statement {
        clear: both;
        width: 100%;
        padding: 0 20px;
        margin-bottom: 14px;
        font-size: 22px;
        line-height: (28/22);
        display: block !important;
        margin-left: auto;
        margin-right: auto;
        max-width: 100%;
        padding-top: 25px;
        padding-bottom: 25px;
        
        @include bigger-than(s) {
            font-size: 22px;
            line-height: (28/22);
        }
        
        @include bigger-than(sp) {
            font-size: 24px;
            line-height: (32/24);
            max-width: 75%;
        }
        
        @include bigger-than(m) {
            padding-top: 20px;
            padding-bottom: 20px;
            margin-top: 10px;
            margin-bottom: 24px;
            font-size: 26px;
            line-height: (34/26);
            max-width: 70%;
        }
        
        @include bigger-than(l) {
            padding-top: 10px;
            padding-bottom: 10px;
            font-size: 30px;
            line-height: (42/30);
            margin-top: 0;
            margin-bottom: 14px;
            max-width: 60%;
        }
        
        @include bigger-than(xl) {
            font-size: 36px;
            line-height: (48/36);
        }

        a {
            box-shadow: inset 0 -1px 0 currentColor;
            padding-bottom: 1px;
            transition: color 0.2s ease;
            
            @include bigger-than(m) {
                box-shadow: inset 0 -1.5px 0 currentColor;
            }
        
            &:hover {
                color: $colorRedMain;
            }	
        }

        &-icon {
            display: block;
            width: 36px;
            height: 36px;
            margin: 0 auto 10px auto;
            
            
            @include bigger-than(m) {
                width: 42px;
                height: 42px;
                margin: 0 auto 13px auto;
            }
        }

        svg {
            width: 100%;
            height: 100%;
        }

        path {
            fill: $colorBlack;
        }
        
        p {
            @include bigger-than(m) {
                display: inline;
            }
        }
    }
    
    .teasers-listing {
        padding-top: 0;
    }
}