@keyframes beat {
    0% {
        transform: scale(1, 1);
    }
    50% {
        transform: scale(1.1, 1.1);
    }
    0% {
        transform: scale(1, 1);
    }
}

@mixin beatAnimation {
    animation: beat;
    animation-duration: 0.5s;
    animation-iteration-count: 2;
}


@keyframes bounceArrow {
    from {
        transform: translate(0, 0);
    }
    to {
        transform: translate(15%, 0);
    }
}

@mixin bounceArrow {
    animation: bounceArrow;
    animation-duration: 0.25s;
    animation-direction: alternate;
    animation-iteration-count: infinite;
}