.feed-sidebar {
    position: relative;
    height: 100%;
    width: 100%;

    &__list-wrapper {
        overflow: auto;
        position: relative;
        height: calc(100% - 40px);
        width: 100%;
        border-top: 1px solid $colorGreyLight;
    }

    &__list {
        border-left: 1px solid $colorGreyLight;
        border-right: 1px solid $colorGreyLight;
    }

    &__list-item {
        border-bottom: 1px solid $colorGreyLight;
        padding: 20px;

        a {
            transition: color 0.2s ease;

            &:hover {
                color: $colorRedMain;
            }
        }

        &:last-child {
            border-bottom: 0;
        }

        &-meta {
            font-size: 12px;
            line-height: (16/12);
            margin-bottom: 15px;
        }

        &-author {
            letter-spacing: 1px;
        }

        &-image-area {
            width: 150px;
            margin-bottom: 15px;

            img {
                width: 100%;
            }
        }

        &-inner {
            position: relative;
            padding-left: 44px;
        }

        &-icon {
            position: absolute;
            left: 0;
            top: 4px;
        }

        &-text {
            font-size: 16px;
            line-height: (20/16);
        }
    }

    &__all-link {
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        background: $colorGreyBackground;
        border: 1px solid $colorGreyMegamenu;
        z-index: 2;
        font-size: 13px;
        line-height: 1;
        letter-spacing: 1px;
        padding: 13px 6px;
        transition-property: background, color, padding;
        transition-duration: 0.1s, 0.1s, 0.3s;
        transition-timing-function: ease, ease, $easeOutSine;
        
        &:hover {
            background: $colorBlack;
            color: $colorWhite;
            padding-right: 0;
        }
        
        &-arrow {
            display: inline-block;
            width: 30px;
            height: 1px;
            background: $colorBlack;
            position: relative;
            top: -1px;
            margin-right: 10px;
            vertical-align: middle;
            transition-property: background, color, width;
            transition-duration: 0.1s, 0.1s, 0.3s;
            transition-timing-function: ease, ease, $easeOutSine;
            
            .feed-sidebar__all-link:hover & {
                background: $colorWhite;
                width: 36px;
            }

            &:after,
            &:before {
                content: ' ';
                display: block;
                width: 6px;
                height: 1px;
                position: absolute;
                right: 0;
                background: $colorBlack;
                transform-origin: 100% 50%;
                transition-property: background, color;
                transition-duration: 0.1s;
                transition-timing-function: ease;
                
                .feed-sidebar__all-link:hover & {
                    background: $colorWhite;
                }
            }

            &:after {
                transform: rotate(-45deg);
            }
            &:before {
                transform: rotate(45deg);
            }
        }
    }
}