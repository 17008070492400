/*
*   Main, generic article template
*
*/
.article {

    /*
    *   Smaller than large
    *
    */
    @include smaller-than(l) {

        // Some flexbox voodoo for source ordering the elements
        display: flex;
        flex-direction: column;

        > * {
            width: 100%;
        }

    }

    @include bigger-than(l) {

        min-height: 100vh;

        // Some more flexbox voodoo - the following will render the aside to the left for large screens
        position: relative;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        width: 100%;

        > * {
            width: 100%;
            order: 1;
        }

    }

    &__header {

        padding-bottom: 18px;

        @include smaller-than(l) {
            order: 2;
        }

        @include bigger-than(m) {
            // Bigger than medium, but smaller than large
            @include smaller-than(l) {
                order: 1;
            }
        }

        @include bigger-than(l) {
            order: 1;
            padding-top: 0;
            padding-bottom: 70px;
        }
    }

    // Metadata - publish date etc.
    &__meta {

        padding-top: 5px;
        padding-bottom: 15px;
        order: 0;

        &-entrymeta {

            @include bigger-than(m) {
                @include screenreader;
            }
        }

        body.is-awards & {
            padding-top: 20px;

            @include bigger-than(m) {
                padding-top: 30px;
            }

            @include bigger-than(l) {
                padding-top: 50px;
            }

        }

        @include bigger-than(l) {
            order: 0;
            padding-top: 20px;
            padding-bottom: 10px;
            .indexlink {
                @include screenreader;
            }
        }

    }

    // The featured image
    &__image {
        padding-bottom: 5px;

        img {
            display: block;
            width: 100%;
        }
        &-bg {
            background-size: cover;
        }
        &-caption {
            font-size: 11px;
            padding-top: 2px;
            @include letter-spacing(0.5, 11);
            @include line-height(15, 11);
        }

        @include smaller-than(m) {
            body.is-article.-event & {
                padding-bottom: 20px;
            }
        }

        @include bigger-than(m) {
            padding-top: 20px;
            padding-bottom: 25px;

            & + &__blocks {
                padding-top: 5px;
            }

        }

        @include smaller-than(l) {
            order: 1;
        }

        @include bigger-than(m) {
            // Bigger than medium, but smaller than large
            @include smaller-than(l) {
                order: 2;
            }
        }

        @include bigger-than(l) {
            order: 3;
            padding-top: 0;
            padding-bottom: 60px;

            & + &__blocks {
                padding-top: 20px;
            }
        }
    }

    &__heading {
        font-size: 26px;
        padding-top: 10px;
        @include line-height(33, 26);

        @include bigger-than(m) {
            font-size: 36px;
            line-height: 1.2;
        }

        @include bigger-than(lp) {
            font-size: 48px;
            line-height: 1.2;
        }
    }

    &__leading {
        font-size: 18px;
        @include line-height(25, 18);
        padding-top: 8px;
        a {
            color: $colorBlue;
            border-bottom: 1px solid currentColor;
            transition: border-bottom-color 0.3s $easeOutCubic;
            &:hover {
                border-bottom-color: transparent;
            }
            .-marketplace & {
                color: $colorGreyDarkest;
            }
            .-event & {
                color: $colorGreenDark;
            }
            body.is-page & {
                color: $colorRedMain;
            }
        }
        em, i {
            font-style: italic;
        }
        strong {
            font-weight: $fontWeightMedium;
        }

        @include bigger-than(m) {
            font-size: 18px;
            @include line-height(25, 18);
        }

        @include bigger-than(l) {
            padding-top: 8px;
        }
    }

    &__blocks {

        @include smaller-than(l) {
            order: 3;
        }

        @include bigger-than(m) {
            padding-bottom: 40px;

            .r21-event-header + & {
                padding-top: 15px;
            }

        }

        @include bigger-than(l) {
            order: 4;
        }
    }

    &__aside {

        @include smaller-than(l) {
            order: 4;
            padding-left: 0;
            padding-right: 0;
        }

        @include bigger-than(l) {
            width: 100%;
            height: 0;
            order: 2;
            position: relative;
            z-index: 2;
        }
    }

    /*
    *   A colored block at the bottom of the content, for some reason
    *
     */
    .blocks-ender {
        height: 3px;
        background-color: $colorBlue;
        //margin-top: -20px;
        margin-bottom: 30px;
        .-marketplace & {
            background-color: $colorGreyDarkest;
        }
        .-event & {
            background-color: $colorGreenDark;
        }
        body.is-awards & {
            background-color: currentColor;
        }
        body.is-r21 &,
        body.is-page & {
            display: none;
        }
    }

}

/*
*   Magazine article
*
 */
.magazine-article {

    .article__blocks {
        padding-top: 20px;
    }

    &__aside {

        padding-top: 24px;

        &-header {
            color: currentColor;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            align-items: baseline;
            padding-bottom: 20px;
        }
        &-heading {
            font-size: 16px;
            @include letter-spacing(0.5, 16);
            @include line-height(23, 16);
        }
        &-indexlink {
            font-size: 14px;
            text-decoration: underline;
            @include line-height(25, 14);
            &:hover {
                text-decoration: none;
            }
        }
        &-items {
            display: flex;
            flex-wrap: wrap;
            align-items: stretch;
        }
        &-item {
            display: flex;
            flex: 0 1 auto;
            float: none;
            margin-bottom: $gutterSmall;
            > * {
                display: flex;
            }
        }
    }

    @include bigger-than(m) {
        &__aside {
            padding-top: 40px;
            &-item {
                margin-bottom: $gutterMedium;
            }
        }
    }

    @include bigger-than(l) {
        &__aside {
            &-item {
                margin-bottom: $gutterLarge;
            }
        }
    }

}
