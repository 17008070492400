.t-graphik {
    @include fontGraphik;
}

.t-caslon {
    @include fontCaslon;
}

.t-size {
    &-h-xl {
        font-size: 25px;
        line-height: (30/25);

        @include bigger-than($widthM) {
            font-size: 45px;
            line-height: (55/45);
        }
        @include bigger-than($widthL) {
            font-size: 50px;
            line-height: (60/50);
        }
        @include bigger-than($widthXL) {
            font-size: 60px;
            line-height: (70/60);
        }
    }
}

.t-weight-light {
    font-weight: $fontWeightLight;
}

.t-weight-regular {
    font-weight: $fontWeightRegular;
}

.t-weight-medium {
    font-weight: $fontWeightMedium;
}

.t-weight-semibold {
    font-weight: $fontWeightSemibold;
}

.t-weight-bold {
    font-weight: $fontWeightBold;
}

.t-upper {
    text-transform: uppercase;
}

.t-left {
    text-align: left;
}

.t-center {
    text-align: center;
}

.t-right {
    text-align: right;
}

.t-italic {
    font-style: italic;
}

.t-antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/*
*	Grafill helpers (mostly TODO)
*
*/

// Article metadata string (type, pubdate)
/*.t-meta {

    .date {

    }
    font-size: 12px;
    line-height: 1.1;
}*/

// Used for entry type in meta strings
// These are going away, André. Promise <3
/*.t-meta-type {
    @extend .t-upper;
    @extend .t-weight-bold;
    font-size: 12px;
    letter-spacing: (0.7 / 12) + 0em;
    line-height: 13/12;
}

.t-meta-date {
    @extend .t-upper;
    font-size: 12px;
    letter-spacing: (0.7 / 12) + 0em;
    line-height: 13/12;
}*/

// Basic heading
/*
.t-heading {

    @extend .t-upper;
    @extend .t-graphik;
    @extend .t-weight-regular;

    font-size: 24px;

    @include letter-spacing(0.5, 24);
    @include line-height(29, 24);

    body.is-awards & {
        padding-top: 18px;
        padding-bottom: 22px;
    }

    @include bigger-than(m) {

        body.is-awards & {
            padding-top: 35px;
            padding-bottom: 33px;
            font-size: 36px;
            @include line-height(48, 36);
            @include letter-spacing(1.12, 36);
        }

    }

    @include bigger-than(l) {

        body.is-awards & {
            padding-top: 56px;
            padding-bottom: 85px;
            font-size: 48px;
            @include line-height(48, 48);
            @include letter-spacing(1.5, 48);
        }

    }

}
*/
