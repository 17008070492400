.block-text {

    // Hide anchor links for users that aren't logged in
    body:not(.is-logged-in) & a.anchor:not(:focus) {
        @include screenreader;
    }

    &:not(.-colored) + &:not(.-colored),
    &.-colored + &.-colored,
    .block-table:not(.-colored) + &:not(.-colored),
    .block-links.-colored + &.-colored {
        padding-top: 0;
    }

    @include bigger-than(m) {
        &:not(.-colored) + &:not(.-colored),
        &.-colored + &.-colored {
            margin-top: -22px;
        }
    }

    @include bigger-than(l) {

        padding-top: 50px;
        padding-bottom: 50px;

        &:not(.-colored) + &:not(.-colored),
        &.-colored + &.-colored {
            margin-top: -25px;
        }

    }

    &__text {

        h1, h2 {
            width: 100%;
            font-size: 18px;
            font-weight: $fontWeightMedium;
            margin: 30px 0 20px;
            @include line-height(22, 18);

            & + p {
                margin-top: 0;
            }

            @include bigger-than(m) {
                font-size: 22px;
                margin: 40px 0 14px;
                @include line-height(25, 22);
            }

        }

        h3, h4, h5, h6 {
            width: 100%;
            font-weight: $fontWeightMedium;
            margin: 0;
            & + p {
                margin-top: 0;
            }
        }

        a {
            display: inline;
            color: $colorBlue;
            border-bottom: 1px solid currentColor;
            transition: border-bottom-color 0.2s $easeOutCubic, color 0.2s $easeOutCubic;
            &:hover {
                color: currentColor;
                border-bottom-color: transparent;
            }
            .-marketplace & {
                color: $colorGreyDarkest;
            }
            .-event & {
                color: $colorGreenDark;
            }
            body.is-r21 & {
                color: $colorRedR21;
            }
            body.is-page & {
                color: $colorRedMain;
            }
            body.is-awards & {
                color: $colorBlack;
                border-bottom-width: 2px;
                border-bottom-color: currentColor;
            }
        }

        p {
            margin: 24px 0;
            line-height: 1.5;
        }

        em, i {
            font-style: italic;
        }

        strong {
            font-weight: $fontWeightMedium;
        }

        ul {
            margin: 24px 0;
            line-height: 1.5;
            li {
                padding-left: (30 / 16) + 0em;
                position: relative;
                &::before {
                    content: "";
                    display: block;
                    width: 14px;
                    height: 2px;
                    background-color: $colorBlue;
                    position: absolute;
                    top: (13 / 16) + 0em;
                    left: 4px;
                }
                .-marketplace &::before {
                    background-color: $colorGreyDarkest;
                }
                .-event &::before {
                    background-color: $colorGreenDark;
                }
                body.is-page &::before {
                    background-color: $colorRedMain;
                }
                body.is-r21 &::before {
                    background-color: $colorRedR21;
                }
                body.is-awards &::before {
                    background-color: currentColor;
                }
            }
        }

        ol {
            margin: 24px 0;
            line-height: 1.5;
            counter-reset: item;
            li {
                padding-left: (30 / 16) + 0em;
                position: relative;
                &::before {
                    display: inline-block;
                    content: counter(item) ".";
                    counter-increment: item;
                    color: $colorBlue;
                    position: absolute;
                    top: 0; left: 0;
                    font-weight: $fontWeightMedium;
                }
                .-marketplace &::before {
                    color: $colorGreyDarkest;
                }
                .-event &::before {
                    color: $colorGreenDark;
                }
                body.is-page &::before {
                    color: $colorRedMain;
                }
                body.is-r21 &::before {
                    color: $colorRedR21;
                }
                body.is-awards &::before {
                    color: currentColor;
                }
            }
        }

        > *:first-child,
        > a[name]:first-child + * { // Account for anchors (<a name="">)
            margin-top: 0 !important;
        }

        > *:last-child {
            margin-bottom: 0 !important;
        }

    }

    &__aside {
        @include bigger-than(l) {
            padding-top: 5px;
        }
    }

    /*
    *   Modifiers
    *
    */

    $this: #{&};

    // The share buttons are horizontal up until large
    &.-has-sharebtns {

        #{$this}__aside {
            padding-bottom: 15px;
        }

        @include bigger-than(m) {

            #{$this}__aside {
                padding-bottom: 34px;
            }

        }

        @include bigger-than(l) {

            #{$this}__aside {
                padding-bottom: 0;
            }

        }

    }

    &.-colored {
        background-color: $colorGreyLight;

        body.is-awards &,
        body.is-r21 & {
            background-color: darken($colorGreyLight, 5%);
        }
    }

}
