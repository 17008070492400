.feed-listing {
    &__header {
        padding-top: 10px;
        padding-bottom: 15px;
    }

    &__heading {
        font-size: 36px;
        padding-bottom: 20px;
        @include letter-spacing(1.12, 36);
        @include line-height(48, 36);
    }
    
    &__wrap {
        
    }
    
    &__inner {
        position: relative;
    }

    &__sub {
        display: inline;

        @include smaller-than(m) {
            display: block;
            margin-bottom: 30px;
        }
    }

}