.block-list {

    &__heading {
        color: $colorRedMain;
        font-size: 20px;
        @include line-height(22, 20);
        .label {
            display: inline-block;
            width: 100%;
            padding-bottom: 15px;
        }
    }

    &__item {
        border-bottom: 1px solid $colorRedMain;
        &.-first {
            border-top: 1px solid $colorRedMain;
        }
        a {
            overflow: hidden;
        }
    }

    &__item-inner {

        width: 100%;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        align-items: center;
        position: relative;
        padding: 20px $gutterSmall;

    }

    &__item-icon {
        display: block;
        position: absolute;
        width: 73px;
        padding-left: 7px;
        left: $gutterSmall;
        top: 50%;
        transform: translate(0, -50%);
        font-size: 24px;
        line-height: 1;
        color: $colorRedMain;
        @include letter-spacing(0.5, 24);

    }

    &__item-text {
        padding-left: 7px;
        .-linked & {
            padding-left: 73px;
        }
    }

    &__item-title {
        display: block;
        font-size: 18px;
        color: $colorRedMain;
        @include line-height(21, 18);
    }

    &__item-description {
        display: block;
        font-size: 14px;
        padding-top: 2px;
        @include line-height(18, 14);
    }

    .arrow {
        display: block;
        width: 37px;
        height: 2px;
        position: absolute;
        top: 0;
        left: 7px;
        pointer-events: none;
        transition: transform 0.5s $easeOutSine;
        transform: translate(0, 0);
        @include gpu;
        .arrow__line {
            display: block;
            width: 100%;
            height: 1px;
            background-color: currentColor;
            position: absolute;
            top: 0;
            left: 0;
        }
        &__point {
            display: block;
            width: 14px;
            height: 1px;
            position: absolute;
            right: -2px;
            background-color: currentColor;
            &.-upper {
                transform: rotate(45deg);
                top: -5px;
            }
            &.-lower {
                transform: rotate(-45deg);
                top: 5px;
            }
        }
        @include bigger-than(m) {
            width: 48px;
            left: 0;
        }
        @include bigger-than(xl) {
            margin-top: 3px;
        }
    }

    .-linked:hover,
    .-linked:active {
        &, * {
            color: $colorWhite;
        }
        svg g {
            fill: $colorWhite;
        }
        background-color: $colorRedMain;
        &:not(.-asset) .arrow {
            @include bounceArrow;
        }
    }

    .-asset .arrow {
        left: 0;
        top: 50%;
        transform: rotate(90deg) translate(-200%, -50%);
        transition: transform 0.5s $easeOutCubic, opacity 0.3s $easeInSine;
        opacity: 0;
    }

    .-asset .extension {
        display: inline-block;
        transition: transform 0.5s $easeOutCubic, opacity 0.3s $easeInCubic;
        transform: translate(0, 0);
    }

    .-asset:hover .arrow {
        transform: rotate(90deg) translate(0);
        opacity: 1;
    }

    .-asset:hover .extension {
        transform: translate(0, 200%);
        opacity: 0;
    }

    @include smaller-than(l) {

        &__wrapper {
            padding-left: 0;
            padding-right: 0;
        }

        &__items {
            padding-left: 0;
            padding-right: 0;
        }

    }

    @include bigger-than(m) {

        &__item-inner {
            padding: 39px $gutterMedium;
        }

        &__item-icon {
            width: 88px;
            left: $gutterMedium;
        }

        &__item-text {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: 100%;
            padding: 0 !important;
        }

        &__item-title {
            width: 40%;
            flex: 0 0 auto;
            .-linked & {
                padding-left: 88px;
            }
        }

        &__item-description {
            width: 60%;
            padding-top: 0;
            flex: 0 0 auto;
            padding-left: $gutterMedium;
        }

    }

    @include bigger-than(l) {

        &__heading {
            font-size: 22px;
            @include line-height(27, 22);
        }

        &__item-inner {
            padding: 38px 0;
        }

        &__item-icon {
            width: 128px;
            font-size: 28px;
            padding-left: 0;
            left: 20px;
            svg {
                width: 50px;
                height: 18px;
            }
        }

        &__item-title {
            width: 45%;
            font-size: 20px;
            @include line-height(27, 20);
            padding: 0 0 0 20px;
            .-linked & {
                padding-left: 128px;
            }
        }

        &__item-description {
            width: 55%;
            padding-left: $gutterLarge;
            padding-right: $gutterLarge/2;
        }

    }

    @include bigger-than(xl) {
        &__heading {
            font-size: 28px;
            @include line-height(32, 28);
        }

        &__item-title {
            font-size: 24px;
            @include line-height(32, 24);
        }
        &__item-description {
            font-size: 18px;
            @include line-height(24, 18);
        }
    }

    /*
    *   Awards context
    *
    */
    body.is-awards & {

        &__heading {
            color: $colorBlack;
            background-color: $colorYellow;
            .label {
                padding-left: 7px;
                padding-top: 15px;
            }
        }

        &__item {
            background-color: $colorWhite;
            border-color: $colorBackgroundAwards;
            border-bottom-width: 2px;
            border-top: none;
        }

        &__item-icon {
            color: $colorBlack;
        }

        &__item-title {
            color: $colorBlack;
            font-weight: $fontWeightMedium;
        }

        .-linked:hover,
        .-linked:active {
            &, * {
                color: $colorBlack;
            }
            background-color: lighten($colorYellow, 25%);
        }

        @include bigger-than(m) {

            &__heading .label {
                padding-left: 0;
            }

        }

        @include bigger-than(l) {

            &__heading {
                background-color: transparent;
                .label {
                    width: calc(100% - #{$gutterLarge});
                    padding-left: 20px;
                    padding-right: 20px;
                    background-color: $colorYellow;
                }
            }

        }

    }

}