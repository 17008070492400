/*
*   Magazine header
*
*/
.magazine-header {

    position: relative;
    z-index: 1;

    &::before {
        content: "";
        width: 100%;
        height: 450px;
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%);
    }

    // Makes the content below the header stay on top of the header PARTY ON
    & + * {
        background-color: $colorWhite;
        position: relative;
        z-index: 2;
    }

    &.-has-image {
        margin-top: -$headerHeightSmall;
        @include bigger-than(m) {
            margin-top: -$headerHeightMedium;
        }
    }

    &__inner {
        display: flex;
        flex-direction: column;
        width: 100%;
        .-has-image & {
            justify-content: flex-end;
            max-height: calc(100vh + 100px);
        }
        @include bigger-than(m) {
            .-has-image & {
                height: 100vh;
            }
        }
    }

    &__image {

        width: 100%;
        flex: 1 1 100vh;
        z-index: -1;

        .-image-hidden & {
            opacity: 0 !important;
            pointer-events: none;
        }

        &-wrapper {
            z-index: -1;
            will-change: transform;
        }

        &-wrapper,
        &-image {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background-size: cover;
            background-repeat: no-repeat;
        }

        &-image {
            will-change: opacity;
            &.lazyload,
            &.lazyloaded {
                transition: opacity 0.5s $easeInSine;
            }
            html.js & {
                visibility: hidden;
            }
            html.js .-js-initialized & {
                visibility: visible;
            }
        }

    }

    &__text {
        flex: 1 1 auto;
        padding: 0 0 24px;
        background-color: $colorWhite;
        @include gpu;
        order: 1;

        position: relative;
        z-index: 2;

        .-has-image & {
            padding-top: 20px;
            order: initial;
        }

        @include bigger-than(m) {
            padding: 5px 0 0;
            order: 1 !important;
            z-index: 1;
            .-has-image & {
                padding-top: 5px;
                padding-bottom: 20px;
            }
        }

        &-inner {
            @include bigger-than(m) {
                will-change: transform;
                .-has-image & {
                    display: block;
                    transition: transform .3s $easeInOutSine;
                    transform: translate3d(0, -10px, 0);
                }
                .-has-image.-scrolled & {
                    transform: translate3d(0, 20px, 0);
                }
            }
        }

    }

    &__heading {
        font-size: 26px;
        @include letter-spacing(1, 26);
        @include line-height(32, 26);

        @include bigger-than(m) {
            font-size: 40px;
            letter-spacing: 0;
            @include line-height(48, 40);
            margin-bottom: 10px;
            padding-right: 70px;
            max-width: 1020px;
        }
    }

    &__leading {
        font-size: 20px;
        font-family: $fontCaslon;
        @include line-height(25, 20);
        background-color: $colorWhite;
        padding-bottom: 30px;
        @include gpu;

        position: relative;
        z-index: 2;

        @include bigger-than(m) {

            font-size: 18px;
            @include line-height(25, 18);
            padding-bottom: 50px;

            html.js .-has-image & > * {
                opacity: 0;
                will-change: transform, opacity;
                transition: transform .3s $easeInOutSine, opacity .3s .2s $easeInSine;
                transform: translate3d(0, 10px, 0);
            }

            html.js .-has-image.-scrolled & > * {
                transform: translate3d(0, 0, 0);
                opacity: 1;
            }

        }
    }

    &__meta {
        width: 100%;
        height: auto;
        padding-bottom: 20px;
        background-color: $colorWhite;
        @include gpu;
        order: 0;

        z-index: 1;

        .-has-image & {
            order: initial;
        }

        @include bigger-than(m) {

            padding-top: 25px;
            padding-bottom: 10px;
            order: 0 !important;
            position: relative;
            z-index: auto;

            > * {
                width: 100%;
                transition: transform .3s $easeInOutSine, opacity .3s .2s $easeInSine;
                transform: translate3d(0, 0, 0);
            }

            .-scrolled & > * {
                transform: translate3d(0, 10px, 0);
            }

            .-has-image & {
                padding-bottom: 0;
            }

            .-scrolled & {
                z-index: 1;
            }

            html.js .-has-image & .entry-type-menu__type::after {
                transition: opacity .3s $easeInSine, transform .2s $easeOutCubic;
            }
            html.js .-has-image:not(.-scrolled) & .entry-type-menu {
                pointer-events: none;
                &__type::after {
                    opacity: 0;
                }
                &__nav {
                    visibility: hidden;
                }
            }

        }
    }

    &__scrollhelper {
        display: block;
        width: 36px;
        height: 36px;
        border-radius: 100%;
        overflow: hidden;
        background-color: $colorGreyLight;
        margin: 20px auto 0;

        transition: background-color 0.3s $easeOutCubic;

        html.no-js &:hover,
        html.js &.-hovered {
            background-color: $colorBlue;
            svg {
                transform: translate(0, 2px);
            }
            svg path {
                stroke: $colorWhite;
                transition: stroke 0.3s $easeOutCubic;
            }
        }
        html.no-js body.is-awards &:hover,
        html.js body.is-awards &.-hovered {
            background-color: $colorYellowButtonHover;
            svg path {
                stroke: $colorBlack;
            }
        }
        .icon {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            transition: transform .3s;

            svg {
                display: block;
                width: 14px;
                height: 7px;
                margin-top: 1px;
                transform: translate(0, 0);
                transition: transform 0.3s $easeOutCubic;
                will-change: transform;
                flex: 0 0 auto;
                position: relative;
            }
        }

        .-scrolled & .icon {
            transform: rotate(180deg);
        }

        @include bigger-than(m) {
            width: 60px;
            height: 60px;
            margin: 0;
            position: absolute;
            top: -2px;
            right: 0;
            .icon svg {
                width: 24px;
                height: 12px;
                margin-top: 2px;
            }

            html.no-js &:hover,
            html.js &.-hovered {
                svg {
                    transform: translate(0, 5px);
                }
            }
        }
    }

}