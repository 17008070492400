.winners-top {
    padding-top: 30px;
    background: #fff;
    margin-bottom: 30px;

    @include bigger-than(m) {
        padding-top: 50px;
        margin-bottom: 60px;
    }
    @include bigger-than(l) {
        padding-top: 60px;
    }

    &.-is-archive {
        margin-top: 30px;

        padding-top: 1px;

        @include bigger-than(m) {
            padding-top: 20px;
        }
    }

    &__heading {
        font-size: 30px;
        line-height: 1;
        letter-spacing: 0.5px;
        margin-bottom: 20px;

        @include bigger-than(m) {
            font-size: 36px;
            margin-bottom: 22px;
        }
        @include bigger-than(l) {
            font-size: 48px;
            letter-spacing: 1.5px;
        }

        &-link {
            position: relative;
            display: inline-block;
            padding: 10px 40px 12px 24px;
            background-color: $colorYellow;
            border-radius: 9999px;
            transition: color,background-color 0.2s ease;
            
            &:hover {
                color: $colorWhite;
                background-color: $colorBlack;
            }

            @include bigger-than(m) {
                padding: 10px 60px 15px 34px;
            }
        }

        &-link-arrow {
            display: block;
            position: absolute;
            right: 15px;
            top: 21px;
            width: 10px;
            height: 10px;
            transition: border-color 0.2s ease;
            border-right: 2px solid currentColor;
            border-bottom: 2px solid currentColor;
            transform: rotate(45deg);

            @include bigger-than(m) {
                width: 15px;
                height: 15px;
                right: 25px;
                top: 27px;
            }
        }
    }

    &__year-wrapper {
        .-is-archive & {
            display: none;
        }
    }

    &__year-nav {
        overflow: hidden;
        height: 0;

        &:target {
            height: auto;
        }

        &-list {

        }

        &-list-button {
            display: inline-block;
            position: relative;
            font-size: 30px;
            line-height: 1;
            @include letter-spacing(0.5, 30);
            margin-bottom: 12px;
            padding: 0 20px;
            transition: color 0.2s ease;

            &:hover {
                color: $colorBlack;
            }

            @include bigger-than(m) {
                font-size: 36px;
                margin-bottom: 35px;
            }
            @include bigger-than(l) {
                font-size: 48px;
                @include letter-spacing(1.5, 48);
                margin-bottom: 40px;
            }

            &:after {
                content: ' ';
                display: block;
                position: absolute;
                right: 0;
                top: 4px;
                width: 2px;
                height: 26px;
                background: $colorGreyMedium;

                @include bigger-than(m) {
                    height: 31px;
                }

                @include bigger-than(l) {
                    top: 6px;
                    height: 43px;
                }

                li:last-child & {
                    display: none;
                }
            }

            &.-last-on-line {
                &:after {
                    display: none;
                }
            }

        }

        li {
            display: inline-block;
        }
    }

    &__category-nav {
        margin-top: 20px;
        margin-bottom: 20px;

        &-list {

        }

        &-list-button {
            display: inline-block;
            background: $colorBlack;
            color: #fff;
            padding: 13px 20px;
            height: 40px;
            font-size: 14px;
            @include letter-spacing(0.6, 14);
            line-height: 1;
            border-radius: 20px;
            margin: 0 3px 15px 3px;
            transition-property: color, background;
            transition-duration: 0.2s;
            transition-timing-function: ease;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;

            @include bigger-than(m) {
                font-size: 15px;
                padding: 12px 20px;
            }

            @include bigger-than(l) {
                padding: 12px 35px;
            }

            &:hover,
            &.-selected {
                background: $colorYellow;
                color: $colorBlack;
                -webkit-font-smoothing: subpixel-antialiased;
                -moz-osx-font-smoothing: auto;
            }
        }

        li {
            display: inline-block;
        }
    }

    &__special-awards {
        margin-top: 20px;
        margin-bottom: 20px;

        &-list {

        }

        &-link {
            display: inline-block;
            font-size: 15px;
            line-height: 1;
            letter-spacing: 1px;
            margin: 0 10px 10px 10px;
            opacity: 1;
            transition: opacity 0.2s ease;

            @include bigger-than(m) {
                margin: 0 15px 10px 15px;
            }
            
            &:hover {
                opacity: 0.5;
            }
        }

        li {
            display: inline-block;
        }
    }

    &__filter {
        position: relative;
        overflow: hidden;
        padding-bottom: 16px;

        @include bigger-than(m) {
            padding-top: 10px;
            padding-bottom: 30px;
        }

        &-toggle {
            display: block;
            position: relative;
            margin-top: 0px;
            border: 1px solid $colorGreyLight;
            background: #fafafa;
            height: 50px;
            padding: 14px 60px 14px 15px;

            @include bigger-than(m) {
                font-size: 20px;
                padding-top: 11px;
                padding-left: 20px;
            }

            &-category {
                font-size: 12px;
                margin-left: 5px;
                letter-spacing: 1px;

                @include bigger-than(m) {
                    font-size: 14px;
                }
            }

            &-contents {
                display: block;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            &-arrow {
                display: block;
                position: absolute;
                right: 17px;
                top: 18px;
                width: 10px;
                height: 10px;
                border-right: 1px solid $colorBlack;
                border-bottom: 1px solid $colorBlack;
                transform: rotate(45deg);
            }

        }
    }

    &__toggle-wrapper {
        width: 100%;

        @include bigger-than(m) {
            width: 50%;
            float: left;
            padding-right: $gutterMedium/2;
        }

        @include bigger-than(mp) {
            padding-right: $gutterMediumPlus/2;
        }
        @include bigger-than(l) {
            padding-right: $gutterLarge/2;
        }
        @include bigger-than(lp) {
            padding-right: $gutterLargePlus/2;
        }
        @include bigger-than(xl) {
            padding-right: $gutterXLarge/2;
        }
    }

    &__search-wrapper {
        margin-bottom: 15px;

        @include bigger-than(m) {
            width: 50%;
            float: right;
            padding-left: $gutterMedium/2;
        }

        @include bigger-than(mp) {
            padding-left: $gutterMediumPlus/2;
        }
        @include bigger-than(l) {
            padding-left: $gutterLarge/2;
        }
        @include bigger-than(lp) {
            padding-left: $gutterLargePlus/2;
        }
        @include bigger-than(xl) {
            padding-left: $gutterXLarge/2;
        }
    }

    &__search-form {
        position: relative;

        &-input {
            height: 50px;
            width: 100%;
            border: 1px solid $colorGreyLight;
            padding-right: 70px;

            @include bigger-than(m) {
                font-size: 20px;
                padding-left: 20px;
            }
        }

        &-button {
            position: absolute;
            display: block;
            width: 50px;
            height: 50px;
            background: transparent;
            right: 0;
            top: 0;

            @include bigger-than(m) {
                right: 5px;
            }

            svg {
                position: absolute;
                display: block;
                width: 20px;
                height: 20px;
                left: 15px;
                top: 15px;
            }
        }
    }

    &__sub-category-nav {
        height: 0;
        overflow: hidden;
        width: 100%;

        .category-winners__filter:target & {
            height: auto;
        }

        &:target {
            height: auto;
        }

        @include bigger-than(m) {

        }
    }

    &__filter-inner {
    }

    &__filter-list {
        font-size: 14px;
        @include letter-spacing(1, 14);
        padding-top: 15px;
        padding-bottom: 65px;

        @include bigger-than(m) {
            column-count: 2;
            column-gap: $gutterMedium;
        }

        @include bigger-than(l) {
            //column-count: 3;
            //column-gap: $gutterLarge;
        }

        @include bigger-than(lp) {
            column-count: 4;
            column-gap: $gutterLargePlus;
        }

        li {
            border-bottom: 1px solid transparentize($colorBlack, 0.9);
            break-inside: avoid;

            @include smaller-than(m) {
                &:last-child {
                    //border-bottom: 0;
                }
            }
        }

        &-link {
            position: relative;
            display: block;
            padding: 11px 0;
            transition: padding 0.5s $easeOutExpo;

            @include bigger-than(m) {
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }

            &:before {
                content: ' ';
                display: block;
                position: absolute;
                width: 10px;
                height: 10px;
                left: 0;
                top: 14px;
                border-radius: 6px;
                border: 1px solid #D9D91D;
                background: $colorYellow;
                opacity: 0;
                transition: opacity 0.05s ease 0s;
            }
            
            &:hover {
                padding-left: 20px;
                
                &:before {
                    opacity: 1;
                    transition: opacity 0.2s ease 0.1s;
                }
            }

            &.-selected {
                font-weight: $fontWeightBold;
                padding-left: 20px;
                
                &:before {
                    opacity: 1;
                }
            }

            &:hover {

            }
        }
    }

    &__filter-close-wrapper {
        display: none;
        position: absolute;
        bottom: 0;
        width: 100%;

        .category-winners__filter:target & {
            display: block;
        }
    }

    &__filter-close {
        position: relative;
        display: inline-block;
        font-size: 21px;
        @include letter-spacing(1.5, 21);
        background: #fafafa;
        padding: 10px 25px 11px 45px;
        transition: color 0.1s ease;
        &:hover {
            background: $colorYellow;
        }
    }

    &__filter-close-cross {
        position: absolute;
        display: block;
        width: 16px;
        height: 16px;
        left: 15px;
        top: 16px;

        &:before,
        &:after {
            content: ' ';
            display: block;
            width: 22px;
            height: 2px;
            background: $colorBlack;
            position: absolute;
            top: 8px;
            left: 0;
            transform-origin: 50% 50%;
        }

        &:before {
            transform: rotate(45deg);
        }
        &:after {
            transform: rotate(-45deg);
        }
    }
}
