@font-face {
	font-family: 'CaslonPro';
	src: url('../fonts/acaslonpro-regular-webfont.woff2') format('woff2'),
		url('../fonts/acaslonpro-regular-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'CaslonPro';
	src: url('../fonts/acaslonpro-italic-webfont.woff2') format('woff2'),
		url('../fonts/acaslonpro-italic-webfont.woff') format('woff');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'CaslonPro';
	src: url('../fonts/acaslonpro-semibolditalic-webfont.woff2') format('woff2'),
		url('../fonts/acaslonpro-semibolditalic-webfont.woff') format('woff');
	font-weight: 600;
	font-style: italic;
}

@font-face {
	font-family: 'CaslonPro';
	src: url('../fonts/adobe_caslon_pro_semibold-webfont.woff2') format('woff2'),
		url('../fonts/adobe_caslon_pro_semibold-webfont.woff') format('woff');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Graphik';
	src: url('../fonts/graphikweb-regular.woff2') format('woff2'),
		url('../fonts/graphikweb-regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Graphik';
	src: url('../fonts/graphikweb-light.woff2') format('woff2'),
		url('../fonts/graphikweb-light.woff') format('woff');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Graphik';
	src: url('../fonts/graphikweb-medium.woff2') format('woff2'),
		url('../fonts/graphikweb-medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Graphik';
	src: url('../fonts/graphikweb-bold.woff2') format('woff2'),
		url('../fonts/graphikweb-bold.woff') format('woff');
	font-weight: 700;
	font-style: normal;
}
