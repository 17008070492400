/*
*   The grid based event list
*
*/



.events-grid {

    overflow: hidden;
    padding-top: 1px;

    &__inner + .loadmore {
        padding-top: 40px;
    }

    &__items {
        display: flex;
        width: 100%;
        position: relative;
        flex-wrap: wrap;
    }

    &__item {
        display: flex;
        padding: 0; // Override the grid-item padding
        position: relative;
        margin-top: -1px;
        > * {
            width: 100%;
        }
    }

    html.js & [data-eventitem-status] .label {
        visibility: hidden;
    }

    html.js &.-js-initialized [data-eventitem-status] .label {
        visibility: visible;
    }

    .js-status-label {
        z-index: 10;
        margin-top: -1px;
    }

    &__noresults {
        font-weight: $fontWeightLight;
        font-size: 18px;
        color: #373737;
        @include letter-spacing(0.35, 21);
        @include line-height(24, 18);
    }

    @include smaller-than(m) {

        padding-left: 0;
        padding-right: 0;

        &__inner {
            padding-left: 0;
            padding-right: 0;
        }

        // 2 events per row - shift 'em to the left to cover up double borders
        $colsPerRow: 2;

        &__items {
            width: calc(100% + 4px);
            margin-left: -2px;
            .program & {
                width: calc(100% + 8px);
            }
        }

        @for $i from 1 to $colsPerRow {
            &__item.nth-#{$i+1}\@s {
                left: -#{$i}px;
            }
            .program &__item.nth-#{$i+1}\@s {
                left: -#{$i * 2}px;
            }
        }

    }

    @include bigger-than(m) {

        @include smaller-than(l) {

            // 4 events per row - shift 'em to the left to cover up double borders
            $colsPerRow: 3;

            &__items {
                width: calc(100% + #{$colsPerRow - 1}px);
                .program & {
                    width: calc(100% + #{($colsPerRow - 1) * 2}px);
                }
            }

            @for $i from 1 to $colsPerRow {
                &__item.nth-#{$i+1}\@m {
                    left: -#{$i}px;
                }
                .program &__item.nth-#{$i+1}\@m {
                    left: -#{$i * 2}px;
                }
            }

        }

    }

    @include bigger-than(l) {

        // 4 events per row - shift 'em to the left to cover up double borders
        $colsPerRow: 4;

        &__items {
            width: calc(100% + #{$colsPerRow - 1}px);
            .program & {
                width: calc(100% + #{($colsPerRow - 1) * 2}px);
            }
        }

        @for $i from 1 to $colsPerRow {
            &__item.nth-#{$i+1}\@l {
                left: -#{$i}px;
            }
            .program &__item.nth-#{$i+1}\@l {
                left: -#{$i * 2}px;
            }
        }

    }

    /*
    *   Modifiers
    *
    */

    // This is extremely dirty, because the `.-nested-article` modifier is actually set on the block wrapping the events grid
    // However, it's the best I could do. May the gods have mercy on us all

    .-nested-article & {

        @include bigger-than(l) {

            &__items {
                width: calc(#{grid(9, 12)} - 6px);
                .program & {
                    width: calc(#{grid(9, 12)} - 5px);
                }
            }

            &__item.grid-3\/12\@l {
                width: grid(4, 12);
            }

            &__item.grid-3\/12\@l,
            .program &__item.grid-3\/12\@l {
                left: 0;
            }

            @for $i from 1 to 3 {
                &__item.nth-#{$i+1}\@m {
                    left: -#{$i}px;
                }
                .program &__item.nth-#{$i+1}\@m {
                    left: -#{$i * 2}px;
                }
            }

        }

    }

}