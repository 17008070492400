input, select, button {
    border: none;
    border-radius: 0;
    vertical-align: middle;

}

label {
    display: block;
}

input[type="checkbox"],
input[type="radio"] {
    display: inline-block;
    width: auto;
    height: auto;
    position: relative;
    top: -1px;
}

input::placeholder {
    color: currentColor;
    opacity: 1;

    .ios-safari & {
        padding-top: 1px;
    }
}

input, select {
    border: 1px solid $colorBlack;
    background: transparent;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;

    @include smaller-than($widthM) {
        .ios-safari & {
            font-size: 16px;
        }
    }
}

select {
    appearance: none;
    line-height: normal;
}

option {

}

button {

}

.errors {
    color: #f00;
}