.next-prev-buttons {

    margin-right: -5px;

    &__btn {
        display: inline-block;
        width: 24px;
        height: 24px;
        border-radius: 100%;
        background-color: $colorGreyDarkest;
        appearance: none;
        padding: 0;
        margin: 0 5px;
        position: relative;
        opacity: 1;
        transition: opacity .2s, background-color .2s;
        @include gpu;
        .icon {
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0; left: 0;
        }
        svg {
            display: block;
            width: 6px;
            height: 12px;
            position: absolute;
            top: 50%; left: 50%;
            margin: -6px 0 0 -3px;
        }
        path {
            transition: stroke .2s;
        }
        &.-next svg {
            margin-left: -2px;
        }
        &.-prev svg {
            margin-left: -4px;
        }
        &:not(.-disabled):hover {
            background-color: $colorBlue;
        }
        &.-disabled {
            opacity: 0.5;
            pointer-events: none;
        }
    }

    /*
    *   Page context
    *
    */
    body.is-page &__btn {
        &:not(.-disabled):hover {
            background-color: $colorRedButtonHover;
        }
    }

    /*
    *   Awards context
    *
    */
    body.is-awards &__btn {
        &:not(.-disabled):hover {
            background-color: $colorYellow;
            path {
                stroke: $colorGreyDarkest;
            }
        }
    }

    /*
    *   R21 context
    *
    */
    body.is-r21 &__btn {
        &:not(.-disabled):hover {
            background-color: $colorRedR21;
        }
    }

    /*
    *   Modifiers
    *
     */
    &.-disabled {
        pointer-events: none;
        @include screenreader;
    }

}