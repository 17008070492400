.winner-article {

    &__head {
        margin-bottom: 20px;

        @include bigger-than(mp) {
            max-width: 760px;
            margin-bottom: 24px;
            margin-left: auto;
            margin-right: auto;
            padding-left: 0 !important;
            padding-right: 0 !important;
        }
    }

    &__category {
        letter-spacing: 1px;
        font-size: 14px;
        border-bottom: 1px solid $colorGreyMedium;
        padding-bottom: 5px;
        margin-bottom: 10px;

        @include bigger-than(m) {
            font-size: 16px;
            padding-bottom: 10px;
        }
    }

    &__award-type {
        font-size: 48px;
        line-height: 1;
        margin-bottom: 15px;

        @include bigger-than(m) {
            float: left;
            padding-right: 15px;
            font-size: 60px;
            margin-bottom: 30px;
        }
    }

    &__winner-entity {
        font-size: 18px;
        line-height: (23/18);
        margin-bottom: 3px;

        @include bigger-than(m) {
            margin-top: 12px;
        }
    }

    &__project-name {
        font-size: 18px;
        line-height: (23/18);
    }

    &__main-media {
        position: relative;

    }

    &__media-item {
        margin-bottom: 20px;

        @include bigger-than(m) {
            margin-bottom: 40px;
        }

        &:last-child {
            margin-bottom: 0;
        }
        
        &-inner {
            background: rgba(255, 255, 255, 0.4);
        }

        &.-main {
            position: relative;

            .-entry-won-gold & {
                &:after {
                    content: ' ';
                    width: 24px;
                    height: 24px;
                    background: $colorYellow;
                    border-radius: 50%;
                    display: block;
                    position: absolute;
                    left: 3px;
                    top: -7px;

                    @include bigger-than(m) {
                        width: 30px;
                        height: 30px;
                        left: 5px;
                        top: -10px;
                    }

                    @include bigger-than(l) {
                        width: 50px;
                        height: 50px;
                        left: -5px;
                        top: -25px;
                    }
                }
            }
        }

        &-embed {
            width: 100%;
            padding-bottom: (9/16) * 100%;
            height: 0;
            position: relative;

            iframe {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
            }
        }
    }

    &__details {
        
        margin-bottom: 40px;
    }
    
    &__jury-video,
    &__details-text {
        font-size: 15px;
        line-height: (20/15);
    }

    &__jury-video {
        margin-top: 15px;
        margin-bottom: 38px;

        @include bigger-than(m) {
            margin-top: 43px;
        }

        &-label {
            margin-top: 5px;

            @include bigger-than(m) {
                margin-top: 20px;
            }
        }

        &-embed {
            width: 100%;
            height: 0;
            padding-bottom: (9/16)*100%;
            position: relative;
            background: $colorGreyMedium;

            iframe,
            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
            
            iframe {
                z-index: 2;
            }
            
            &:after {
                content: ' ';
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: linear-gradient(to bottom, rgba(0, 0, 0, 0.0) 10%, rgba(0, 0, 0, 0.5) 100%);
            }
        }
        &-trigger {
            position: absolute;
            display: block;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            z-index: 1;
            .icon {
                display: block;
                position: absolute;
                top: 50%;
                left: 50%;
                width: 20px;
                height: 20px;
                margin-top: -10px;
                margin-left: -8px;
                
                svg {
                    display: block;
                    width: 100%;
                    height: 100%;
                }
            }
            
            &::before {
                content: "";
                display: block;
                width: 48px;
                height: 48px;
                position: absolute;
                top: 50%;
                left: 50%;
                margin: -26px 0 0 -26px;
                border-radius: 100%;
                background-color: transparent;
                border: 2px solid $colorWhite;
            }
        }

    }

    &__details-text {
        margin-top: 30px;
        column-count: 2;
        column-gap: $gutterSmall;
        margin-bottom: 20px;

        @include bigger-than(m) {
            margin-top: 40px;
            margin-bottom: 30px;
            column-gap: $gutterMedium;
        }

        @include bigger-than(mp) {
            column-gap: $gutterMediumPlus;
        }

        @include bigger-than(l) {
            column-count: 3;
            column-gap: $gutterLarge;
        }

        @include bigger-than(lp) {
            column-gap: $gutterLargePlus;
        }

        @include bigger-than(xl) {
            column-gap: $gutterXLarge;
        }

        p {
            padding-bottom: 20px;
            break-inside: avoid;
        }

        a {
            @extend %defaultLink;
        }
    }

    &__jury-text {
        @include bigger-than(m) {
            margin-bottom: 50px;
        }

        &-heading {
            margin-bottom: 20px;
        }
    }
    
    &__related {
        margin-top: 40px;
        padding-bottom: 40px;

        @include bigger-than(m) {
            margin-top: 65px;
            padding-bottom: 60px;
        }

        &-top {

        }

        &-heading {
            padding-top: 35px;
            padding-bottom: 30px;
            border-top: 1px solid $colorGreyMedium;
            font-size: 16px;

            @include bigger-than(m) {
                padding-top: 53px;
                padding-bottom: 40px;
            }
        }
    }
}