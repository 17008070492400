.awards-subpage-nav {

    &__link {

        display: inline-block;
        font-size: 16px;
        color: #373737;
        color: currentColor;

        padding: 0 5px;
        margin: 0 0 5px;

        @include letter-spacing(1, 16);

        .label {
            display: inline-block;
            position: relative;
        }

        .label::before {
            content: "";
            width: 100%;
            height: 1px;
            position: absolute;
            left: 0; bottom: -1px;
            background-color: currentColor;
            opacity: 0;
            transform: scale(0, 1);
            transform-origin: center center;
            transition: transform 0.25s, opacity 0.2s;
        }

        &.-active .label::before,
        &:hover .label::before {
            transform: scale(1, 1);
            opacity: 1;
        }

    }

}