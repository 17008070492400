.block-images {

    img {
        display: block;
        width: 100%;
    }

    &__image {
        margin-bottom: $gutterSmall;
        &.-first.-last,
        .-last &.-last {
            margin-bottom: 0 !important;
        }
        &-link {
            display: block;
            width: 100%;
            background-size: cover;
        }
        &-caption {
            font-size: 11px;
            padding-top: 2px;
            @include letter-spacing(0.5, 11);
            @include line-height(15, 11);
        }
    }

    & + & {
        padding-top: 0;
    }

    @include bigger-than(m) {

        &__images {
            display: flex;
            flex-wrap: wrap;
        }

        &__image {
            margin-bottom: $gutterMedium;
            .-half .-last &.-first {
                margin-bottom: 0 !important;
            }
        }

        & + & {
            margin-top: -($gutterMedium/2);
        }

    }

    @include bigger-than(l) {

        & + &,
        .block-video + & {
            padding-top: 0;
            margin-top: 0;
        }

        &__image {
            margin-bottom: $gutterLarge;
        }

    }

    /*
    *   Modifiers
    *
    */
    $this: #{&};

    &.-colored:not(.-nested-article) {
        position: relative;
        z-index: 1;
        > * {
            position: relative;
            z-index: 2;
        }

        #{$this}__bg {
            display: block;
            width: 150vw;
            height: 100%;
            position: absolute;
            top: 0; left: -50vw;
            z-index: 1;
            background-color: $colorGreyLight;

            body.is-awards &,
            body.is-r21 & {
                background-color: darken($colorGreyLight, 5%);
            }
        }
    }

    // Should apply if the width is "full", or whenever there's more than one image
    &.-nested-article:not(.-singleimage),
    &.-nested-article.-full {

        @include bigger-than(l) {

            #{$this}__images {
                width: grid(12, 16);
            }

        }

    }

}
