.block-profiles {

    &.-first {
        padding-top: 0 !important;
    }

    &__header {

        background-color: #ACDCF2;
        color: $colorBlack;
        padding: 20px 0;

        @include bigger-than(m) {

            padding: 30px 0 45px;

            > div {
                display: flex;
                align-items: center;
                width: 100%;
            }
        }

        &-text {

            @include bigger-than(m) {
                padding-right: 0 !important;
            }

            .heading {
                font-size: 30px;
                line-height: 36px;
                margin: 0 0 15px;

                @include bigger-than(m) {
                    font-size: 36px;
                    line-height: 42px;
                    margin: 0 0 25px;
                }
            }

            .text {
                font-size: 18px;
                line-height: 24px;
                margin: 0 0 20px;

                @include bigger-than(m) {
                    font-size: 20px;
                    line-height: 27px;
                }
            }
        }

        &-image {

            display: flex;
            justify-content: center;
            align-items: center;

            padding-top: 20px;
            padding-bottom: 20px;

            @include bigger-than(m) {
                justify-content: flex-end;
            }

            .image {
                width: 100%;
                flex: 1 1 auto;
                background-size: cover;
                background-repeat: no-repeat;
                max-width: 155px;

                @include bigger-than(m) {
                    max-width: 260px;
                }
            }
        }
    }

    &__footer {
        font-size: 30px;
        color: $colorRedMain;
        line-height: 36px;
        @include letter-spacing(0.46, 30);
        padding-top: 55px;
        //padding-bottom: 50px;

        @include bigger-than(m) {
            font-size: 36px;
            @include letter-spacing(0.56, 36);
        }
    }

}
