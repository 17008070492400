.block-events-feed {

    overflow: hidden;

    &__inner {
        /*padding-top: 15px;
        padding-bottom: 20px;*/
    }

    &__header {
        padding-bottom: 20px;
    }

    &__header-wrap {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: baseline;
        > * {
            display: block;
            width: auto;
            flex: 0 1 auto;
        }
    }

    &__heading {
        font-size: 18px;
        line-height: 1;
        margin-right: 15px;

        @include bigger-than(l) {
            font-size: 21px;
        }
    }

    &__link {
        font-size: 13px;
        @include line-height(19, 13);
        border-bottom: 1px solid currentColor;
        transition: border-bottom-color 0.3s $easeOutCubic;
        top: -1px;
        position: relative;
        padding-top: 8px;
        &:hover,
        &:active {
            border-bottom-color: transparent;
        }
    }

    @include bigger-than(m) {

        /*&__inner {
            padding: 0;
        }*/

    }

    $this: #{&};

    body.is-page &,
    body.is-r21 & {

        #{$this}__header {
            //padding-bottom: 30px;
        }

        #{$this}__heading {
            font-family: $fontCaslon;
            font-weight: $fontWeightRegular;
            font-size: 24px;
            @include line-height(25, 24);
        }

    }

}