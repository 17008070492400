.feed-item {
    margin-bottom: $gutterSmall;

    @include bigger-than(m) {
        margin-bottom: $gutterMedium;
    }

    @include bigger-than(mp) {
        margin-bottom: $gutterMediumPlus;
    }

    @include bigger-than(l) {
        margin-bottom: $gutterLarge;
    }

    @include bigger-than(lp) {
        margin-bottom: $gutterLargePlus;
    }

    @include bigger-than(xl) {
        margin-bottom: $gutterXLarge;
    }

    &__inner {
        .-instagrampost & {
            background: $colorBlack;
            color: $colorWhite;
        }
    }

    &__icon {
        height: 25px;
        width: 30px;
        margin-bottom: 11px;
    }

    &__media-area {
        position: relative;
        img {
            width: 100%;
        }
        video {
            width: 100%;
        }
    }

    &__text-area {
        padding: 20px;

        a {
            transition: color 0.2s ease;
        }

        a:hover {
            .-tweet & {
                color: $colorRedMain;
            }
            .-instagrampost & {
                color: #ccc;
            }
        }
    }

    &__text {
        font-size: 16px;
        line-height: (22/16);

        .-tweet & {
            font-size: 18px;
            line-height: (24/18);
        }

        @include bigger-than(lp) {
            font-size: 18px;
            line-height: (24/18);

            .-tweet & {
                font-size: 20px;
                line-height: (28/20);
            }
        }

    }

    &__meta {
        position: relative;
        font-size: 13px;
        line-height: (19/13);
        margin-top: 15px;
        padding-top: 15px;

        &:before {
            content: ' ';
            width: 50px;
            height: 1px;
            position: absolute;
            top: 0;
            left: 50%;
            margin-left: -25px;
            background: #626262;

        }
    }

}