@keyframes dotdotdot {
    0% {
        content: "";
    }
    25% {
        content: ".";
    }
    50% {
        content: "..";
    }
    75% {
        content: "...";
        opacity: 1;
    }
    100% {
        content: "...";
        opacity: 0;
    }
}

.loadmore {

    overflow: hidden;
    transition: opacity 0.3s 0.2s $easeInSine, max-height 1s $easeInOutSine;
    max-height: 500px;
    opacity: 1;
    user-select: none;

    html.js &.-disabled {
        max-height: 0;
        opacity: 0;
        pointer-events: none;
    }

    &__inner {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    &__pagination {

        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        > * {
            display: flex;
            flex: 1 1 33.3333%;
        }

        &-next {
            justify-content: flex-end;
            text-align: right;
        }

        &-pages {
            justify-content: center;
            overflow: hidden;
            text-overflow: ellipsis;
            > * {
                margin: 0 4px;
            }
            @include smaller-than(s) {
                @include screenreader;
            }
        }

        &-prev {
            justify-content: flex-start;
        }

        html.js & {
            @include screenreader;
        }

    }

    &__link,
    &__button {

        display: inline-block;
        appearance: none;
        padding: 0;
        border-radius: 0;
        border: none;
        background: none;
        font-size: 24px;

        line-height: 1;

        @include letter-spacing(0.54, 24);

        color: $colorRedMain;

        transition: opacity .2s $easeInSine;

        .label {
            display: inline-block;
            position: relative;
            padding-bottom: 2px;
            &::before {
                content: "";
                display: block;
                width: 100%;
                height: 2px;
                position: absolute;
                left: 0;
                bottom: 0;
                background-color: currentColor;
                transform-origin: center center;
                transform: scale(0, 1);
                transition: transform .1s;
            }
            &.-loading {
                &::after {
                    content: "";
                    display: inline-block;
                    position: absolute;
                    animation: dotdotdot;
                    animation-duration: 1s;
                    animation-timing-function: linear;
                    animation-iteration-count: infinite;
                }
            }
        }

        svg {
            display: inline-block;
            width: 18px;
            height: 18px;
            transition: opacity .3s $easeInSine;
            margin-right: 5px;
        }

        path {
            stroke: currentColor;
        }

        &.-current {
            color: $colorBlack;
            .label::before {
                content: none;
            }
        }

    }

    &__button {
        display: block;
        margin: 0 auto;
    }
    
    html.no-js &__button {
        display: none;
    }

    html.js &.-disabled &__button,
    &.-loading &__button {
        pointer-events: none;
        cursor: default;
        svg {
            opacity: 0;
        }
    }

    &__link:hover .label::before,
    &:not(.-loading) &__button:hover .label::before {
        transform: scale(1, 1);
    }

    &:not(.-loading) .label.-loading {
        @include screenreader;
    }

    &.-loading .label:not(.-loading) {
        @include screenreader;
    }

    @include bigger-than(m) {

        &__link,
        &__button {
            font-size: 36px;
            @include letter-spacing(0.54, 36);
            svg {
                width: 27px;
                height: 27px;
            }
        }

    }

}