.awards-archive {

    &__header {
        padding-top: 30px;
        padding-bottom: 30px;

        @include bigger-than(l) {
            padding-top: 55px;
            padding-bottom: 35px;
        }
    }

    &__heading {
        font-size: 30px;
        line-height: 1;
        @include letter-spacing(0.93, 30);

        @include bigger-than(l) {
            font-size: 48px;
            line-height: 1;
            @include letter-spacing(1.5, 48);
        }
    }

    &__page {
        display: block;
        width: 100%;
        padding-bottom: 30px;

        @include bigger-than(l) {
            padding-bottom: 40px;
        }
    }

}