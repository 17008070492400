.grid-wrap,
.grid-wrap\@l {
    display: block;
    max-width: $maxContentWidth + ($outerMarginXLarge*2);
    margin-left: auto;
    margin-right: auto;
}

.grid-wrap {
    padding-left: $outerMarginSmall - ($gutterSmall/2);
    padding-right: $outerMarginSmall - ($gutterSmall/2);

    @include bigger-than($widthM) {
        padding-left: $outerMarginMedium - ($gutterMedium/2);
        padding-right: $outerMarginMedium - ($gutterMedium/2);
    }
    @include bigger-than($widthMPlus) {
        padding-left: $outerMarginMediumPlus - ($gutterMediumPlus/2);
        padding-right: $outerMarginMediumPlus - ($gutterMediumPlus/2);
    }
    @include bigger-than($widthL) {
        padding-left: $outerMarginLarge - ($gutterLarge/2);
        padding-right: $outerMarginLarge - ($gutterLarge/2);
    }
    @include bigger-than($widthLPlus) {
        padding-left: $outerMarginLargePlus - ($gutterLargePlus/2);
        padding-right: $outerMarginLargePlus - ($gutterLargePlus/2);
    }
    @include bigger-than($widthXL) {
        padding-left: $outerMarginXLarge - ($gutterXLarge/2);
        padding-right: $outerMarginXLarge - ($gutterXLarge/2);
    }
    // Adds in flexbox support, lifted from Arkitektnytt - mmikkel
    &--flex {
        .flexbox & {
            display: flex;
            flex-wrap: wrap;
        }

        &:before {
            .flexbox & {
                display: none;
            }
        }

        &:after {
            .flexbox & {
                display: none;
            }
        }
    }
}

.grid-wrap\@l {
    @include bigger-than($widthL) {
        padding-left: $outerMarginLarge - ($gutterLarge/2);
        padding-right: $outerMarginLarge - ($gutterLarge/2);
    }
    @include bigger-than($widthLPlus) {
        padding-left: $outerMarginLargePlus - ($gutterLargePlus/2);
        padding-right: $outerMarginLargePlus - ($gutterLargePlus/2);
    }
    @include bigger-than($widthXL) {
        padding-left: $outerMarginXLarge - ($gutterXLarge/2);
        padding-right: $outerMarginXLarge - ($gutterXLarge/2);
    }
}

.grid-item,
.grid-item\@l {
    width: 100%;
}

%grid-item {
    padding-left: $gutterSmall/2;
    padding-right: $gutterSmall/2;

    @include bigger-than($widthM) {
        padding-left: $gutterMedium/2;
        padding-right: $gutterMedium/2;
    }
    @include bigger-than($widthMPlus) {
        padding-left: $gutterMediumPlus/2;
        padding-right: $gutterMediumPlus/2;
    }
    @include bigger-than($widthL) {
        padding-left: $gutterLarge/2;
        padding-right: $gutterLarge/2;
    }
    @include bigger-than($widthLPlus) {
        padding-left: $gutterLargePlus/2;
        padding-right: $gutterLargePlus/2;
    }
    @include bigger-than($widthXL) {
        padding-left: $gutterXLarge/2;
        padding-right: $gutterXLarge/2;
    }

    // Adds in flexbox support, lifted from Arkitektnytt - mmikkel
    &--flex {
        display: flex;
        flex: 0 0 auto;
    }

}

.grid-item {
    @extend %grid-item;
}

.grid-item\@l {
    @include bigger-than($widthL) {
        padding-left: $gutterLarge/2;
        padding-right: $gutterLarge/2;
    }
    @include bigger-than($widthLPlus) {
        padding-left: $gutterLargePlus/2;
        padding-right: $gutterLargePlus/2;
    }
    @include bigger-than($widthXL) {
        padding-left: $gutterXLarge/2;
        padding-right: $gutterXLarge/2;
    }
}

.grid-clear {
    width: 100%;
    clear: both;
}

@include generate-grid(12, $grid-breakpoints);
@include generate-grid($gridColumns, $grid-breakpoints);
