@function get-breakpoint-width-from-map($name, $breakpoints) {
    @if map-has-key($breakpointMap, $name) {
        @return map-get($breakpointMap, $name);
    } @else {
        @warn "Breakpoint #{$name} wasn't found in $breakpointMap.";
    }
}

@mixin screenreader {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

@mixin bigger-than($breakpoint) {
    $min-width: 0;

    @if $breakpoint {
        @if type-of($breakpoint) == number {
            $min-width: $breakpoint;
        }
        @else {
            $min-width: get-breakpoint-width-from-map($breakpoint, $breakpointMap);
        }
    }

    @if $min-width != 0 {
        @media (min-width: #{$min-width}px) {
            @content;
        }
    }
}

@mixin smaller-than($breakpoint) {
    $max-width: 0;

    @if $breakpoint {
        @if type-of($breakpoint) == number {
            $max-width: $breakpoint;
        } 
        @else {
            $max-width: get-breakpoint-width-from-map($breakpoint, $breakpointMap);
        }
    }

    @if $max-width != 0 {
        @media (max-width: #{$max-width - 1}px) {
            @content;
        }
    }
}

@mixin verticallyAligned($pos:relative) {
    position: $pos;
    top: 50%;
    transform: translateY(-50%);
}

@mixin get-breakpoint-width($breakpoint) {
    $min-width: 0;

    @if $breakpoint {
        @if type-of($breakpoint) == number {
            $min-width: $breakpoint;
        } @else {
            $min-width: get-breakpoint-width-from-map($breakpoint, $breakpointMap);
        }
    }

    @if $min-width != 0 {
        @media (min-width: #{$min-width}px) {
            @content;
        }
    }
}

@function grid-columns($span, $columns) {
    @if $span >= 1 {
        // Convert the given span to a fraction of the columns
        @return percentage($span / $columns);
    } @else {
        // A fraction has been supplied so lets just converting it to a percentage
        @return percentage($span);
    }
}

// Just an alias because grid-columns is hard to type and doesn't have default parameters
@function grid($span: $gridColumns, $columns: $gridColumns) {
    @return grid-columns($span, $columns);
}

// grid item helper mixin (this is meant to be used like the `grid-` classes) - added by mmikkel
@mixin grid($span: $gridColumns, $columns: $gridColumns, $last: false) {
    width: grid-columns($span, $columns);
    @if $last {
        float: right;
        margin-left: auto;
    }
    @else {
        float: left;
    }
}

@mixin _grid-widths($columns, $breakpoint: null) {
    $breakpoint-suffix: '';

    @if $breakpoint != null {
        $breakpoint-suffix: \@#{$breakpoint};
    }

    @each $column in $columns {
        @if $column == 1 {
            // no point outputting 100% several times so lets just do it once
            .grid-#{$column}\/#{$column}#{$breakpoint-suffix} {
                width: 100% !important;
            }
        } @else {
            @for $i from 1 to $column {
                .grid-#{$i}\/#{$column}#{$breakpoint-suffix} {
                    @include grid($i / $column, $columns);
                }
                .grid-#{$i}\/#{$column}#{$breakpoint-suffix}--right {
                    @include grid($i / $column, $columns, true);
                }
                .push-#{$i}\/#{$column}#{$breakpoint-suffix} {
                    margin-left: grid($i / $column, $columns);
                }
                .pull-#{$i}\/#{$column}#{$breakpoint-suffix} {
                    margin-left: -(grid($i / $column, $columns));
                }
            }
        }
    }
}

@mixin generate-grid($columns, $breakpoints) {
    @include _grid-widths($columns);
    @each $breakpoint in $breakpoints {
        @include get-breakpoint-width('#{$breakpoint}') {
            @include _grid-widths($columns, $breakpoint);
        }
    }
}

@mixin breakpointer($breakpoints) {
    body:after {
        display: none;
        position: relative;
        z-index: 1;

        @each $key, $val in $breakpoints {
            @include bigger-than($val) {
                content: '#{$key}';
                z-index: $val;
            }
        }
    }
}

// Added by mmikkel 09.02.17
@mixin print($declarations) {
    @each $property, $value in $declarations {
        #{$property}: $value
    }
}

/*
* Aspect ratio - added by mmikkel 21.02.17
*
*/
@mixin aspect($width, $height, $context: 100%) {
    position: relative;
    display: block;
    content: "";
    width: $context;
    height: 0;
    padding-top: ($height / $width) * $context;
}

@mixin gpu() {
    -webkit-backface-visibility: hidden;
    -webkit-perspective: 1000;
    -webkit-transform: translate3d(0, 0, 0);
}


@mixin letter-spacing($space, $fontSize) {
    letter-spacing: ($space / $fontSize) + 0em;
}

@mixin line-height($line, $fontSize) {
    line-height: ($line / $fontSize);
}

@mixin ellipsis ($lines: 1, $max-height: 0) {

    overflow: hidden;

    @if($lines > 1) {
        // Fallback for non-webkit browsers.
        // Fallback does not render ellipsis.
        display: block;
        width: 100%;

        max-height: $max-height;

        // Webkit solution for multiline ellipsis
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: $lines;

        // Solution for Opera
        text-overflow: -o-ellipsis-lastline;
    } @else {
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}

/// Stripe builder
/// @author Hugo Giraudel
/// @param {Direction} $direction - Gradient direction
/// @param {List} $colors - List of colors
/// @output `background-image` if several colors, `background-color` if only one
@mixin stripes($direction, $colors) {
    $length: length($colors);

    @if $length > 1 {
        $stripes: ();

        @for $i from 1 through $length {
            $stripe: (100% / $length) * ($i - 1);

            @if $i > 1 {
                $stripes: append($stripes, nth($colors, $i - 1) $stripe, comma);
            }

            $stripes: append($stripes, nth($colors, $i) $stripe, comma);
        }

        background-image: linear-gradient($direction, $stripes);
    } @else if $length == 1 {
        background-color: $colors;
    }
}