.mega-menu {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 0;
    background: $colorGreyMegamenu;
    @include scrollable;
    z-index: 95;

    .-branding-award & {
        background: $colorBlack;
        color: $colorWhite;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;

        //border-bottom: 1px solid $colorGreyMegamenu;
    }

    &:target {
        .page-header:not(.-js-enabled) & {
            height: auto;
            bottom: 0;
        }
    }

    &__inner {
        position: relative;
        padding-top: 80px;
        padding-bottom: 30px;

        @include bigger-than(m) {
            padding-top: 90px;
        }

        .mega-menu.-js-enabled & {
            @include bigger-than(m) {
                padding-top: 0;
                padding-bottom: 120px;
            }
        }
    }

    &__close-button {
        display: none;

        @include bigger-than(m) {
            position: absolute;
            display: block;
            width: 25px;
            height: 25px;
            right: $outerMarginMedium;
            top: 18px;

            span {
                position: absolute;
                display: block;
                width: 30px;
                height: 4px;
                background: $colorBlack;
                transform-origin: 50% 50%;
                top: 11px;
                left: -3px;
                transition: background 0.1s ease;

                .-branding-award & {
                    background: $colorWhite;
                }
            }

            span:first-child {
                transform: rotate(-45deg);
            }

            span:last-child {
                transform: rotate(45deg);
            }

            &:hover {
                /*
                span {
                    background-color: $colorRedMain;
                    
                    .-branding-award & {
                        background-color: $colorYellow;
                    }
                }
                */
            }
        }

        @include bigger-than(mp) {
            right: $outerMarginMediumPlus;
        }
        @include bigger-than(l) {
            right: $outerMarginLarge;
        }
        @include bigger-than(lp) {
            right: $outerMarginLargePlus;
        }
        @include bigger-than(xl) {
            right: $outerMarginXLarge;
        }

    }

    &__search-area {
        margin: 5px 0 25px 0;

        @include bigger-than(m) {
            margin: 35px 0 45px 0;
        }

    }

    &__search-form {
        position: relative;

        @include bigger-than(m) {
            margin-right: 50px;
        }
    }

    &__search-input {
        border: 0;
        background: $colorWhite;
        color: $colorBlack;
        height: 40px;
        font-size: 20px;
        line-height: normal;
        padding: 10px 50px 10px 10px;

        @include bigger-than(m) {
            height: 60px;
            font-size: 25px;
            padding: 24px 50px 20px 20px; 
        }
    }

    &__search-button {
        display: block;
        width: 40px;
        height: 40px;
        position: absolute;
        right: 0;
        top: 0;
        padding: 0;
        background: #fff;

        .icon {
            position: absolute;
            left: 11px;
            top: 11px;
        }

        @include bigger-than(m) {
            width: 60px;
            height: 60px;

            .icon {
                width: 20px;
                height: 20px;
                left: 20px;
                top: 20px;
            }
        }
    }

    &__blocks {

    }

    &__block-list {

    }

    &__block-item {
        position: relative;

        &.-top {
            margin-bottom: 30px;

            @include bigger-than(m) {
                margin-bottom: 55px;

                &:nth-child(3n+1) {
                    clear: both;
                }
            }
            @include bigger-than(mp) {
                &:nth-child(3n+1) {
                    clear: none;
                }
                &:nth-child(4n+1) {
                    clear: both;
                }
            }
            @include bigger-than(lp) {
                width: 20% !important;

                &:nth-child(4n+1) {
                    clear: none;
                }
                &:nth-child(5n+1) {
                    clear: both;
                }
            }
        }

        &.-my-page {
            @include bigger-than(m) {
                p {
                    line-height: 22px;
                }
                
            }
            
        }
        
        &.-contact {
            @include smaller-than(m) {
                margin-top: 58px;
            }
        }

        &.-newsletter {
            @include smaller-than(m) {
                margin-top: 48px;
            }
            h2 {
                margin-bottom: 13px;
            }
        }
    }

    &__block-item-heading {
        font-size: 22px;
        letter-spacing: 0.5px;

        @include smaller-than(m) {
            font-size: 28px;
            margin-bottom: 10px;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
        }
    }

    &__block-item-text {
        font-size: 14px;
        line-height: 2;
        letter-spacing: 1px;

        @include smaller-than(m) {

        }

        a {
            box-shadow: inset 0 -2px 0 $colorGreyMedium;
            padding-bottom: 4px;
            transition: box-shadow 0.2s ease, color 0.2s ease;

            &:hover {
                color: $colorRedMegamenu;
                box-shadow: inset 0 -2px 0 $colorRedMegamenu;

                .-branding-award & {
                    color: $colorYellow;
                    box-shadow: inset 0 -2px 0 $colorYellow;
                }
            }
        }
    }

    &__block-item-map {
        display: none;

        @include smaller-than(m) {
            position: relative;
            display: block;
            height: 280px;
            background: rgb(229, 227, 223);
            margin-bottom: 15px;
            overflow: hidden;

            &-image {
                position: absolute;
                top: 0; left: 0;
                width: 100%; height: 100%;
                object-fit: cover;
                object-position: 50% 50%;
                z-index: 1;
            }

            &-marker {
                width: 26px;
                height: 37px;
                position: absolute;
                top: 50%; left: 50%;
                transform: translate(-50%, -50%);
                z-index: 2;
            }

            &-link {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                z-index: 1;
            }
        }

        @include smaller-than(sp) {
            height: 180px;
        }

    }

    &__block-item-link {
        position: relative;
        display: inline-block;
        transition-property: color, border-color, box-shadow;
        transition-duration: 0.2s;
        transition-timing-function: ease;

        @include smaller-than(m) {
            display: block;
            border-bottom: 1px solid $colorGreyMedium;
        }

        &-alt-title {
            display: none;
        }

        &.-top {
            font-size: 22px;
            letter-spacing: 0.5px;

            @include smaller-than(m) {
                font-size: 28px;
                line-height: 1;
                -webkit-font-smoothing: antialiased;
            }
        }

        &.-sub {
            font-size: 14px;
            letter-spacing: 1px;
            padding: 4px 0;

            @include smaller-than(m) {
                font-size: 15px;
                padding: 2px 0;
                margin: 14px 0;
            }
        }

        &-arrow {
            display: block;
            width: 20px;
            height: 20px;
            position: absolute;
            right: 0px;
            top: 2px;
            background: $colorWhite;
            border-radius: 10px;
            transition-property: background;
            transition-duration: 0.2s;
            transition-timing-function: ease;

            &:after {
                content: ' ';
                display: block;
                width: 5px;
                height: 5px;
                position: absolute;
                left: 7px;
                top: 6px;
                border-right: 1px solid $colorBlack;
                border-bottom: 1px solid $colorBlack;
                transform: rotate(45deg);
                transition-property: border-color;
                transition-duration: 0.2s;
                transition-timing-function: ease;
            }

            @include bigger-than(m) {
                display: none;
            }
        }

        &:hover,
        &.-button-hover,
        &.-selected {
            color: $colorRedMegamenu;
            border-color: $colorRedMegamenu;
            
            .-branding-award & {
                border-color: $colorYellow;
            }

            .mega-menu__block-item-link-arrow {
                background: $colorRedMegamenu;

                &:after {
                    border-color: $colorWhite;
                }
            }
        }

        .-branding-award & {

        }

        @include bigger-than(m) {
            box-shadow: inset 0 -2px 0 transparent;

            &:hover {
                box-shadow: inset 0 -2px 0 currentColor;
            }
        }
    }

    &__block-item-button {
        position: absolute;
        width: 50px;
        height: 40px;
        right: -5px;
        top: -7px;
        border: none;
        background: transparent;

        @include bigger-than(m) {
            display: none;
        }
    }

    &__block-item-submenu {

        @include smaller-than(m) {
            padding-left: 25px;

            .mega-menu.-js-enabled & {
                height: 0;
                overflow: hidden;
            }
        }
    }

    &__social-icons {
        margin-top: 35px;

        @include bigger-than(m) {
            margin-top: 20px;
        }

        li, a {
            display: inline-block;
        }

        a {
            margin: 0 10px;
            opacity: 1;

            path {
                fill: $colorMainText;
                transition: fill 0.1s ease;
            }

            &.-facebook {
                margin: 0 5px;
            }

            &:first-child {
                margin-left: 0 !important;
            }

            &:hover {
                path {
                    fill: $colorRedMain;
                }
            }
            
            .-branding-award & {
                path {
                    fill: $colorWhite;
                }
                
                &:hover {
                    path {
                        fill: $colorYellow;
                    }
                }
            }
        }

    }

    &__newsletter {
        &-form {
            position: relative;
            margin-top: 13px;
            max-width: 550px;
        }

        &-input {
            border: none;
            background: $colorWhite;
            font-size: 16px;
            height: 40px;
            margin-bottom: 15px;

            .-branding-award & {
                background: $colorBlack;
                color: $colorWhite;
                border: 1px solid $colorGreyMegamenu;
            }
        }

        &-button {
            background: $colorBlack;
            color: $colorWhite;
            display: block;
            width: 100%;
            height: 40px;
            border-radius: 20px;
            font-size: 16px;
            transition: background 0.2s ease;

            &:hover {
                background: $colorRedMain;
            }

            .-branding-award & {
                background: $colorWhite;
                color: $colorBlack;
                
                &:hover {
                    background: $colorYellow;
                }
            }
        }
    }

    &__home-item {
        display: none;
    }

    // override for branding
    .-branding-r21 &,
    .-branding-award & {
        @include smaller-than(m) {
            &__block-item.-menu:not(.-section-block) {
                display: none;
            }

            &__block-item-submenu {
                padding-left: 0;
                height: auto !important;
            }

            &__block-item-link-arrow,
            &__block-item-button {
                display: none;
            }

            &__block-item-link.-top {
                @include fontGraphik;
                font-size: 15px;
                line-height: inherit;
                padding: 2px 0;
                margin: 14px 0 0 0;
                text-transform: uppercase;
            }

            &__block-item-link {
                transition: color 0.2s ease;

                &-alt-title {
                    display: inline;
                }
                &-title {
                    display: none;
                }
            }

            &__home-item {
                display: block;
                margin-top: 25px;

                &-link {
                    transition: color 0.2s ease;
                    font-size: 15px;
                    letter-spacing: 1px;
                }

                &-link-arrow {
                    transition: background 0.2s ease;
                    display: inline-block;
                    width: 30px;
                    height: 1px;
                    position: relative;
                    top: -1px;
                    margin-right: 10px;
                    vertical-align: middle;

                    &:after,
                    &:before {
                        content: ' ';
                        display: block;
                        width: 6px;
                        height: 1px;
                        position: absolute;
                        right: 0;
                        transform-origin: 100% 50%;
                        transition: background 0.2s ease;
                    }

                    &:after {
                        transform: rotate(-45deg);
                    }
                    &:before {
                        transform: rotate(45deg);
                    }
                }
            }
        }
    }

    .-branding-r21 & {
        &__block-item-link {
            &:hover,
            &.-button-hover,
            &.-selected {
                color: $colorRedMain;
            }
        }

        &__home-item {
            &-link:hover {
                color: $colorRedMain;

                .mega-menu__home-item-link-arrow,
                .mega-menu__home-item-link-arrow:after,
                .mega-menu__home-item-link-arrow:before {
                    background: $colorRedMain;
                }
            }

            &-link-arrow {
                background: $colorMainText;

                &:after,
                &:before {
                    background: $colorMainText;
                }
            }
        }
    }

    .-branding-award & {
        &__block-item-link {
            &:hover,
            &.-button-hover,
            &.-selected {
                color: $colorYellow;
            }
        }

        &__home-item {
            &-link:hover {
                color: $colorYellow;

                .mega-menu__home-item-link-arrow,
                .mega-menu__home-item-link-arrow:after,
                .mega-menu__home-item-link-arrow:before {
                    background: $colorYellow;
                }
            }

            &-link-arrow {
                background: #fff;

                &:after,
                &:before {
                    background: #fff;
                }
            }
        }
    }
    
    &__mypage-button {
        display: block;
        max-width: 200px;
        font-size: 21px;
        line-height: 28px;
        letter-spacing: 0;
        padding: 8px 40px 9px 10px;
        margin-top: 15px;
        border: 1px solid $colorGreyMedium;
        box-shadow: none !important;
        
        &-arrow {
            display: inline-block;
            width: 30px;
            height: 1px;
            background: $colorRedMain;
            position: relative;
            top: -1px;
            margin-right: 10px;
            vertical-align: middle;
            transition-property: background, color, width;
            transition-duration: 0.1s, 0.1s, 0.3s;
            transition-timing-function: ease, ease, $easeOutSine;
            
            .-branding-award & {
                background: $colorYellow;
            }
            
            .mega-menu__mypage-button:hover & {
            }

            &:after,
            &:before {
                content: ' ';
                display: block;
                width: 6px;
                height: 1px;
                position: absolute;
                right: 0;
                background: $colorRedMain;
                transform-origin: 100% 50%;
                transition-property: background, color;
                transition-duration: 0.1s;
                transition-timing-function: ease;
                
                .-branding-award & {
                    background: $colorYellow;
                }
                
                .mega-menu__mypage-button:hover & {
                }
            }

            &:after {
                transform: rotate(-45deg);
            }
            &:before {
                transform: rotate(45deg);
            }
        }
    }

}

