.winners-list {
    @include smaller-than(m) {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    &__inner {
        position: relative;
    }

    &__sub {
        display: inline;

        @include smaller-than(m) {
            display: block;
            margin-bottom: 50px;
        }
    }
    &__heading {
        margin-bottom: 30px;
        padding: 0 $outerMarginSmall;
        letter-spacing: 1px;
        font-size: 20px;
        
        @include bigger-than(m) {
            @include screenreader;
        }
    }
}