.block-articles {

    > * {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }

    &__header {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        > * {
            display: flex;
        }
    }

    &__header-text {
        display: flex;
        justify-content: flex-start;
        align-items: baseline;
        flex-wrap: wrap;
        min-height: 39px; // Same height as the next/prev buttons, to avoid "jumping" on JS init
        padding-bottom: 20px;
    }

    &__heading {
        font-size: 18px;
        font-weight: $fontWeightMedium;
        line-height: 1;
        margin-right: 14px;
    }

    &__link {
        font-size: 13px;
        @include line-height(19, 13);
        border-bottom: 1px solid currentColor;
        transition: border-bottom-color 0.3s $easeOutCubic;
        top: -1px;
        position: relative;
        padding-top: 8px;
        &:hover,
        &:active {
            border-bottom-color: transparent;
        }
    }

    $this: #{&};

    /*
    *   Page context
    *
    */
    body.is-page &,
    body.is-r21 & {

        #{$this}__heading {
            font-family: $fontCaslon;
            font-weight: $fontWeightRegular;
            font-size: 24px;
            @include line-height(25, 24);
        }

    }

}