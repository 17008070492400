.page {

    &__header {
        color: $colorRedMain;
        padding-bottom: 24px;
        .grid-item {
            clear: left;
        }

        @include bigger-than(m) {
            padding-top: 5px;
            padding-bottom: 60px;
        }
    }

    &__heading {
        font-size: 30px;
        @include line-height(36, 30);
        margin: 14px 0;

        @include bigger-than(m) {
            font-size: 36px;
            @include line-height(44, 36);
        }
    }

    &__leading {
        font-size: 18px;
        @include line-height(22, 18);

        a {
            color: $colorBlack;
            border-bottom: 1px solid currentColor;
            transition: color 0.3s $easeOutCubic;
            &:hover,
            &:active {
                color: $colorRedMain;
            }
        }

        @include bigger-than(m) {
            font-size: 20px;
            @include line-height(28, 20);
        }
    }

    /*
    *   Modifiers
    *
    */
    $this: #{&};

    /*
    *   Chapter
    *
     */
    &.-chapter {

        #{$this}__header {
            padding-top: 0;
            padding-bottom: 10px;
        }

        @include bigger-than(l) {

            #{$this}__header {
                padding-top: 40px;
                padding-bottom: 55px;
            }

            #{$this}__heading {
                font-size: 60px;
                @include letter-spacing(1.5, 60);
                @include line-height(80, 60);
            }

        }

    }

    /*
    *   Frontpage
    *
     */
    &.-frontpage {



    }



}