.teasers-listing {

    &__header {
        padding-top: 10px;
        padding-bottom: 15px;

        body.is-awards & {
            padding-top: 20px;
        }

        @include bigger-than(m) {
            &, body.is-awards & {
                padding-top: 30px;
                padding-bottom: 35px;
            }
        }
    }

    &__heading,
    .entry-type-menu__nav-heading { // This is dirty, but the entry type menu doubles as the listing heading for some contexts - I think this is easier than adding identical styles to _entry-type-menu.sccs (that file is sort of a clusterfuck)
        font-size: 36px;
        padding-bottom: 20px;
        @include letter-spacing(1.12, 36);
        @include line-height(48, 36);

        @include bigger-than(m) {
            padding: 0 0 5px;
        }
    }

    &.-has-entry-type-menu &__heading {
        @include screenreader;
    }

    &__items {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        align-items: stretch;
    }

    &__item {
        display: flex;
        flex: 0 1 auto;
        margin-bottom: $gutterSmall;
        > * {
            display: flex;
            width: 100%;
        }
        html.js & {
            visibility: hidden;
        }
        html.js .-js-initialized & {
            visibility: visible;
        }

        @include smaller-than(sm) {
            width: 100% !important;
        }

        @include smaller-than(m) {
            &.-last {
                margin-bottom: 0;
            }
        }

        @include bigger-than(m) {
            margin-bottom: $gutterMedium;
        }

        @include bigger-than(l) {
            margin-bottom: $gutterLarge;
        }
    }

}