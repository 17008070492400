.block-links {

    &:not(.-colored) + &:not(.-colored),
    &.-colored + &.-colored {
        padding-top: 0;
        margin-top: -24px;
    }

    .block-text:not(.-colored) + &:not(.-colored),
    .block-text.-colored + &.-colored {
        padding-top: 0;
    }

    @include bigger-than(m) {
        &:not(.-colored) + &:not(.-colored),
        &.-colored + &.-colored {
            margin-top: -44px;
        }
    }

    @include bigger-than(l) {

        padding-top: 50px;
        padding-bottom: 50px;

        .block-text:not(.-colored) + &:not(.-colored),
        .block-text.-colored + &.-colored {
            margin-top: -16px;
        }

        &:not(.-colored) + &:not(.-colored),
        &.-colored + &.-colored {
            margin-top: -50px;
        }

    }

    &__links {

        display: block;
        width: 100%;

        position: relative;
        left: -10px;

        &-item {
            display: inline-block;
            margin: 0 10px 10px;
            text-align: center;

            &-link {
                display: inline-block;
                font-size: 20px;
                line-height: 1;
                padding: 13px 24px 18px;
                transition: background-color 0.15s $easeOutCubic, color 0.15s $easeOutCubic;

                background-color: $colorRedMain;
                color: $colorWhite;

                // Hover state
                &:hover {
                    background-color: $colorBlack;
                    color: $colorWhite;
                }

                body.is-awards & {
                    background-color: $colorYellow;
                    color: $colorBlack;
                    &:hover {
                        background-color: $colorBlack;
                        color: $colorWhite;
                    }
                }

                body.is-r21 & {
                    background-color: $colorRedR21;
                }

            }

        }
    }

}