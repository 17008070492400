.page-header {
    $colorTransitionTime: .2s;
    $colorTransitionEase: $easeInOutSine;

    &__wrapper {
        position: fixed;
        z-index: 99;
        width: 100%;
        top: 0;
        left: 0;
        transform: translate3d(0, 0, 0);
        transition: transform .3s $easeOutSine;

        .-hidden & {
            transform: translate3d(0, -100%, 0);
        }
    }

    &__bar,
    &__bar-bg {
        position: relative;
        width: 100%;
        height: $headerHeightSmall;

        @include bigger-than(m) {
            height: $headerHeightMedium;

            .-branding-award & {
                height: $headerHeightMediumAward;
            }
        }

    }

    &__bar {

        z-index: 99;
        color: $colorBlack;

        transition: color #{$colorTransitionTime} $colorTransitionEase;

        svg {
            display: block;
            width: 100%;
            height: 100%;
        }

        html.js body.-pageheader-transparent:not(.-pageheader-menuopen) & {
            color: $colorWhite;
        }

        body.-pageheader-menuopen & {
            color: $colorBlack;
        }

        &-bg {

            display: block;

            width: 100%;
            height: 100%;
            background: $colorWhite;
            z-index: 91;
            transition: transform .3s $easeOutSine;

            position: absolute;
            top: 0;
            left: 0;

            transform: translate3d(0, 0, 0);

            body.is-404 & {
                background-color: $colorRedMegamenu;
            }

            html.js body.-pageheader-transparent:not(.-pageheader-menuopen) & {
                //background: transparent;
                transform: translate3d(0, -100%, 0);
            }

            .-branding-award & {
                background: $colorBlack;

                &:before {
                    content: ' ';
                    display: block;
                    height: 1px;
                    width: 100%;
                    background: $colorGreyMegamenu;
                    position: relative;
                    top: -1px;
                }
            }

            body.is-r21 & {
                background-color: $colorBackgroundR21;
            }

            @include bigger-than(m) {
                z-index: 98;
            }
        }

        &-inner {
            position: relative;
        }

        &-logo {
            position: absolute;
            left: $gutterSmall/2;
            top: 24px;

            .-branding-award & {
                top: 19px;
            }

            @include bigger-than(m) {
                top: 40px;
                left: $gutterMedium/2;
            }

            @include bigger-than(mp) {
                left: $gutterMediumPlus/2;
            }

            @include bigger-than(l) {
                left: $gutterLarge/2;
            }

            @include bigger-than(lp) {
                left: $gutterLargePlus/2;
            }

            @include bigger-than(xl) {
                left: $gutterXLarge/2;
            }

            path {
                fill: currentColor;
                transition: fill #{$colorTransitionTime} $colorTransitionEase;
                .-branding-award &,
                body.is-404 & {
                    fill: $colorWhite;
                }
            }

            &-link {
                display: block;

                &.-grafill-full {
                    width: $headerHeightMedium;
                    height: 27px;
                }

                &.-grafill-symbol {
                    width: 32px;
                    height: 32px;

                    @include bigger-than(m) {
                        width: 40px;
                        height: 40px;
                    }
                }

                &.-visuelt,
                &.-avb,
                &.-avib {
                    position: absolute;
                }

                &.-visuelt {
                    width: 80px;
                    height: 25px;
                    top: 7px;
                    left: 42px;

                    @include bigger-than(m) {
                        width: 100px;
                        height: 30px;
                        top: 8px;
                        left: 60px;
                    }
                }

                &.-avb,
                &.-avib {
                    width: 160px;
                    height: 25px;
                    top: 5px;
                    left: 42px;

                    @include bigger-than(m) {
                        width: 180px;
                        height: 30px;
                        left: 60px;
                    }
                    
                    span {
                        display: block;
                        position: absolute;
                        left: 0;
                        top: 1px;
                        color: #fff;
                        font-weight: 700;
                        white-space: nowrap;
                        font-size: 16px;
                        -webkit-font-smoothing: antialiased;
                        -moz-osx-font-smoothing: grayscale;
                        
                        @include bigger-than(m) {
                            font-size: 17.5px;
                            top: 4px;
                        }
                    }
                }

                &.-avib {
                    width: 244px;
                    @include bigger-than(m) {
                        width: 267px;
                    }
                }
            }

            &:hover {
                /*
                path {
                    fill: $colorRedMain;
                    
                    .-branding-award & {
                        fill: $colorYellow;
                    }

                    body.is-404 & {
                        fill: $colorGreyDarkest;
                    }
                } 
                */
            }
        }

        &-logo-r21 {
            position: absolute;
            left: 50%;
            top: 30px;
            transition: top 0.4s $easeOutQuint;
            
            a {
                display: block;
            }
            
            &:hover {
                top: 33px;
            }

            @include bigger-than(m) {
                top: 40px;
                
                &:hover {
                    top: 44px;
                }
            }

            svg {
                display: block;
                width: 40px;
                height: 40px;
                margin: -13px 0 0 -10px;
                transition-property: width, height, margin;
                transition-duration: 0.3s;
                transition-timing-function: $easeInOutQuad;

                @include bigger-than(m) {
                    width: 66px;
                    height: 66px;
                    margin: -23px 0 0 -33px;
                }

                .is-r21-frontpage & {
                    width: 86px;
                    height: 86px;
                    margin: -43px 0 0 -33px;
                    
                    @include bigger-than(m) {
                        width: 160px;
                        height: 160px;
                        margin: -$headerHeightSmall 0 0 -80px;
                    }
                }

                .is-r21-frontpage .page-header.-hidden & {
                    width: 40px;
                    height: 40px;
                    margin: -13px 0 0 -10px;
                    
                    @include bigger-than(m) {
                        width: 66px;
                        height: 66px;
                        margin: -19px 0 0 -33px;
                    }
                }
            }

        }

        &-menu-toggle {
            position: absolute;
            right: $gutterSmall/2;
            top: 25px;
            width: 30px;
            height: 30px;
            display: block;

            opacity: 1;
            transform: translate3d(0, 0, 0);
            transition: transform .3s .25s $easeOutSine, opacity .2s .25s $easeInSine;

            .-hidden & {
                opacity: 0;
                transform: translate3d(0, -100%, 0);
            }

            .-branding-award & {
                top: 20px;
            }

            @include bigger-than(m) {
                top: 42px;

                .-branding-award & {
                    top: 24px;
                }
            }

            @include bigger-than(m) {
                right: $gutterMedium/2;
            }

            @include bigger-than(mp) {
                right: $gutterMediumPlus/2;
            }

            @include bigger-than(l) {
                right: $gutterLarge/2;
            }

            @include bigger-than(lp) {
                right: $gutterLargePlus/2;
            }

            @include bigger-than(xl) {
                right: $gutterXLarge/2;
            }

            span {
                display: block;
                width: 30px;
                height: 4px;
                position: absolute;
                left: 0;
                background: currentColor;
                transition: background 0.1s ease;
                
                .-branding-award &,
                body.is-404 & {
                    background: $colorWhite;
                }
            }

            span:nth-child(1) {
                top: 5px;
            }
            span:nth-child(2) {
                top: 13px;
            }
            span:nth-child(3) {
                top: 21px;
            }
            
            .-pageheader-menuopen & {
                @include smaller-than(m) {
                    span:nth-child(1) {
                        transform: rotate(-45deg);
                        top: 14px;
                    }
                    span:nth-child(2) {
                        display: none;
                    }
                    span:nth-child(3) {
                        transform: rotate(45deg);
                        top: 14px;
                    }
                }
            }
            
            &:hover {
                /*
                span {
                    background: $colorRedMain;

                    .-branding-award & {
                        background: $colorYellow;
                    }

                    body.is-404 & {
                        background: $colorGreyDarkest;
                    }
                }
                */
            }
        }
    }

    /* --- Breadcrumb --- */
    &__breadcrumb {
        display: none;
        opacity: 1;
        transform: translate3d(0, 0, 0);
        transition: transform .3s .15s $easeOutSine, opacity .2s .2s $easeInSine;

        .-hidden & {
            opacity: 0;
            transform: translate3d(-10%, 0, 0);
        }

        .-branding-award &,
        body.is-404 & {
            color: $colorWhite;
        }

        @include bigger-than(s) {
            display: block;
            position: relative;
            margin-left: 110px;
            margin-right: 70px;
            top: 34px;

            .-branding-award & {
                top: 28px;
            }

            .-subbranding-visuelt & {
                margin-left: 142px;
            }
            .-subbranding-avb & {
                margin-left: 224px;
            }
            .-subbranding-avib & {
                margin-left: 304px;
            }
        }

        @include bigger-than(m) {
            top: 48px;
            margin-left: 115px;

            .-branding-award & {
                top: 31px;
            }

            .-subbranding-visuelt & {
                margin-left: 183px;
            }
            .-subbranding-avb & {
                margin-left: 266px;
            }
            .-subbranding-avib & {
                margin-left: 350px;
            }
        }

        @include bigger-than(l) {
            margin-left: 120px;

            .-subbranding-visuelt & {
                margin-left: 188px;
            }
            .-subbranding-avb & {
                margin-left: 271px;
            }
            .-subbranding-avib & {
                margin-left: 358px;
            }
        }

        &-inner {
            display: block;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        &-item {
            display: none;
            font-size: 13px;
            line-height: (15/13);
            letter-spacing: 1px;
            margin-right: 8px;
            
            span {
                transition: color 0.1s ease;
            }
            
            &:hover {
                span {
                    color: $colorRedMain;
                    
                    .-branding-award & {
                        color: $colorYellow;
                    }
    
                    body.is-404 & {
                        color: $colorGreyDarkest;
                    }
                }
            }

            @include bigger-than(s) {
                &:first-child {
                    display: inline;
                }
            }

            @include bigger-than(m) {
                display: inline;
            }

            &:before {
                content: ' ';
                display: inline-block;
                height: 1px;
                width: 40px;
                position: relative;
                top: -4px;
                background: currentColor;
                margin-right: 10px;

                .-branding-award & {
                    background: $colorWhite;
                }
            }
        }
    }

    /* --- Shortcuts frontpage menu --- */
    &__shortcuts {
        @include smaller-than(m) {
            display: none;
        }

        @include bigger-than(l) {
            width: 75%;
        }

        &-inner {
            padding-top: 53px;
            text-align: center;
            font-size: 0;
            line-height: 0;

            @include bigger-than(l) {
                text-align: left;
                padding-left: 146px;
            }
        }

        &-item {
            position: relative;
            display: inline-block;
            padding-right: 24px;

            &:after {
                content: ' ';
                display: block;
                width: 1px;
                height: 18px;
                background: $colorGreyLight;
                position: absolute;
                top: 0;
                right: 12px;
            }

            &:last-child {
                padding-right: 0;

                &:after {
                    display: none;
                }
            }
        }

        &-link {
            font-size: 16px;
            line-height: 1;
            transition: color 0.2s ease;

            &:hover {
                color: $colorRedMain;
            }
        }
    }
}
