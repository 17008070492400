.block-people {

    @include bigger-than(l) {
        padding-bottom: 80px;
    }

    &__heading {
        display: block;
        padding-bottom: 40px;

        p {
            font-size: 28px;
        }

        @include bigger-than(l) {
            padding-bottom: 85px;

            p {
                font-size: 40px;
            }
        }
    }

    &__items {

        &-list {
            display: block;
            width: 100%;
        }

        &-item {
            display: block;
            border-top: 1px solid $colorRedMain;

            &.-last {
                border-bottom: 1px solid $colorRedMain;
            }

        }
    }

    &__person {

        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        padding: 26px 0 26px;

        font-size: 16px;
        @include line-height(20, 16);

        @include bigger-than(m) {
            flex-direction: row;
            align-items: center;
            padding: 28px 0 38px;
            font-size: 20px;
        }

        a {
            color: $colorRedMain;
            border-bottom: 1px solid transparent;
            transition: border-color 0.3s $easeOutCubic;
            &:hover,
            &:active {
                border-color: $colorRedMain !important;
            }
        }

        &-image {

            width: 100px;
            flex: 0 0 auto;
            margin-bottom: 10px;

            > div {
                border-radius: 100%;
                overflow: hidden;

                background-size: cover;

                img {
                    display: block;
                    width: 100%;
                    height: auto;
                }
            }

            @include bigger-than(s) {
                width: 120px;
            }

            @include bigger-than(m) {
                width: 205px;
                margin-bottom: 0;
            }
        }

        &-details {
            display: inline-block;
            flex: 1 1 auto;
            overflow: hidden;

            @include bigger-than(m) {
                padding-left: 50px;
            }

            @include bigger-than(l) {
                display: flex;
                align-items: center;
            }

            &-contact {
                padding-top: 5px;
                > * {
                    display: block;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    margin-bottom: 5px;
                    line-height: 1.1;
                    &:last-child {
                        margin: 0;
                    }
                    a {
                        display: inline-block;
                    }
                }

                @include bigger-than(l) {
                    padding-top: 28px;
                }
            }

            &-personalia {
                width: 100%;

                @include bigger-than(l) {
                    width: 43%;
                    flex: 1 1 auto;
                    padding: 0 50px 0 0;
                }
            }

            &-bio {

                //@include smaller-than(m) {
                //    @include screenreader;
                //}

                padding-top: 10px;

                @include bigger-than(m) {
                    font-size: 18px;
                    @include line-height(24, 18);
                    padding-top: 15px;
                }

                @include bigger-than(l) {
                    width: 57%;
                    padding: 0;
                    flex: 1 1 auto;
                }
            }

        }

        &-name {
            font-size: 18px;
            @include line-height(22, 18);
            margin: 0 0 10px;

            @include bigger-than(m) {
                font-size: 24px;
                margin: 0 0 5px;
            }
        }

        &-position {
            display: block;
            @include smaller-than(m) {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }

    }

}
