/*
*   The events-grid-item wraps the teaser-event in event grid context
*
 */

.events-grid-item {

    width: 100%;
    position: relative;
    z-index: 1;

    &__status {
        display: block;
        width: 100%;
        font-size: 14px;
        position: relative;
        line-height: 28px;
        z-index: 10;
        @include letter-spacing(1, 16);
        &::after,
        &::before {
            content: "";
            display: block;
            width: 100%;
            height: 1px;
            position: absolute;
            left: 0;
        }
        &::before {
            top: -1px;
        }
        &::after {
            bottom: -1px;
        }
        .-current & {
            background-color: $colorGreenDark;
            color: $colorWhite;
            &::before,
            &::after {
                background-color: $colorGreenDark;
            }
            body.is-awards & {
                background-color: $colorYellow;
                color: $colorGreyDarkest;
                &::before,
                &::after {
                    background-color: $colorYellow;
                }
            }
            body.is-r21 & {
                background-color: $colorRedR21;
                &::before,
                &::after {
                    background-color: $colorRedR21;
                }
            }
            body.is-page & {
                background-color: $colorRedMain;
                &::before,
                &::after {
                    background-color: $colorRedMain;
                }
            }
        }
        .-upcoming & {
            background-color: $colorGreyDarkest;
            color: $colorWhite;
            &::before,
            &::after {
                background-color: $colorGreyDarkest;
            }
        }
        .-past & {
            border-color: transparent;
            border-left: 1px solid $colorGreyMegamenu;
            border-right: 1px solid $colorGreyMegamenu;
            background-color: $colorWhite;
            background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAAM0lEQVQoU2N8/Pjxf1lZWUYGHAAsgU8RXCcuRShGY1OEYTe6IqyOQ1aE0/UwRTgVwHwHAHv2H7E3MtxPAAAAAElFTkSuQmCC);
            background-repeat: repeat;
            color: $colorBlack;
            &::before,
            &::after {
                background-color: $colorGreyMegamenu;
            }
            .events-grid &::before {
                top: 0;
            }
            // Awards context
            body.is-awards .-past & {
                border-color: $colorBackgroundAwards;
                &::before,
                &::after {
                    background-color: $colorBackgroundAwards;
                }
                @include bigger-than(m) {
                    border-left-width: 2px;
                    border-right-width: 2px;
                }
            }
            body.is-r21 .-past & {
                border-color: $colorBackgroundR21;
                &::before,
                &::after {
                    background-color: $colorBackgroundR21;
                }
            }
            body.is-page .-past & {
                border-color: $colorBackgroundR21;
                &::before,
                &::after {
                    background-color: $colorBackgroundR21;
                }
            }
        }

        @include bigger-than(m) {
            font-size: 16px;
            line-height: 38px;
        }
    }
    
    &__teaser {
        width: 100%;
        height: 100%;
        .block-program & {
            display: flex;
        }
    }

    /*
    *   Hover
    *
     */
    &:hover {
        z-index: 10;
    }

}
