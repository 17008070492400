.videos-module {
    display: block !important;
    position: relative;

    @include bigger-than(mp) {
        border-top: 1px solid $colorGreyLight;
        border-bottom: 1px solid $colorGreyLight;
        padding: 50px 0;
    }

    &__inner {
        @include bigger-than(mp) {
            position: relative;
            max-width: 950px;
            margin-left: auto;
            margin-right: auto;
            display: flex;
            
            .-playarea-activated & {
                flex-direction: column;
            }
        }
    }

    &__play-area {
        position: relative;
        @include bigger-than(mp) {
            width: (7/12)*100%;
            padding-right: $gutterMediumPlus/3;
        }
        
        iframe {
            position: absolute;
            z-index: 5;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
        }
    }

    &__navigation {
        @include bigger-than(mp) {
            display: flex;
            width: (5/12)*100%;
            padding-left: $gutterMediumPlus/1.5;
            
            .-playarea-activated & {
                width: 100%;
                padding-left: 0;
            }
        }
    }
    
    &__list {
        @include bigger-than(mp) {
            //height: 100%;
            display: flex;
            width: 100%;
            flex-direction: column;
            justify-content: space-between;
            
            &:before,
            &:after {
                display: none;
            }
            
            .-playarea-activated & {
                flex-direction: row;
                padding-top: 20px;
            }
        }
    }

    &__item {
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: (9/16)*100%;

        &:after {
            content: ' ';
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            background: linear-gradient(to bottom, rgba(0, 0, 0, 0.0) 40%, rgba(0, 0, 0, 0.5) 100%);
            z-index: 2;
        }
    }

    &__item-image {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 1;

    }

    &__item-text {
        position: absolute;
        width: 100%;
        padding: 15px;
        bottom: 0;
        left: 0;
        z-index: 3;
        color: $colorWhite;

        @include bigger-than(m) {
            padding: 30px;
        }
    }

    &__item-meta {
        padding-bottom: 4px;
    }

    &__item-heading {
        @include bigger-than(sp) {
            font-size: 18px;
            line-height: (22/18);
        }
    }

    &__item-trigger {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 4;
        background: rgba(0, 0, 0, 0);
        transition: background 0.3s ease;

        &:hover {
            //background: rgba(0, 0, 0, 0.2);
        }
        
        .icon {
            display: block;
            position: absolute;
            top: 40%;
            left: 50%;
            width: 16px;
            height: 16px;
            margin-top: -6px;
            margin-left: -4px;
            
            @include bigger-than(s) {
                top: 50%;
                width: 20px;
                height: 20px;
                margin-top: -8px;
                margin-left: -6px;
            }

            svg {
                display: block;
                width: 100%;
                height: 100%;
            }
            path {
                fill: $colorWhite;
            }
        }
        
        &::before {
            content: "";
            display: block;
            width: 40px;
            height: 40px;
            position: absolute;
            top: 40%;
            left: 50%;
            margin: -20px 0 0 -20px;
            border-radius: 100%;
            background-color: transparent;
            border: 2px solid $colorWhite;
            transition-property: transform, background-color, border-color;
            transition-duration: 0.4s, 0.2s, 0.2s;
            transition-timing-function: $easeOutQuint, ease, ease;
            
            @include bigger-than(s) {
                top: 50%;
                width: 50px;
                height: 50px;
                margin: -25px 0 0 -25px;
            }
        }
        
        &:hover {
            &::before {
                background-color: $colorMainText;
                border-color: $colorMainText;
                transform: scale(1.2);
            }
        }
    }

    &__list-item {
        padding: 15px 0;
        border-bottom: 1px solid $colorGreyLight;

        &.-active-video {
            display: none !important;
            
            &+.videos-module__list-item.-divider {
                display: none;
            }
        }

        &.-divider {
            height: 1px !important;
            background: $colorGreyLight;
            
            @include smaller-than(mp) {
                display: none;
            }
            
            &:last-child {
                display: none;
            }
            
            .-playarea-activated & {
                display: none;
            }
        }

        @include bigger-than(sp) {
            width: 50%;
            float: left;

            &:nth-child(7),
            &:last-child {
                display: none; // todo: This does not work when the module is js enabled (and last item is active). Must fix!
            }
        }

        @include bigger-than(mp) {
            width: 100%;
            float: none;
            padding: 0;
            margin-bottom: 0;
            border-bottom: 0;

            &:nth-child(7),
            &:last-child {
                display: block;
                border-bottom: 0; // todo: same, doesn't work
            }
        }
    }

    &__list-link {
        display: block;
        position: relative;
        font-size: 14px;
        line-height: (18/14);

        &-image {
            width: 124px;
        }

        &-text {
            position: absolute;
            margin-left: 134px;
            bottom: 0;
            left: 0;
            
            @include bigger-than(mp) {
                padding-right: 10px;
            }

            .icon {
                display: block;
                position: relative;
                width: 20px;
                height: 20px;
                background: transparent;
                border: 1px solid $colorGreyMedium;
                border-radius: 50%;
                margin-bottom: 3px;
                transition-property: transform, background, border-color;
                transition-duration: 0.4s, 0.2s, 0.2s;
                transition-timing-function: $easeOutQuart, ease, ease;
                
                .videos-module__list-link:hover & {
                    border-color: $colorMainText;
                    background: $colorMainText;
                    transform: scale(1.3);
                }
                
                svg {
                    display: block;
                    position: absolute;
                    width: 45%;
                    height: 45%;
                    left: 35%;
                    top: 25%;

                }
                path {
                    fill: $colorBlack;
                    transition: fill 0.1s ease;
                    
                    .videos-module__list-link:hover & {
                        fill: $colorWhite;
                    }
                }
            }

        }
    }
}