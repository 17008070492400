.block-events {

    overflow: hidden;

    img {
        display: block;
        width: 100%;
    }

    &__item {
        margin-bottom: 38px;

        &:last-child {
            margin-bottom: 0;
        }

        &-image {

            margin-bottom: 15px;
            position: relative;
            overflow: hidden;

            > * {
                transform: scale(1, 1);
                transform-origin: center center;
                transition: transform 0.5s $easeOutSine;
                will-change: transform;
            }

            .image {
                background-size: cover;
            }
            &-status {
                display: block;
                width: auto;
                font-style: italic;
                font-size: 36px;
                letter-spacing: 1.8px;
                line-height: 25px;
                display: inline-block;
                position: absolute;
                right: 22px;
                bottom: 20px;
                z-index: 2;
                body.is-r21 & {
                    color: $colorRedR21;
                }
                body.is-awards & {
                    color: $colorYellow;
                }
                @include smaller-than(m) {
                    @include screenreader;
                }
            }
        }

        &-heading {
            font-size: 23px;
            @include line-height(28, 23);
            letter-spacing: 0;

            transition: color 0.3s $easeOutCubic;
        }

        &-info {
            width: 100%;
        }

        &-meta {
            width: 100%;
            font-size: 14px;
            padding: 10px 0 0;
            @include line-height(18, 14);
            overflow: hidden;

            transition: color 0.3s $easeOutCubic;
            > span {
                display: flex;
                flex-wrap: wrap;
                width: auto;
                float: left;
                margin-left: -24px;
            }
            .type {
                @include letter-spacing(0.54, 14);
            }
            .date {
                @include letter-spacing(0.61, 14);
            }
            span span {
                margin-left: 12px;
                padding-left: 12px;
                position: relative;
                &::before {
                    content: "|";
                    display: inline-block;
                    font-weight: $fontWeightLight;
                    position: absolute;
                    left: -1px; top: 0;
                }
            }
        }

        &-leading {
            display: block;
            font-size: 15px;
            @include line-height(22, 15);
            @include letter-spacing(0.26, 15);
            padding: 15px 0 0;
        }

    }

    /*
    *   Hover state
    *
     */
    $this: #{&};

    a:hover,
    a:active {
        #{$this}__item-heading,
        #{$this}__item-meta {
            color: $colorRedR21;
        }
        #{$this}__item-image > * {
            transform: scale(1.025, 1.025);
        }
    }

    @include bigger-than(m) {

        &__item {
            margin-bottom: 58px;

            &:last-child,
            &:nth-last-child(2) {
                margin-bottom: 20px;
            }

            &-heading {
                font-size: 25px;
                @include line-height(30, 25);
            }

            &-leading {
                padding-right: $gutterMedium;
            }
        }

    }

    // Featured event
    $this: #{&};

    .-featured {

        a:hover,
        a:active {
            #{$this}__item-heading,
            #{$this}__item-meta {
                color: $colorWhite;
            }
            #{$this}__item-image > * {
                transform: scale(1.02, 1.02);
            }
        }

        margin-bottom: 40px;

        #{$this}__item-header {
            color: $colorWhite;
            position: relative;
        }

        #{$this}__item-info {
            width: 100%;
            position: absolute;
            left: 0; bottom: 25px;
            font-size: 14px;
            user-select: none;
            pointer-events: none;
            z-index: 2;
            hyphens: manual;
        }

        #{$this}__item-heading {
            font-size: 30px;
            font-weight: $fontWeightMedium;
            padding: 0;
            @include line-height(34, 30);
        }

        #{$this}__item-leading {
            padding: 0;
        }

        #{$this}__item-meta {
            padding: 15px 0 0;
        }

        #{$this}__item-image {
            z-index: 1;
            margin: 0;
            .image {
                z-index: 1;
            }
            &::before {
                content: "";
                display: block;
                width: 100%;
                height: 55%;
                position: absolute;
                left: 0;
                bottom: 0;
                z-index: 2;
                background: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 180%);
            }
        }

        @include smaller-than(m) {

            #{$this}__item-leading {
                @include screenreader;
            }

            #{$this}__item-image {

                // Bleed the outer gutters, like a pig
                width: calc(100% + #{$gutterSmall * 2});
                position: relative;
                left: -$gutterSmall;
                margin: 0;

                .image {
                    @include aspect(380, 390);
                    position: relative;
                    img {
                        width: auto;
                        max-width: none;
                        height: 100%;
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                    }
                }

                &::before {
                    background: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 140%);
                }

            }

        }

        @include bigger-than(m) {

            margin-bottom: 85px;

            #{$this}__item-info {
                padding: 0 25px;
                bottom: 24px;
            }

            #{$this}__item-heading {
                font-size: 32px;
                line-height: 1;
            }

            #{$this}__item-meta {
                padding: 12px 0 0;
                font-size: 16px;
                @include line-height(23, 16);
                .date,
                .type {
                    @include letter-spacing(0.7, 16);
                }
            }

            #{$this}__item-leading {
                width: grid(12, 16);
                padding: 24px 0 0;
            }

        }

        @include bigger-than(l) {

            #{$this}__item-heading {
                font-size: 48px;
            }

            #{$this}__item-leading {
                width: grid(9, 16);
                padding: 24px 0 0;
            }

        }

    }

    .-has-features:not(.-has-regular-events) .-featured:last-child {
        margin-bottom: 20px !important;
    }

}