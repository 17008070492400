// Generic styles for all Matrix blocks

.block {

    padding-top: 24px;
    padding-bottom: 24px;

    &.-first {
        padding-top: 0;
    }

    &.-last {
        padding-bottom: 40px;
    }

    @include bigger-than(m) {

        padding-top: 34px;
        padding-bottom: 34px;

        &.-last {
            padding-bottom: 70px !important;
        }

    }

    /*
    *   Modifiers
    *
    */
    &.-wrapped,
    &.-wrapped .grid-wrap {
        padding-left: 0;
        padding-right: 0;
        float: left;
        margin-left: 0;
        margin-right: 0;
        @include bigger-than(l) {
            width: grid(9, 12);
        }
    }

    &.-colored {
        @extend .clearfix;
    }

}