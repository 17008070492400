.awards-page {

    &__header {
        padding-top: 18px;
        padding-bottom: 22px;
    }

    &__heading {
        font-size: 24px;
        @include letter-spacing(0.5, 24);
        @include line-height(29, 24);
    }

    @include bigger-than(m) {

        &__header {
            padding-top: 35px;
            padding-bottom: 33px;
        }

        &__heading {
            font-size: 36px;
            @include line-height(48, 36);
            @include letter-spacing(1.12, 36);
        }

    }

    @include bigger-than(l) {

        &__header {
            padding-top: 56px;
            padding-bottom: 85px;
        }

        &__heading {
            font-size: 48px;
            @include line-height(48, 48);
            @include letter-spacing(1.5, 48);
        }

    }

}