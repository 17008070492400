.share-buttons {

    svg {
        display: block;
        width: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &__items {
        margin-left: -5px;
    }

    &__item {
        width: 50px;
        height: 40px;
        float: left;
        position: relative;
        margin: 0 5px 10px;
        @include gpu;
        &.-last {
            margin-right: 0;
        }
    }
    
    .icon {
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0; left: 0;
        background-color: $colorWhite;
        border: 1px solid $colorGreyMedium;
        transition: background-color 0.3s $easeOutCubic, border-color 0.3s $easeOutCubic;
        z-index: 2;

        path {
            transition: fill 0.3s $easeOutCubic;
        }
        g {
            transition: stroke 0.3s $easeOutCubic;
        }
    }

    .counter {
        display: flex;
        width: 100%;
        height: 100%;
        position: absolute;
        top: -100%; left: 0;
        justify-content: center;
        align-items: center;
        background-color: $colorBlack;
        color: $colorBlack;
        line-height: 1;
        font-size: 15px;
        z-index: 1;
        @include letter-spacing(0.5, 15);
        transform: translate(0, 100%);
        transition: transform 0.3s $easeOutCubic, color 0.3s 0.2s $easeOutCubic;
    }

    &__item:hover .counter {
        transform: translate(0, 0);
        color: $colorWhite;
    }

    .-facebook {
        svg {
            width: 8px;
            height: 17px;
            path {
                fill: $colorBlack;
            }
        }
        &:hover,
        &:active {
            background-color: #C4CDE0;
            border-color: #C4CDE0;
            svg path {
                fill: #3B5998;
            }
        }
    }

    .-twitter {
        svg {
            width: 19px;
            height: 16px;
            path {
                fill: $colorBlack;
            }
        }
        &:hover,
        &:active {
            background-color: #CCEEFB;
            border-color: #CCEEFB;
            svg path {
                fill: #00ACED;
            }
        }
    }

    .-mail {
        svg {
            width: 23px;
            height: 14px;
            g {
                stroke: $colorBlack;
            }
        }
        &:hover .icon,
        &:active .icon {
            background-color: $colorGreyDarkest;
            border-color: $colorGreyDarkest;
            svg g {
                stroke: $colorWhite;
            }
        }
    }

    .-event {
        svg {
            width: 22px;
            height: 23px;
            g {
                stroke: $colorBlack;
            }
        }
        &:hover,
        &:active {
            .icon {
                background-color: $colorGreenDark;
                border-color: $colorGreenDark;
            }
            svg g {
                stroke: $colorWhite;
            }
            body.is-r21 & {
                background-color: $colorRedR21;
                border-color: $colorRedR21;
            }
        }
    }

    body.is-r21 &__item {
        border: none;
    }

    @include bigger-than(l) {

        &__item {
            clear: left;
            margin-bottom: 10px;
            &.-last {
                margin-bottom: 0;
            }
        }

        .counter {
            top: 0; left: 100%;
            transform: translate(-100%, 0);
        }

    }

}