.events-list {

    line-height: 1.2;

    &__list + .loadmore {
        padding-top: 40px;
    }

    &__header {
        font-size: 14px;
        @include letter-spacing(1.17, 14);
        @include line-height(15, 14);
        padding-top: 8px;
        padding-bottom: 8px;
        &.-current {
            color: $colorWhite;
            background-color: $colorGreenDark;
            body.is-awards & {
                color: $colorGreyDarkest;
                background-color: $colorYellow;
            }
            body.is-r21 & {
                background-color: $colorRedR21;
            }
        }
        &.-upcoming {
            color: $colorWhite;
            background-color: $colorGreyDarkest;
        }
        &.-past {
            border: 1px solid $colorGreyMedium;
            position: relative;
            background-color: $colorWhite;
            background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAAM0lEQVQoU2N8/Pjxf1lZWUYGHAAsgU8RXCcuRShGY1OEYTe6IqyOQ1aE0/UwRTgVwHwHAHv2H7E3MtxPAAAAAElFTkSuQmCC);
            background-repeat: repeat;

            body.is-r21 &,
            body.is-page & {
                border-color: $colorGreyMedium;
                @include smaller-than(m) {
                    border-left: none;
                    border-right: none;
                }
            }
        }
    }

    &__section:last-child &__items {
        border-bottom: 1px solid $colorGreyMedium;
        body.is-r21 & {
            border: none;
        }
    }

    &__item {
        background-color: $colorWhite;
        position: relative;
        &::after {
            content: "";
            width: 100%;
            height: 1px;
            background-color: $colorGreyMedium;
            position: absolute;
            left: 0; top: 0;
            z-index: 1;
        }
        &:first-child::after {
            content: none;
        }
        img {
            display: block;
            width: 100%;
        }
        body.is-r21 & {
            &::after {
                content: none;
            }
            margin-bottom: 10px;
        }
    }

    &__event-link {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 20px $gutterSmall;
    }

    &__event-image {
        width: 70px;
        float: left;
        flex: 0 0 auto;
        position: relative;
        .-r21 &-icon {
            display: block;
            width: 30px;
            height: 30px;
            border-radius: 100%;
            overflow: hidden;
            @include gpu;
            background-color: $colorRedR21;
            color: $colorWhite;
            position: absolute;
            top: -4px;
            left: -6px;
            z-index: 2;
            svg {
                width: 100%;
                height: 100%;
            }
        }
        .image {
            display: block;
            width: 70px;
            height: 70px;
            border-radius: 100%;
            overflow: hidden;
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;
            @include gpu;
            img {
                display: block;
                width: 100%;
                height: 100%;
            }
            .-r21 & {
                background-color: $colorGreyLight;
            }
        }
    }

    &__event-text {
        width: calc(100% - 70px);
        float: left;
        padding: 0 0 0 17px;
        flex: 0 1 auto;
    }

    &__event-heading {
        display: block;
        width: 100%;
        font-size: 16px;
        @include line-height(19, 16);
        border-bottom: 1px solid $colorGreyMedium;
        padding: 0 0 8px;
    }

    &__event-meta {
        padding: 6px 0 0;
        @include line-height(22, 14);
        span {
            display: block;
        }
        .date {
            font-size: 14px;
            .-past & {
                text-decoration: line-through;
                color: $colorBlack;
                > * {
                    color: $colorGreyMedium;
                }
            }
        }
        .location {
            font-size: 12px;
        }
        .-past & {
            color: $colorGreyMedium;
        }
        .-archive & {
            color: currentColor;
        }
    }

    &__event-description {
        font-size: 14px;
        @include line-height(18, 14);
    }

    &__event-download {
        display: block;
        position: absolute;
        padding: 5px;
        top: 50%;
        left: 26px;
        margin-top: -21px;
        transition: opacity .2s;
        &.-disabled {
            pointer-events: none;
            cursor: default;
            opacity: 0.3;
        }
        svg {
            display: block;
            width: 27px;
            height: 32px;
        }
        path {
            stroke: $colorBlack;
            transition: stroke .2s;
        }
        &:not(.-disabled):hover,
        &:not(.-disabled):active {
            path {
                stroke: $colorGreenDark;
            }
        }
        body.is-awards &:not(.-disabled):hover,
        body.is-awards &:not(.-disabled):active {
            path {
                stroke: $colorBlack;
            }
        }
        body.is-r21 &:not(.-disabled):hover,
        body.is-r21 &:not(.-disabled):active {
            path {
                stroke: $colorRedR21;
            }
        }
        body.is-page &:not(.-disabled):hover,
        body.is-page &:not(.-disabled):active {
            path {
                stroke: $colorRedMain;
            }
        }
    }

    &__noresults {
        font-weight: $fontWeightLight;
        font-size: 18px;
        color: #373737;
        @include letter-spacing(0.35, 21);
        @include line-height(24, 18);
    }

    /*
    *   Hover state
    *
    */
    $this: #{&};
    &__event-inner {
        &:hover {
            background-color: $colorGreenLighter;
            & * {
                color: currentColor !important;
            }
            body.is-awards & {
                background-color: $colorYellowButtonHover;
            }
            body.is-r21 & {
                background-color: #FFC7C7;
            }
            body.is-page & {
                background-color: #FFC7C7;
            }
            #{$this}__event-download:not(.-disabled) {
                opacity: 1;
            }
        }
    }

    @include smaller-than(m) {

        &__event-download {
            @include screenreader;
        }

    }

    @include smaller-than(l) {

        &__list {
            padding-left: 0;
            padding-right: 0;
        }

        &__section {
            padding-left: 0;
            padding-right: 0;
        }

        &__event-description {
            @include screenreader;
        }

    }

    @include bigger-than(m) {

        &__event-inner {
            position: relative;
        }

        &__event-link {
            padding: 20px $gutterMedium 18px ($gutterMedium + 53px);
            .-archive & {
                padding-left: $gutterMedium;
            }
        }

        &__event-image {
            width: 60px;
            &::before {
                left: -9px;
                top: -2px;
            }
            .image {
                width: 60px;
                height: 60px;
            }
        }

        &__event-text {
            display: flex;
            align-items: center;
            width: calc(100% - 60px);
            padding: 0 0 0 25px;
        }

        &__event-heading {
            display: inline-block;
            width: 50%;
            font-size: 18px;
            padding: 0 $gutterMedium 0 0;
            border: none;
            @include line-height(25, 18);
        }

        &__event-meta {
            display: inline-block;
            width: 50%;
            padding: 0;
            .date {
                font-size: 18px;
                @include line-height(24, 18);
            }
            .location {
                padding-top: 3px;
                font-size: 14px;
                @include line-height(24, 18);
            }
        }

    }

    @include bigger-than(l) {

        &__item {
            border-right: 1px solid $colorGreyMedium;
            border-left: 1px solid $colorGreyMedium;
            body.is-r21 & {
                border: none;
            }
        }

        &__event-link {
            padding: 20px $gutterLarge 19px ($gutterLarge + 53px);
            .-archive & {
                padding-left: $gutterLarge;
            }
        }

        &__event-heading {
            width: 35%;
            font-size: 20px;
            padding: 0;
            @include line-height(25, 20);
        }

        &__event-meta {
            width: 30%;
            line-height: 1;
            padding: 0 0 0 $gutterLarge;
            .date {
                font-size: 17px;
            }
        }

        &__event-description {
            display: inline-block;
            width: 35%;
            padding: 0 0 0 $gutterLarge;
        }

    }

}
