/*
*   The block-slideshow block basically just wraps a .content-slider element, but there's a few other things going on too LOL
*
 */

.block-slideshow {

    width: 100%;

    img {
        display: block;
        width: auto;
        height: 100%;
    }

    // This isn't really nice, but we need to set some extra styles to the content-slider wrapped by the slideshow block element :/
    &__slider {
        .-has-captions & [data-contentslider-list] {
            padding-bottom: 30px; // Vertical space for the captions
        }
        [data-contentslider-list] {
            @extend %grid-item;
        }
    }

    &__item {

        width: auto;
        height: 250px;
        margin: 0 10px 0 0;
        flex: 0 0 auto;
        position: relative;

        @include bigger-than(m) {
            height: 400px;
        }

        &-image {
            display: block;
            width: auto;
            height: 100%;
            background-size: cover;

            &-srcset {
                display: block;
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0; left: 0;
                background-size: cover;
            }
        }

        &-caption {
            display: block;
            font-size: 11px;
            position: absolute;
            left: 0;
            top: 100%;
            bottom: 0;
            padding-top: 7px;

            @include letter-spacing(0.5, 11);
            @include line-height(15, 11);
        }

    }



}