.calendar-sidebar {
    position: relative;
    height: 100%;
    width: 100%;
 
    &__lists {
        overflow: auto;
        position: relative;
        height: calc(100% - 40px);
        width: 100%;
        border-top: 1px solid $colorGreyLight;
    }
    
    &__block {
        
        &.-current {
            
        }
        &.-upcoming {
            
        }
    }
    
    &__heading {
        font-size: 14px;
        line-height: 1;
        letter-spacing: 1px;
        background: $colorBlack;
        color: $colorWhite;
        padding: 13px 0;
        
        
        .-current & {
            background: $colorGreenDark;
        }
        .-past & {
            color: $colorBlack;
            border-bottom: 1px solid $colorGreyMegamenu;
            border-left: 1px solid $colorGreyMegamenu;
            border-right: 1px solid $colorGreyMegamenu;
            border-top: 1px solid $colorGreyMegamenu;
            background-color: $colorWhite;
            background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAAM0lEQVQoU2N8/Pjxf1lZWUYGHAAsgU8RXCcuRShGY1OEYTe6IqyOQ1aE0/UwRTgVwHwHAHv2H7E3MtxPAAAAAElFTkSuQmCC);
            background-repeat: repeat;
        }
    }
    
    &__list {
        
    }
    
    &__list-item {
        
    }
    
    &__list-link {
        display: flex;
        align-items: center;
        width: 100%;
        border-left: 1px solid $colorGreyMegamenu;
        border-right: 1px solid $colorGreyMegamenu;
        border-bottom: 1px solid $colorGreyMegamenu;
        padding: 20px;
        background: $colorWhite;
        transition: background 0.4s ease;
        
        &:hover {
            background: $colorGreenLighter;
        }
        
        .-current .calendar-sidebar__list-item:last-child & {
            border-bottom: 0;
        }
        
        &-image {
            width: 70px;
            float: left;
            flex: 0 0 auto;
            position: relative;
            
            .-utstilling &::before {
                content: '';
                display: block;
                width: 30px;
                height: 30px;
                border-radius: 100%;
                overflow: hidden;
                @include gpu;
                color: $colorWhite;
                font-size: 11px;
                text-transform: uppercase;
                @include letter-spacing(0.36, 11);
                line-height: 28px;
                white-space: nowrap;
                font-weight: $fontWeightMedium;
                text-align: center;
                position: absolute;
                top: -4px;
                left: -6px;
                z-index: 2;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 160 160'%3E%3Ccircle cx='80' cy='80' r='80' fill='%23ff4747'/%3E%3Cpath d='M37.3 89.7l-21.5-3.4.7-4.1 21 3.4c7.1 1.1 10.2-.9 11.1-6.6.9-5.4-.8-9-9.1-10.3l-20.4-3.3.7-4.1 20.9 3.4c8.2 1.3 12.6 6.2 11.2 14.9-1.1 7.7-6.3 11.4-14.6 10.1zM38 36.5l-7.3 6.8-2.3-2.5 17.5-16.5 2.3 2.5-7.2 6.8 21.1 22.5-3 2.9L38 36.5zM68.8 50.5l4-.5c1 4 3.1 7.2 9.9 6.3 4.4-.6 7.4-3.6 6.9-7.4-.5-3.8-2.5-5.2-9.1-5.4-7.3-.2-11.8-1.8-12.6-7.9-.7-5.2 3.3-9.8 9.7-10.6 6.8-.9 11.4 1.9 12.9 7.8l-3.7.5c-1.4-4.2-4.1-5.6-8.8-5-4.7.6-6.8 3.2-6.4 6.4.4 3.3 1.9 4.9 9 5 7.7.2 12 2 12.8 8.4.7 5.7-3.7 10.7-10.4 11.5-8.9 1.2-12.8-3.3-14.2-9.1zM115 29.7l-8.8-4.7 1.6-3 21.3 11.3-1.6 3-8.8-4.7-14.4 27.3-3.7-1.9L115 29.7zM130.2 47.3l4.1-.9 7.3 33.4-4.1.9-7.3-33.4zM59.6 70.7l3.8-1.7L76 97.1l14.3-6.4 1.4 3.1-18 8.2-14.1-31.3zM137.8 91.2l-2.1 3.6-26.8-15.2-7.7 13.6-3-1.7 9.8-17.2 29.8 16.9zM52.2 125l-3.2 2.7-22-26.2 3.2-2.7 22 26.2zM86.8 143.1h-5.4l-16.8-28.2.1 28.3h-4l-.1-34.2h4.8l17.4 29.3-.1-29.3h4l.1 34.1zM101 127.7l-.2-.3c-5.6-8.3-4.1-18.3 4.1-23.7 5.7-3.8 12.5-4.5 17.6 1.3l-3.5 2.3c-3.8-4-8.1-3.7-12.2-.9-6.5 4.4-7.1 11.6-2.4 18.6l.2.3c4.7 7 11.3 9.3 18 4.9 6.2-4.1 6.2-9.9 2.9-15.1l-8.3 5.6-1.9-2.9L127 110l1.4 2.1c5.5 8.2 3.4 16-3.9 20.9-8.9 5.8-18 3-23.5-5.3z' fill='%23fff'/%3E%3C/svg%3E");
                background-size: 100%;
            }
            
            .image {
                display: block;
                position: relative;
                width: 70px;
                height: 70px;
                border-radius: 100%;
                overflow: hidden;
                background-color: $colorGreyLight;
                background-size: cover;
                background-position: center center;
                background-repeat: no-repeat;
                @include gpu;
                
                img {
                    display: block;
                    width: 100%;
                    height: 100%;
                }
                .-r21 &,
                .-utstilling & {
                    background-color: $colorGreyLight;
                }
                
                &:after {
                    position: absolute;
                    display: block;
                    content: ' ';
                    width: 100%;
                    height: 100%;
                    left: 0;
                    top: 0;
                    background: rgba(0, 0, 0, 0.04);
                }
            }
        }
        
        &-text {
            float: left;
            padding: 0 0 0 17px;
            flex: 0 1 auto;
        }
        
        &-meta {
            font-size: 14px;
            line-height: (18/14);
            letter-spacing: 0.5px;
            margin-bottom: 3px;
        }
        
        &-heading {
            font-size: 16px;
            line-height: (22/16);
            padding-bottom: 2px;
        }
    }
    
    &__all-link {
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        background: $colorGreyBackground;
        border: 1px solid $colorGreyMegamenu;
        z-index: 2;
        font-size: 13px;
        line-height: 1;
        letter-spacing: 1px;
        padding: 13px 6px;
        transition-property: background, color, padding;
        transition-duration: 0.1s, 0.1s, 0.3s;
        transition-timing-function: ease, ease, $easeOutSine;
        
        &:hover {
            background: $colorBlack;
            color: $colorWhite;
            padding-right: 0;
        }
        
        &-arrow {
            display: inline-block;
            width: 30px;
            height: 1px;
            background: $colorBlack;
            position: relative;
            top: -1px;
            margin-right: 10px;
            vertical-align: middle;
            transition-property: background, color, width;
            transition-duration: 0.1s, 0.1s, 0.3s;
            transition-timing-function: ease, ease, $easeOutSine;
            
            .calendar-sidebar__all-link:hover & {
                background: $colorWhite;
                width: 36px;
            }

            &:after,
            &:before {
                content: ' ';
                display: block;
                width: 6px;
                height: 1px;
                position: absolute;
                right: 0;
                background: $colorBlack;
                transform-origin: 100% 50%;
                transition-property: background, color;
                transition-duration: 0.1s;
                transition-timing-function: ease;
                
                .calendar-sidebar__all-link:hover & {
                    background: $colorWhite;
                }
            }

            &:after {
                transform: rotate(-45deg);
            }
            &:before {
                transform: rotate(45deg);
            }
        }
    }
}
