.page-footer {
    width: 100%;
    color: $colorWhite;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    border-bottom: 1px solid transparent;

    &__wrap {
        position: relative;
        z-index: 1;
        background: $colorBlack;
        margin-bottom: 299px;
    }

    &__inner {
        position: relative;
        padding-top: 25px;
        padding-bottom: 25px;

        @include bigger-than(l) {
            padding-top: 70px;
        }
    }

    &__logo {
        display: block;
        position: relative;
        margin-bottom: 30px;

        @include bigger-than(l) {
            width: 12%;
            float: left;
        }

        svg {
            display: block;
            width: 70px;
            height: 70px;
        }
    }

    &__heading {
        font-size: 13px;
        letter-spacing: 1px;
    }

    &__nav {
        font-size: 14px;
        line-height: 1.2;
        margin-bottom: 40px;

        @include bigger-than(l) {
            width: 50%;
            float: left;
            position: relative;
            top: -9px;
        }

        &-list {
            @include bigger-than(s) {
                column-count: 2;
                column-gap: $gutterSmall;
            }
            @include bigger-than(m) {
                column-gap: $gutterMedium;
            }
        }

        li {
            border-bottom: 1px solid rgba(255, 255, 255, 0.2);
            break-inside: avoid;
        }

        &-link {
            display: inline-block;
            letter-spacing: 0.5px;
            margin: 11px 0;
            opacity: 1;
            transition: color 0.2s ease;

            &:hover {
                color: $colorRedMain;
            }
        }
    }

    &__right-wrap {
        @include bigger-than(l) {
            position: relative;
            top: -5px;
            width: 38%;
            float: left;
        }
    }

    &__nav-list {

    }

    &__address,
    &__opening-hours {
        font-size: 13px;
        line-height: 25px;
        margin-bottom: 30px;
        letter-spacing: 1px;
    }

    &__address {
        @include bigger-than(l) {
            margin-bottom: 36px;
        }

        a {
            @extend %defaultLink;
            transition-property: color, box-shadow;
            
            &:hover {
                color: $colorRedMain;
            }
        }
    }

    &__opening-hours {
        @include bigger-than(l) {
            margin-bottom: 10px;
        }
    }
    &__social-icons {
        position: absolute;
        top: 45px;
        right: $outerMarginSmall;

        @include bigger-than(m) {
            right: $outerMarginMedium;
        }

        @include bigger-than($widthL) {
            position: relative;
            top: 0;
            right: auto;
            left: -10px;

            li:last-child a {
                margin-right: 0;
            }
        }

        li {
            display: inline-block;
        }

        a {
            margin: 0 10px;
            opacity: 1;
            
            
            path {
                fill: $colorWhite;
                transition: fill 0.2s ease;
            }
            
            &.-facebook {
                margin: 0 5px;
            }
            
            &:hover {
                path {
                    fill: $colorRedMain;
                }
            }
        }

    }

    &__newsletter {

        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 60px;
        margin-bottom: 30px;
        clear: both;

        @include bigger-than(m) {
            margin-top: 0;
            clear: none;
        }

        @include bigger-than(l) {
            width: 100% !important;
            float: none;
            clear: both;
        }

        &-heading {
            margin-bottom: 13px;
        }

        
    }

    &__map {
        display: block;
        position: fixed;
        z-index: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 300px;
        background: rgb(229, 227, 223); /* Google maps wrapper background color */
        overflow-hidden: true;
        &-image {
            position: absolute;
            top: 0; left: 0;
            width: 100%; height: 100%;
            object-fit: cover;
            object-position: 50% 50%;
            z-index: 1;
        }
        &-marker {
            width: 26px;
            height: 37px;
            position: absolute;
            top: 50%; left: 50%;
            transform: translate(-50%, -50%);
            z-index: 2;
        }
    }
}
