/*!
 * Styles for Grafill
 *
 * @author Værsågod / www.vaersaagod.no
 */

/*! purgecss start ignore */
@import "setup/_variables";
@import "setup/_mixins";
@import "setup/_extends";
@import "setup/_fonts";

@import "generic/_easings";
@import "generic/_mixins";
@import "generic/_normalize";
@import "generic/_helpers";
@import "generic/_animations";

@import "base/_defaults";
@import "base/_icon";
@import "base/_grid";
@import "base/_forms";
@import "base/_layout";
@import "base/_typography";
@import "base/_ui";

/*
*   Global components
*
*/
@import "blocks/page-header";
@import "blocks/mega-menu";
@import "blocks/page-footer";

/*
*   Partials
*
*/
@import "blocks/teasers-listing";
@import "blocks/article-aside";
@import "blocks/article-credits";
@import "blocks/content-slider";
@import "blocks/aside-event";
@import "blocks/r21-event-header";
@import "blocks/entry-meta";
@import "blocks/entry-type-menu";
@import "blocks/teaser";
@import "blocks/teaser-event";
@import "blocks/magazine-header";
@import "blocks/newsletter-button";
@import "blocks/events-list";
@import "blocks/events-grid";
@import "blocks/events-grid-item";
@import "blocks/event-header";
@import "blocks/share-buttons";
@import "blocks/next-prev-buttons";
@import "blocks/loadmore";
@import "blocks/winner-item";
@import "blocks/winners-list";
@import "blocks/winner-article";
@import "blocks/winners-top";
@import "blocks/winners-search";
@import "blocks/awards-hero";
@import "blocks/awards-subpage-nav";
@import "blocks/fixed-sidebar";
@import "blocks/videos-module";
@import "blocks/r21-sidebar";
@import "blocks/calendar-sidebar";
@import "blocks/feed-sidebar";
@import "blocks/frontpage-banner";
@import "blocks/feed-listing";
@import "blocks/feed-item";
@import "blocks/modal-slideshow";
@import "blocks/features-module";
@import "blocks/global-search";
@import "blocks/highlighted-video";
@import "blocks/profile";

/*
*   Matrix blocks
*
*/
@import "blocks/block";
@import "blocks/block-text-navigation";
@import "blocks/block-articles";
@import "blocks/block-articles-feed";
@import "blocks/block-list";
@import "blocks/block-banner";
@import "blocks/block-video";
@import "blocks/block-text";
@import "blocks/block-text-expandable";
@import "blocks/block-images";
@import "blocks/block-program";
@import "blocks/block-events";
@import "blocks/block-events-feed";
@import "blocks/block-quote";
@import "blocks/block-slideshow";
@import "blocks/block-callout";
@import "blocks/block-sponsors";
@import "blocks/block-table";
@import "blocks/block-links";
@import "blocks/block-people";
@import "blocks/block-profiles";

/*
*   Templates
*
*/
@import "blocks/pages/awards-index";
@import "blocks/pages/awards-archive";
@import "blocks/pages/awards-archive-page";
@import "blocks/pages/awards-page";
@import "blocks/pages/articles-listing";
@import "blocks/pages/page";
@import "blocks/pages/calendar";
@import "blocks/pages/article";
@import "blocks/pages/category-winners";
@import "blocks/pages/r21-page";
@import "blocks/pages/frontpage";
@import "blocks/pages/404";
@import "blocks/pages/newsletter-page";
/*! purgecss end ignore */
