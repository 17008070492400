.l-max-wrap,
.l-max-wrap\@gt-mp,
.l-max-wrap\@gt-l {
	display: block;
	max-width: $maxContentWidth + ($outerMarginXLarge*2);
	margin-left: auto;
	margin-right: auto;
}

.l-max-wrap {
	padding-left: $outerMarginSmall;
	padding-right: $outerMarginSmall;

	@include bigger-than($widthM) {
		padding-left: $outerMarginMedium;
		padding-right: $outerMarginMedium;
	}
}

.l-max-wrap,
.l-max-wrap\@gt-mp {
	@include bigger-than($widthMPlus) {
		padding-left: $outerMarginMediumPlus;
		padding-right: $outerMarginMediumPlus;
	}
}

.l-max-wrap,
.l-max-wrap\@gt-mp,
.l-max-wrap\@gt-l {
	@include bigger-than($widthL) {
		display: block;
		max-width: $maxContentWidth + ($outerMarginXLarge*2);
		margin-left: auto;
		margin-right: auto;
		padding-left: $outerMarginLarge;
		padding-right: $outerMarginLarge;
	}
	@include bigger-than($widthLPlus) {
		padding-left: $outerMarginLargePlus;
		padding-right: $outerMarginLargePlus;
	}
	@include bigger-than($widthXL) {
		padding-left: $outerMarginXLarge;
		padding-right: $outerMarginXLarge;
	}
}

.l-center-block {
	margin-left: auto;
	margin-right: auto;
}

