.icon {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center center;
    font-size: 0;
    line-height: 1;

    svg {
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
    }

    /*
    &__menu-arrow-down {
        width: 8px;
        height: 9px;
        background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%226.8%22%20height%3D%227.9%22%20viewBox%3D%220%200%206.8%207.9%22%3E%3Cpath%20fill%3D%22%23595959%22%20d%3D%22M3.4%207.9c-.3%200-.6-.2-.9-.5L0%205.1l.5-.6L3%206.9c.3.3.4.3.7%200l2.4-2.4.6.6-2.3%202.4c-.3.3-.6.4-1%20.4z%22/%3E%3Cpath%20class%3D%22st0%22%20d%3D%22M3%200h.8v4.8H3z%22/%3E%3C/svg%3E');
        
        @include bigger-than($widthM) {
            width: 10px;
            height: 12px;
        }
        @include bigger-than($widthL) {
            
        }
        @include bigger-than($widthXL) {
            width: 11px;
            height: 13px;
        }
    }
    */
    
    &.-search {
        width: 18px;
        height: 18px;
        background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%2026%2025.88%22%3E%3Cpath%20d%3D%22M26%2024.45l-8.28-8.28a9.92%209.92%200%201%200-1.41%201.45l8.26%208.26zm-16-6.56A7.93%207.93%200%201%201%2017.89%2010%207.94%207.94%200%200%201%2010%2017.89z%22%20fill%3D%22%23373737%22/%3E%3C/svg%3E');
    }

    &__follow {
        width: 24px;
        height: 22px;
        background-size: auto 100%;
        background-color: transparent;
        transition-property: background;
        transition-duration: 0.2s;
        transition-timing-function: $easeOutSine;

        /*
        path {
            fill: $colorWhite;
        }

        &.-facebook {
            background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%2010.09%2022%22%3E%3Cpath%20d%3D%22M6.66%204.84a.92.92%200%200%201%201-1.06h2.42V0H6.7C2.96%200%202.15%202.81%202.15%204.61v2.52H-.04V11h2.19v11h4.51V11h3l.39-3.88H6.66z%22%20fill%3D%22%23fff%22/%3E%3C/svg%3E');
        }
        &.-twitter {
            background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%2024%2019.9%22%3E%3Cpath%20d%3D%22M23.34.36a9.73%209.73%200%200%201-3.13%201.22A4.87%204.87%200%200%200%2016.62%200a5%205%200%200%200-4.92%205%205.13%205.13%200%200%200%20.13%201.14A13.88%2013.88%200%200%201%201.67.92%205.1%205.1%200%200%200%203.2%207.63%204.84%204.84%200%200%201%201%207v.06A5%205%200%200%200%204.91%2012a4.88%204.88%200%200%201-1.3.18%205%205%200%200%201-.93-.09%205%205%200%200%200%204.6%203.49%209.74%209.74%200%200%201-6.11%202.15A10.26%2010.26%200%200%201%200%2017.64a13.73%2013.73%200%200%200%207.55%202.26c9.06%200%2014-7.66%2014-14.3v-.65A10.11%2010.11%200%200%200%2024%202.36a9.66%209.66%200%200%201-2.83.79A5%205%200%200%200%2023.34.36z%22%20fill%3D%22%23fff%22/%3E%3C/svg%3E');
        }
        &.-instagram {
            background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%2022.06%2022.01%22%3E%3Cellipse%20cx%3D%2211.03%22%20cy%3D%2211%22%20rx%3D%224.41%22%20ry%3D%224.4%22%20fill%3D%22none%22/%3E%3Cpath%20d%3D%22M17.65%2011a6.62%206.62%200%200%201-13.24%200%203.68%203.68%200%200%201%20.11-1.1H2.21v9.35a.52.52%200%200%200%20.55.55H19.3a.52.52%200%200%200%20.55-.55V9.9h-2.31a3.68%203.68%200%200%201%20.11%201.1z%22%20fill%3D%22none%22/%3E%3Crect%20x%3D%2216.54%22%20y%3D%222.2%22%20width%3D%223.31%22%20height%3D%223.3%22%20rx%3D%22.55%22%20ry%3D%22.55%22%20fill%3D%22none%22/%3E%3Cpath%20d%3D%22M19.85%200H2.21A2.21%202.21%200%200%200%200%202.2v17.6A2.21%202.21%200%200%200%202.21%2022h17.64a2.21%202.21%200%200%200%202.21-2.2V2.2A2.21%202.21%200%200%200%2019.85%200zM11%206.6A4.4%204.4%200%201%201%206.62%2011%204.41%204.41%200%200%201%2011%206.6zm8.82%2012.65a.52.52%200%200%201-.55.55H2.76a.52.52%200%200%201-.55-.55V9.9h2.31a3.68%203.68%200%200%200-.11%201.1%206.62%206.62%200%200%200%2013.24%200%203.68%203.68%200%200%200-.11-1.1h2.31zm0-14.3a.52.52%200%200%201-.55.55h-2.18a.52.52%200%200%201-.55-.5V2.75a.52.52%200%200%201%20.55-.55h2.21a.52.52%200%200%201%20.55.55z%22%20fill%3D%22%23fff%22/%3E%3C/svg%3E');
        }
        &.-linkedin {
            background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%2022%2022%22%3E%3Cpath%20fill%3D%22none%22%20d%3D%22M3.26%208.25h3.26v10.5H3.26z%22/%3E%3Ccircle%20cx%3D%224.89%22%20cy%3D%224.92%22%20r%3D%221.89%22%20fill%3D%22none%22/%3E%3Cpath%20d%3D%22M14.83%208a3.43%203.43%200%200%200-3.09%201.7V8.25H8.57v10.5h3.26v-5.2c0-1.37.26-2.7%202-2.7s1.7%201.57%201.7%202.78v5.11h3.26V13c-.04-2.84-.65-5-3.96-5z%22%20fill%3D%22none%22/%3E%3Cpath%20d%3D%22M20.37%200H1.62A1.61%201.61%200%200%200%200%201.59v18.83A1.61%201.61%200%200%200%201.62%2022h18.75A1.61%201.61%200%200%200%2022%2020.42V1.59A1.61%201.61%200%200%200%2020.37%200zM6.53%2018.75H3.26V8.25h3.27zM4.89%206.81a1.89%201.89%200%201%201%201.9-1.89%201.89%201.89%200%200%201-1.9%201.89zm13.86%2011.94h-3.26v-5.11c0-1.22%200-2.78-1.7-2.78s-2%201.33-2%202.7v5.19H8.57V8.25h3.13v1.43A3.43%203.43%200%200%201%2014.83%208c3.3%200%203.91%202.17%203.91%205z%22%20fill%3D%22%23fefefe%22/%3E%3C/svg%3E');
        }
        &.-facebook-dark {
            background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%2010.09%2022%22%3E%3Cpath%20d%3D%22M6.66%204.84a.92.92%200%200%201%201-1.06h2.42V0H6.7C2.96%200%202.15%202.81%202.15%204.61v2.52H-.04V11h2.19v11h4.51V11h3l.39-3.88H6.66z%22%20fill%3D%22%23373737%22/%3E%3C/svg%3E');
        }
        &.-twitter-dark {
            background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%2024%2019.9%22%3E%3Cpath%20d%3D%22M23.34.36a9.73%209.73%200%200%201-3.13%201.22A4.87%204.87%200%200%200%2016.62%200a5%205%200%200%200-4.92%205%205.13%205.13%200%200%200%20.13%201.14A13.88%2013.88%200%200%201%201.67.92%205.1%205.1%200%200%200%203.2%207.63%204.84%204.84%200%200%201%201%207v.06A5%205%200%200%200%204.91%2012a4.88%204.88%200%200%201-1.3.18%205%205%200%200%201-.93-.09%205%205%200%200%200%204.6%203.49%209.74%209.74%200%200%201-6.11%202.15A10.26%2010.26%200%200%201%200%2017.64a13.73%2013.73%200%200%200%207.55%202.26c9.06%200%2014-7.66%2014-14.3v-.65A10.11%2010.11%200%200%200%2024%202.36a9.66%209.66%200%200%201-2.83.79A5%205%200%200%200%2023.34.36z%22%20fill%3D%22%23373737%22/%3E%3C/svg%3E');
        }
        &.-instagram-dark {
            background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%2022.06%2022.01%22%3E%3Cellipse%20cx%3D%2211.03%22%20cy%3D%2211%22%20rx%3D%224.41%22%20ry%3D%224.4%22%20fill%3D%22none%22/%3E%3Cpath%20d%3D%22M17.65%2011a6.62%206.62%200%200%201-13.24%200%203.68%203.68%200%200%201%20.11-1.1H2.21v9.35a.52.52%200%200%200%20.55.55H19.3a.52.52%200%200%200%20.55-.55V9.9h-2.31a3.68%203.68%200%200%201%20.11%201.1z%22%20fill%3D%22none%22/%3E%3Crect%20x%3D%2216.54%22%20y%3D%222.2%22%20width%3D%223.31%22%20height%3D%223.3%22%20rx%3D%22.55%22%20ry%3D%22.55%22%20fill%3D%22none%22/%3E%3Cpath%20d%3D%22M19.85%200H2.21A2.21%202.21%200%200%200%200%202.2v17.6A2.21%202.21%200%200%200%202.21%2022h17.64a2.21%202.21%200%200%200%202.21-2.2V2.2A2.21%202.21%200%200%200%2019.85%200zM11%206.6A4.4%204.4%200%201%201%206.62%2011%204.41%204.41%200%200%201%2011%206.6zm8.82%2012.65a.52.52%200%200%201-.55.55H2.76a.52.52%200%200%201-.55-.55V9.9h2.31a3.68%203.68%200%200%200-.11%201.1%206.62%206.62%200%200%200%2013.24%200%203.68%203.68%200%200%200-.11-1.1h2.31zm0-14.3a.52.52%200%200%201-.55.55h-2.18a.52.52%200%200%201-.55-.5V2.75a.52.52%200%200%201%20.55-.55h2.21a.52.52%200%200%201%20.55.55z%22%20fill%3D%22%23373737%22/%3E%3C/svg%3E');
        }
        &.-linkedin-dark {
            background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%2022%2022%22%3E%3Cpath%20fill%3D%22none%22%20d%3D%22M3.26%208.25h3.26v10.5H3.26z%22/%3E%3Ccircle%20cx%3D%224.89%22%20cy%3D%224.92%22%20r%3D%221.89%22%20fill%3D%22none%22/%3E%3Cpath%20d%3D%22M14.83%208a3.43%203.43%200%200%200-3.09%201.7V8.25H8.57v10.5h3.26v-5.2c0-1.37.26-2.7%202-2.7s1.7%201.57%201.7%202.78v5.11h3.26V13c-.04-2.84-.65-5-3.96-5z%22%20fill%3D%22none%22/%3E%3Cpath%20d%3D%22M20.37%200H1.62A1.61%201.61%200%200%200%200%201.59v18.83A1.61%201.61%200%200%200%201.62%2022h18.75A1.61%201.61%200%200%200%2022%2020.42V1.59A1.61%201.61%200%200%200%2020.37%200zM6.53%2018.75H3.26V8.25h3.27zM4.89%206.81a1.89%201.89%200%201%201%201.9-1.89%201.89%201.89%200%200%201-1.9%201.89zm13.86%2011.94h-3.26v-5.11c0-1.22%200-2.78-1.7-2.78s-2%201.33-2%202.7v5.19H8.57V8.25h3.13v1.43A3.43%203.43%200%200%201%2014.83%208c3.3%200%203.91%202.17%203.91%205z%22%20fill%3D%22%23373737%22/%3E%3C/svg%3E');
        }
        */
    }

}