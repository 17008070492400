.frontpage-banner {
    position: relative;
    
    &__image {
        background-color: $colorGreyLight;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        @include gpu;
        
        img {
            display: block;
            width: 100%;
        }
    }
    
    &__text {
        background: $colorWhite;
        @include verticallyAligned(absolute);
        width: 80%;
        margin-left: -40%;
        left: 50%;
        padding: 20px;
        letter-spacing: 1px;
        
        a {
            color: $colorRedMain;
            padding-bottom: 0;
            box-shadow: inset 0 -2px 0 currentColor;
            transition: box-shadow 0.2s ease;
        
            &:hover {
                box-shadow: inset 0 -2px 0 transparent;
            }	
        }
        
        @include bigger-than(s) {
            width: 70%;
            margin-left: -35%;
            
        }
        
        @include bigger-than(sp) {
            width: 60%;
            margin-left: -30%;
        }
        
        @include bigger-than(m) {
            width: 50%;
            margin-left: -25%;
            padding: 25px 40px;
            font-size: 27px;
            line-height: (42/27);
            
            
        }
        
        @include bigger-than(xl) {
            width: 55%;
            font-size: 30px;
            line-height: (45/30);
        }
        
    }
}