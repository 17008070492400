/*
*   Generic (article) teaser
*
*/
.teaser {

    width: 100%;
    position: relative;

    /*
    *   Inner (link)
    *
     */
    &__clickable {

        display: block;
        width: 100%;
        align-self: flex-start;

        .-type-marketplace &,
        .-type-page & {
            display: flex;
            flex-direction: column;
            align-self: stretch;
            background-color: $colorGreyDarkest;
            color: $colorWhite;
            transition: background-color 0.3s $easeOutCubic;
            &:hover,
            &:active {
                .using-mouse & {
                    background-color: #2E2E4C;
                    color: $colorWhite;
                }
            }
        }

        .-type-page & {
            background-color: $colorRedMegamenu;
        }

        .-type-page &:hover,
        .-type-page &:active {
            .using-mouse & {
                background-color: $colorRedButtonHover;
            }
        }

        .-type-magazine & {
            display: flex;
            flex-direction: column;
            align-self: stretch;
        }

        .-highlighted & {
            display: block;
        }

    }

    /*
    *   Image
    *
     */
    &__image {

        width: 100%;
        height: 119px;
        overflow: hidden;
        position: relative;

        background-size: cover;
        background-color: $colorGreyLight;

        @include gpu;

        // Image for highlighted article
        .-highlighted & {
            @include aspect(740, 330);
        }

        > * {
            transform: scale(1, 1);
            transform-origin: center center;
            transition: transform 0.5s $easeOutSine;
            will-change: transform;
            &.lazyloaded {
                transition: transform 0.5s $easeOutSine, opacity 0.3s $easeOutQuad;
            }
        }

        @include smaller-than(m) {

            @include aspect(158, 119);

            .-type-magazine & {
                @include aspect(460, 160);
            }

            @include smaller-than(s) {
                .-type-magazine & {
                    @include aspect(335, 189);
                }
            }
        }

        @include bigger-than(m) {
            height: 210px;
        }

        @include bigger-than(mp) {
            height: 230px;
        }

        @include bigger-than(lp) {
            height: 250px;
        }

        @include bigger-than(xl) {
            height: 260px;
        }

        &-image {
            width: 100%;
            height: 100%;
            background-size: cover;
            position: absolute;
            top: 0; left: 0;
        }

        &-icon {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0; left: 0;
            padding-top: 20px;
            svg {
                display: block;
                flex: 0 0 auto;
                width: 40%;
                height: auto;
                path {
                    fill: $colorWhite;
                }
            }
        }

        .-type-marketplace &::before,
        .-type-page &::before {
            content: "";
            display: block;
            width: calc(100% - 20px);
            height: 100%;
            position: absolute;
            top: 0; left: 0;
            border: 10px solid $colorGreyDarkest;
            border-bottom: none;
            z-index: 2;
            transition: border-color 0.3s $easeOutCubic;
        }

        .-type-marketplace a:hover &::before,
        .-type-marketplace a:active &::before{
            .using-mouse & {
                border-color: #2E2E4C;
            }
        }

        .-type-page & {
            background-color: transparent;
        }

        .-type-page &::before {
            border-color: $colorRedMegamenu;
        }

        .-type-page a:hover &::before,
        .-type-page a:active &::before{
            .using-mouse & {
                border-color: $colorRedButtonHover;
            }
        }

    }

    &:hover &__image > * {
        .using-mouse & {
            transform: scale(1.04, 1.04);
        }
    }

    &.-type-magazine:hover &__image > * {
        .using-mouse & {
            transform: scale(1.025, 1.025);
        }
    }

    /*
    *   Video icon
    *
     */
    &__video-icon {
        display: block;
        width: 25px;
        height: 25px;
        position: absolute;
        left: 10px;
        bottom: 10px;
        z-index: 2;
        svg {
            display: block;
            width: 100%;
            height: 100%;
        }

        @include bigger-than(m) {
            width: 30px;
            height: 30px;
            left: 14px;
            bottom: 14px;
        }
    }

    /*
    *   Text container
    *
     */
    &__text {
        padding: 12px 0;

        @include bigger-than(s) {
            padding: 18px 0 10px;
        }

        .-type-magazine & {

            flex: 1 1 auto;
            padding: 16px 28px 18px 22px;
            border: 4px solid $colorGreyLight;
            border-top: none;
            background-color: $colorWhite;
            transition: border-color 0.3s $easeOutCubic;

            @include bigger-than(m) {
                padding-top: 18px;
            }
        }

        .-type-magazine a:hover &,
        .-type-magazine a:active & {
            .using-mouse & {
                border-color: #D8D8FD;
            }
        }

        .-type-marketplace &,
        .-type-page & {
            padding: 12px 10px 18px;
            @include bigger-than(sm) {
                padding: 12px 20px 20px;
            }
            @include bigger-than(s) {
                padding: 18px 20px 18px;
            }
            @include bigger-than(sp) {
                padding: 18px 29px 30px 27px;
            }
        }

    }

    /*
    *   Meta (type, published date)
    *
     */
    &__meta {
        padding: 0 0 10px;

        &-text {
            display: block;
            width: 100%;
            position: relative;

            > * {
                transform: translate(0, 0);
                transition: transform 0.3s $easeOutCubic;
            }

            &::before {
                display: block;
                content: "";
                width: 8px;
                height: 8px;
                position: absolute;
                top: 50%;
                left: 0;
                margin-top: -4px;
                border-radius: 100%;
                overflow: hidden;
                background-color: $colorBlue;
                pointer-events: none;
                opacity: 0;
                transform: scale(0, 0);
                transform-origin: center center;
                transition: opacity 0.15s $easeOutCubic, transform 0.4s 0.15s $easeOutBack;

                .-type-page & {
                    background-color: $colorWhite;
                }

            }

            a:hover & {
                > * {
                    .using-mouse & {
                        transform: translate(13px, 0);
                    }
                }
                &::before {
                    .using-mouse & {
                        opacity: 1;
                        transform: scale(1, 1);
                    }
                }
            }
        }

    }

    /*
    *   Heading
    *
     */
    &__heading {

        font-size: 13px;
        @include line-height(16, 13);

        @include smaller-than(s) {
            
            font-weight: $fontWeightRegular;
        }
        
        @include bigger-than(s) {
            font-size: 17px;
            @include line-height(19, 17);
        }
        
        @include bigger-than(m) {

            font-size: 17px;
            @include line-height(20, 17);

            .-highlighted & {
                font-size: 24px;
                @include line-height(27, 24);
            }

        }
        @include bigger-than(l) {
            font-size: 20px;
            @include line-height(24, 20);
        }
        @include bigger-than(xl) {
            font-size: 22px;
            @include line-height(26, 22);
        }

        .-type-page & {
            font-size: 20px;
            font-weight: $fontWeightMedium;
            @include line-height(25, 20);

            @include bigger-than(m) {
                font-size: 22px;
                @include line-height(28, 22);
            }

            @include bigger-than(l) {
                font-size: 25px;
                @include line-height(32, 25);
            }
            @include bigger-than(xl) {
                font-size: 28px;
                @include line-height(35, 28);
            }
        }

        .-type-magazine & {

            font-size: 23px;
            font-weight: $fontWeightLight;
            text-transform: uppercase;
            @include letter-spacing(0.7, 23);
            @include line-height(27, 23);

            @include bigger-than(l) {
                font-size: 27px;
                @include letter-spacing(1, 27);
                @include line-height(30, 27);
            }

            @include bigger-than(xl) {
                font-size: 30px;
                @include letter-spacing(1, 30);
                @include line-height(34, 30);
            }

        }
    }

    /*
    *   Excerpt
    *
     */
    &__excerpt {

        font-size: 14px;
        margin: 10px 0 0;
        @include line-height(22, 14);

        @include smaller-than(s) {
            @include screenreader;
        }
        
        @include bigger-than(xl) {
            font-size: 16px;
            margin: 15px 0 0;
            @include line-height(21, 16);
        }

        .-type-magazine & {
            @include fontCaslon;

            font-size: 16px;
            padding: 7px 0 12px;
            margin: 0;
            @include line-height(19, 16);

            @include smaller-than(s) {
                @include screenreader;
            }

            @include bigger-than(l) {
                font-size: 18px;
                padding: 15px 0 15px;
                @include line-height(24, 18);
            }

        }

    }

    /*
    *   Tag
    *
     */
    &__tag {
        @include gpu;
        display: inline-block;
        width: auto;
        max-width: 85%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        background-color: $colorGreyDarkest;
        color: $colorWhite;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, 0);
        padding: 7px 12px 8px;
        z-index: 2;
        font-size: 11px;
        line-height: 1;
        @include letter-spacing(0.7, 11);
    }

    /*
    *   ContentSlider context
    *
     */
    $this: #{&};
    .content-slider & {
        // The teaser images vary a lot on smaller screens (i.e. magazine teasers are typically full width and have taller images
        // This makes sure that all the teaser images are the same height within the content slider
        @include smaller-than(m) {
            #{$this}__image {
                width: 100%;
                height: 140px;
                padding-top: 0;
            }
        }
    }

}