/*
*   Buttons
*
*/

// These are our different variations of buttons
$buttonThemes: (
    blue: (
        background-color: $colorBlue,
        color: $colorWhite,
    ),
    green: (
        background-color: $colorGreenDark,
        color: $colorWhite,
    ),
    red: (
        background-color: $colorRedMain,
        color: $colorWhite,
    ),
);

.u-button {

    @extend .t-center;
    @extend .t-upper;

    display: inline-block;
    appearance: none;
    padding: 0;
    background-color: $colorGreyDarkest;
    color: $colorWhite;
    border: none;
    border-radius: 0;
    line-height: 13px;
    font-size: 12px;
    padding: 8px 20px 9px;
    letter-spacing: (0.7/12) + 0em;

    &:hover,
    &:active {
        background-color: $colorBlue;
    }

    /*
    *   Themes
    *
    */
    @each $key, $theme in $buttonThemes {
        &.-#{$key} {
            @include print($theme);
            &:hover,
            &:active {
                background-color: $colorGreyDarkest;
                color: $colorWhite;
            }
        }
        &.-h-#{$key} {
            &:hover,
            &:active {
                @include print($theme);
            }
        }
    }

    /*
    *   Modifiers
    *
    */
    &.-rounded {
        border-radius: 25px;
    }

}

button.u-button {
    // Set padding on child element to combat iOS being weird
    padding: 0;
    > * {
        display: inline-block;
        padding: 8px 20px 9px;
    }
}