.content-slider {

    $this: #{&};
    user-select: none;

    &.-is-dragging > * {
        pointer-events: none;
    }

    &__header {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        > * {
            display: flex;
        }
    }

    html.no-js &__items {
        @include scrollableX;
    }

    &__header-text {
        display: flex;
        justify-content: flex-start;
        align-items: baseline;
        flex-wrap: wrap;
        min-height: 39px; // Same height as the next/prev buttons, to avoid "jumping" on JS init
        padding-bottom: 20px;
    }

    &__heading {
        font-size: 18px;
        font-weight: $fontWeightMedium;
        line-height: 1;
        margin-right: 14px;

        @include bigger-than(l) {
            font-size: 21px;
        }
    }

    &__link {
        font-size: 13px;
        @include line-height(19, 13);
        border-bottom: 1px solid currentColor;
        transition: border-bottom-color 0.3s $easeOutCubic;
        top: -1px;
        position: relative;
        padding-top: 8px;
        &:hover,
        &:active {
            border-bottom-color: transparent;
        }
    }

    &__list {
        display: flex;
        width: 100%;
        align-items: flex-start;
        position: relative;
    }

    &__list-items {

        display: flex;
        width: 100%;
        max-width: none;
        min-width: $widthMPlus - $outerMarginMediumPlus;

        > * {
            display: flex;
            flex: 0 0 auto;
            position: relative;
            z-index: 1;

            @include smaller-than(m) {
                max-width: calc(100vw - #{$gutterSmall});
                .block-slideshow & {
                    max-width: none;
                }
            }

            > * {
                display: flex;
                width: 100%;
            }
        }

        .teaser,
        .teaser-event {
            display: flex;
            width: 100%;
            height: 100%;
        }

    }

    &__nextprev {
        display: flex;
        justify-content: space-between;
        width: 68px;
        height: 39px;
        flex: 0 0 auto;
        margin-left: auto;
        transition: transform 0.35s 0.15s $easeInOutBack;
        .-next,
        .-prev {
            opacity: 0;
            transform: scale(0.75, 0.75);
            transform-origin: center center;
            transition: opacity 0.35s $easeInCubic, transform 0.5s $easeInOutBack;
            pointer-events: none;
        }
        .-next-enabled & .-next,
        .-prev-enabled & .-prev{
            opacity: 1;
            pointer-events: auto;
            transform: scale(1, 1);
        }
        #{$this}.-prev-enabled:not(.-next-enabled) & {
            transform: translate(34px, 0);
        }

    }

    @include smaller-than(m) {

        .grid-4\/16\@s {
            width: 225px;
        }

        .grid-8\/16\@s {
            width: 450px;
        }

    }

    /*
    *   Page context
    *
    */
    body.is-page &,
    body.is-r21 & {

        #{$this}__heading {
            font-family: $fontCaslon;
            font-weight: $fontWeightRegular;
            font-size: 24px;
            @include line-height(25, 24);
        }

    }

    /*
    *   Profile context
    *
     */
    body.is-page .profile &,
    body.is-r21 .profile & {
        #{$this}__heading {
            font-family: $fontGraphik;
            font-size: 18px;
            font-weight: $fontWeightMedium;
            line-height: 1;
            margin-right: 14px;

            @include bigger-than(l) {
                font-size: 21px;
            }
        }
    }

    /*
    *   Modifiers
    *
    */
    .-nested-article & {

        // When nested in an article, reduce to 9/12 grid columns to account for aside on large screens
        @include bigger-than(l) {

            #{$this}__header {
                width: grid(9, 12);
            }

            #{$this}__list {

                width: grid(9, 12);
                min-width: 0;
                position: relative;

                html.js &::before {
                    content: "";
                    display: block;
                    width: 50vw;
                    height: calc(100% + 30px);
                    background-color: $colorBackground;
                    position: absolute;
                    top: 0;
                    right: -50vw;
                    margin-right: $gutterLarge/2;
                    z-index: 2;
                }

                html.js body.is-awards &::before {
                    background-color: $colorBackgroundAwards;
                }
                html.js body.is-r21 &::before {
                    background-color: $colorBackgroundR21;
                }

                .grid-4\/16\@s {
                    width: grid(3, 9);
                }

                .grid-8\/16\@s {
                    width: grid(6, 9);
                }

            }

        }
    }

    /*
    *   Flickity stuff
    *
     */

    .flickity-enabled {
        position: relative;
    }

    .flickity-enabled:focus { outline: none; }

    .flickity-viewport {
        position: relative;
        height: 100%;
    }

    .flickity-slider {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    /* draggable */

    .flickity-enabled.is-draggable {
        -webkit-tap-highlight-color: transparent;
        tap-highlight-color: transparent;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .flickity-enabled.is-draggable .flickity-viewport {
        cursor: move;
        cursor: grab;
        cursor: -moz-grab;
        cursor: -webkit-grab;
    }

    .flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
        &, *, a {
            cursor: grabbing;
            cursor: -moz-grabbing;
            cursor: -webkit-grabbing;
        }
    }

}
