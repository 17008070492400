.teaser-event {
    display: flex;
    width: 100%;
    flex: 1 1 auto;
    position: relative;

    border: 1px solid $colorGreyMegamenu;
    transition: border-color 0.3s $easeOutCubic;

    .block-program & {
        border-top-width: 2px;
    }

    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAAM0lEQVQoU2N8/Pjxf1lZWUYGHAAsgU8RXCcuRShGY1OEYTe6IqyOQ1aE0/UwRTgVwHwHAHv2H7E3MtxPAAAAAElFTkSuQmCC);
    background-repeat: repeat;

    &.-has-link {
        background-image: none;
    }

    &__inner {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    &__meta {
        display: flex;
        flex-direction: column;
        justify-content: center;

        width: 100%;
        height: 48px;
        background-color: green;

        flex: 0 0 auto;

        background-color: rgba($colorGreenDark, 0.4);
        border-bottom: 1px solid $colorGreyMegamenu;

        transition: border-color 0.3s $easeOutCubic, background-color 0.3s $easeOutCubic;

        font-size: 12px;
        @include line-height(16, 12);

        > * {
            display: inline-block;
            width: 100%;
            padding: 0 10px;
            @include ellipsis;
            
            @include bigger-than(sp) {
                padding: 0 24px;
            }
        }

        @include bigger-than(s) {
            height: 60px;
            
            font-size: 14px;
            @include line-height(20, 14);


        }
        
        @include bigger-than(m) {
            height: 70px;
            
            .date {
                font-size: 15px;
                @include line-height(20, 15);
            }
        }

    }

    &__details {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: center;

        width: 100%;
        padding: 10px 10px 16px;
        flex: 1 0 auto;

        background-color: transparent;
        transition: background-color 0.3s $easeOutCubic;

        @include bigger-than(s) {
            padding: 15px 12px;
        }

        @include bigger-than(m) {
            padding: 18px 10px;
        }

        > * {
            width: 100%;
            flex: 1 1 auto;
        }

        &-text {
            display: flex;
            flex-direction: column;
            width: 100%;
            flex: 0 0 auto;
        }

    }

    &__heading {
        display: flex;
        align-items: center;
        width: 100%;
        height: 42px;
        font-size: 12px;
        margin: 0 auto;
        flex: 0 0 auto;
        max-width: 280px;

        @include line-height(14, 12);
        
        span {
            @include ellipsis(3, 42px);
        }

        @include bigger-than(s) {
            //align-items: flex-end;
            height: 40px;
            font-size: 15px;
            @include line-height(18, 15);
            
            span {
                @include ellipsis(2, 40px);
            }
        }

        @include bigger-than(m) {
            height: 40px;
            font-size: 16px;
            @include line-height(20, 16);
            padding: 0 10px;
        }
        
        @include bigger-than(mp) {
            height: 42px;
            font-size: 17px;
            @include line-height(21, 17);
            padding: 0 10px;
            span {
                @include ellipsis(2, 42px);
            }
        }
        
        @include bigger-than(l) {
            height: 40px;
            font-size: 16px;
            @include line-height(20, 16);
            padding: 0 10px;
        }
        
        @include bigger-than(lp) {
            height: 42px;
            font-size: 17px;
            @include line-height(21, 17);
            padding: 0 10px;
            span {
                @include ellipsis(2, 42px);
            }
        }
    }

    &__excerpt {
        display: flex;
        align-items: flex-start;
        width: 100%;
        height: 44px;
        font-size: 12px;
        margin: 6px auto 0;
        flex: 0 0 auto;

        .-no-excerpt & {
            height: 0;
            margin: 0;
        }

        max-width: 280px;

        @include line-height(15, 12);

        span {
            @include ellipsis(3, 44px);
        }

        @include smaller-than(s) {
            @include screenreader;
        }

        @include bigger-than(s) {
            padding: 0 12px;
        }

        @include bigger-than(m) {
            padding: 0 16px;
            font-size: 13px;
            height: 48px;
            margin-top: 10px;
            @include line-height(16, 13);
            
            span {
                @include ellipsis(3, 48px);
            }
        }
        
        @include bigger-than(mp) {
            font-size: 14px;
            height: 52px;
            @include line-height(18, 14);
            
            span {
                @include ellipsis(3, 54px);
            }
        }
        
        @include bigger-than(l) {
            font-size: 13px;
            height: 48px;
            @include line-height(16, 13);
            
            span {
                @include ellipsis(3, 48px);
            }
        }
         
        @include bigger-than(lp) {
            font-size: 14px;
            height: 52px;
            @include line-height(18, 14);
            
            span {
                @include ellipsis(3, 54px);
            }
        }
    }

    &__image {
        display: flex;
        width: 100%;
        flex: 0 0 auto;
        padding: 5px 12px 0;
        align-self: flex-end;

        justify-content: center;
        align-items: center;

        @include bigger-than(s) {
            padding: 20px 16px 10px;
        }

        &-wrap {
            background-size: cover;
            border-radius: 100%;
            position: relative;
            flex: 0 1 auto;
            max-width: 100%;
        }

        &-image {
            width: 100%;
            img {
                display: block;
                width: 200px;
                max-width: 100%;
                height: auto;
                transform-origin: center center;
                transition: transform 0.5s $easeOutCubic;
                border-radius: 100%;
                &.lazyloaded {
                    transition: transform 0.5s $easeOutCubic, opacity 0.3s $easeOutQuad;
                }
            }
        }

        &-overlay {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            border-radius: 100%;
            overflow: hidden;
            background-color: rgba(0, 0, 0, 0.03);
            transition: background-color 0.3s $easeOutCubic;
            z-index: 2;
        }

        &-arrow {
            display: block;
            width: 40px;
            height: 2px;
            position: absolute;
            top: 50%;
            left: 50%;
            margin: -1px 0 0 -20px;
            color: $colorGreenDark;

            opacity: 0;
            @include gpu;

            @include bigger-than(m) {
                width: 70px;
                margin: -1px 0 0 -35px;
            }

            &-line {
                display: block;
                width: 100%;
                height: 2px;
                background-color: currentColor;
                position: absolute;
                top: 0;
                left: 0;
            }

            &-dash {
                display: block;
                width: 10px;
                height: 2px;
                position: absolute;
                right: -2px;
                background-color: currentColor;
                &.-upper {
                    transform: rotate(45deg);
                    top: -3px;
                }
                &.-lower {
                    transform: rotate(-45deg);
                    top: 3px;
                }

                @include bigger-than(m) {
                    width: 14px;
                    &.-upper {
                        top: -4px;
                    }
                    &.-lower {
                        top: 4px;
                    }
                }
            }
        }

        &-r21badge {
            display: block;
            width: 50%;
            height: 1px;
            position: absolute;
            left: 0;
            top: 50%;
            transform: rotate(45deg);
            transform-origin: right center;
            z-index: 3;
            
            svg {
                display: inline-block;
                width: 28px;
                height: 28px;
                position: absolute;
                left: 0;
                top: 0;
                margin: -14px 0 0 -14px;

                @include bigger-than(s) {
                    width: 40px;
                    height: 40px;
                    margin: -20px 0 0 -20px;
                }
            }
        }

    }

    &__download {
        position: absolute;
        width: 47px;
        left: 8px;
        bottom: 8px;
        padding: 10px;
        z-index: 10;
        opacity: 0.3;
        transition: opacity 0.5s $easeOutSine;
        svg {
            display: block;
            width: 27px;
            height: 32px;
        }
        path {
            stroke: $colorBlack;
        }
        &:hover {
            .using-mouse & {
                opacity: 1;
            }
            path {
                .using-mouse & {
                    stroke: $colorGreenDark;
                    transition: stroke 0.5s $easeOutSine;
                }
            }
        }
        body.is-awards &:hover {
            .using-mouse & {
                opacity: 1 !important;
            }
            path {
                .using-mouse & {
                    stroke: $colorBlack;
                }
            }
        }
        .-past & {
            display: none !important;
        }

        @include smaller-than(m) {
            @include screenreader;
        }
    }

    /*
    *   Hover state
    *
     */
    $this: #{&};
    &.-has-link:hover,
    &.-has-link:active {
        .using-mouse & {
            border-color: #77B9A0;
        }
        #{$this}__meta {
            .using-mouse & {
                background-color: $colorGreenDark;
                border-bottom-color: #77B9A0;
            }
        }
        #{$this}__details {
            .using-mouse & {
                background-color: $colorGreenLightest;
            }
        }
        #{$this}__image {
            &-overlay {
                .using-mouse & {
                    background-color: rgba($colorGreyDarkest, 0.6);
                }
            }
        }
    }

    /*
    *   Awards context
    *
     */
    body.is-awards & {

        background-color: $colorWhite;
        border-color: $colorBackgroundAwards !important;

        #{$this}__meta {
            background-color: $colorWhite;
            border-bottom-color: $colorBackgroundAwards;
        }

        #{$this}__image {
            &-arrow {
                color: $colorYellow;
            }
        }

        &.-has-link:hover,
        &.-has-link:active {
            #{$this}__meta {
                .using-mouse & {
                    background-color: $colorGreyDarkest;
                    border-color: $colorGreyDarkest;
                    color: $colorWhite;
                }
            }

            #{$this}__details {
                .using-mouse & {
                    background-color: $colorYellow;
                }
            }
        }

    }

    /*
    *   R21 context
    *
     */
    body.is-r21 & {

        background-color: $colorWhite;
        border-color: $colorBackgroundR21 !important;

        #{$this}__meta {
            background-color: $colorWhite;
            border-bottom-color: $colorBackgroundR21;
        }

        #{$this}__image {
            &-arrow {
                color: $colorRedR21;
            }
        }

        &.-has-link:hover,
        &.-has-link:active {
            .using-mouse & {
                border-color: darken(#FFC7C7, 5%) !important;
            }

            #{$this}__meta {
                .using-mouse & {
                    background-color: #FFC7C7;
                    border-color: $colorBackgroundR21;
                    border-bottom-color: darken(#FFC7C7, 5%);
                }
            }

            #{$this}__details {
                .using-mouse & {
                    background-color: #FFC7C7;
                }
            }
        }

    }

    /*
    *   Pages (Nyttig) context
    *
     */
    /*body.is-page & {

        background-color: $colorWhite;
        border-color: $colorBackgroundR21 !important;

        #{$this}__meta {
            background-color: $colorWhite;
            border-bottom-color: $colorBackgroundR21;
        }

        #{$this}__image {
            &-arrow {
                color: $colorRedButtonHover;
            }
        }

        &.-has-link:hover,
        &.-has-link:active {
            .using-mouse & {
                border-color: darken(#FFC7C7, 5%) !important;
            }

            #{$this}__meta {
                .using-mouse & {
                    background-color: #FFC7C7;
                    border-color: $colorBackgroundR21;
                    border-bottom-color: darken(#FFC7C7, 5%);
                }
            }

            #{$this}__details {
                .using-mouse & {
                    background-color: #FFC7C7;
                }
            }
        }

    }*/

    /*
    *   Events grid context
    *
     */
    .events-grid & {

        #{$this}__details {
            @include bigger-than(s) {
                padding-bottom: 24px;
            }
        }

        #{$this}__meta {
            background-color: transparent;
        }

        #{$this}__heading,
        #{$this}__excerpt {
            max-width: 300px;
        }

        #{$this}__image {
            &-wrap {
                max-width: 240px;
            }
        }

        &.-has-link:hover,
        &.-has-link:active {
            #{$this}__meta {
                .using-mouse & {
                    background-color: rgba($colorGreenDark, 0.4);
                }
            }
        }
    }

    // Past events in events grid
    .events-grid &.-past {

        #{$this}__meta {
            color: $colorGreyMedium;

            .date {
                text-decoration: line-through;
                color: $colorBlack;
                time {
                    color: $colorGreyMedium;
                }
            }

        }

        &:hover,
        &:active {
            #{$this}__meta {
                .using-mouse & {
                    color: $colorBlack;
                }
                * {
                    .using-mouse & {
                        color: currentColor !important;
                    }
                }
            }
        }

    }

    /*
    *   Program context
    *
     */
    .program & {

        background-color: $colorWhite;
        border-left-width: 2px;
        border-right-width: 2px;
        border-bottom-width: 2px;

        #{$this}__meta {
            background-color: $colorWhite;
            border-bottom-width: 2px;
        }

        #{$this}__details {
            @include bigger-than(m) {
                padding-top: 8%;
            }
        }

        #{$this}__image {
            @include bigger-than(m) {
                padding-top: 12%;
                padding-bottom: 8%;
            }
        }

    }

    /*
    *   Content Slider & Linear grid context (i.e. standalone; i.e. frontpage)
    *
     */
    [data-component="ContentSlider"] &,
    [data-component="LinearGrid"] & {
        &.-upcoming {

            background-color: $colorWhite;
            border-color: #E6E6E6 !important;

            #{$this}__meta {
                background-color: #F4F4F4;
                border-bottom-color: #E6E6E6;
            }

            #{$this}__image {
                &-arrow {
                    color: #C8C8C8;
                }
            }

            &.-has-link:hover,
            &.-has-link:active {
                .using-mouse & {
                    border-color: #E6E6E6 !important;
                }

                #{$this}__meta {
                    .using-mouse & {
                        color: $colorGreyDarkest !important;
                        background-color: #C8C8C8;
                        border-color: #E6E6E6 !important;
                    }
                }

                #{$this}__details {
                    .using-mouse & {
                        background-color: #F4F4F4 !important;
                    }
                }

                #{$this}__image {
                    &-overlay {
                        .using-mouse & {
                            background-color: rgba($colorGreyDarkest, 0.8);
                        }
                    }
                }
            }
        }

        &.-past {

            background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAAM0lEQVQoU2N8/Pjxf1lZWUYGHAAsgU8RXCcuRShGY1OEYTe6IqyOQ1aE0/UwRTgVwHwHAHv2H7E3MtxPAAAAAElFTkSuQmCC);
            background-repeat: repeat;
            border-color: #E6E6E6;

            #{$this}__meta {
                background-color: transparent;
                color: $colorGreyDark;
                .date {
                    text-decoration: line-through;
                }
                .using-mouse & {
                    transition: color 0.3s;
                }
            }

            #{$this}__details {
                background-color: $colorWhite;
            }

            #{$this}__image {
                &-arrow {
                    color: #C8C8C8;
                }
            }

            &.-has-link:hover,
            &.-has-link:active {

                .using-mouse & {
                    border-color: #E6E6E6 !important;
                }

                #{$this}__meta {
                    .using-mouse & {
                        color: $colorGreyDarkest !important;
                        border-color: #E6E6E6 !important;
                        background-color: transparent !important;
                    }
                }

                #{$this}__details {
                    background-color: $colorWhite !important;
                }

                #{$this}__image {
                    &-overlay {
                        .using-mouse & {
                            background-color: rgba($colorGreyDarkest, 0.8);
                        }
                    }
                }
            }
        }
    }

}
