.entry-meta {

    font-size: 11px;
    hyphens: manual;

    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    @include letter-spacing(0.7, 12);
    @include line-height(13, 12);

    .type {
        padding-right: 3px;
    }

}