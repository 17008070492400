.winner-item {
    @include smaller-than(m) {
        padding-left: 0 !important;
        padding-right: 0 !important;
        border-bottom: 2px solid $colorGreyBackground;
        
        @include smaller-than(m) {
            margin-bottom: 20px;
        }
        
        &:last-child {
            border-bottom: none;
        }
    }

    @include bigger-than(m) {
        margin-bottom: $gutterMedium;
    }

    @include bigger-than(mp) {
        margin-bottom: $gutterMediumPlus;
    }

    @include bigger-than(l) {
        margin-bottom: $gutterLarge;
    }

    @include bigger-than(lp) {
        margin-bottom: $gutterLargePlus;
    }

    @include bigger-than(xl) {
        margin-bottom: $gutterXLarge;
    }
    
    &__inner {
        position: relative;
        background: $colorGreyMedium;
        transition: background 0.4s ease;

        &:hover {
            background: #b0b0b0;
        }
        .-won-gold & {
            background: $colorBlack;
            color: #fff;
            
            &:hover {
                background: #111;
            }

            &:before {
                content: ' ';
                width: 36px;
                height: 36px;
                background: $colorYellow;
                border-radius: 50%;
                display: block;
                position: absolute;
                left: 18px;
                top: -18px;

                @include bigger-than(m) {
                    width: 30px;
                    height: 30px;
                    left: -15px;
                    top: -15px;
                }

                @include bigger-than(l) {
                    width: 50px;
                    height: 50px;
                    left: -25px;
                    top: -25px;
                }
            }
        }
    }

    &__link {
        font-size: 12px;

        @include smaller-than(m) {
            display: flex;
        }

        @include bigger-than(m) {
            font-size: 15px;
        }

        .-won-gold & {
            font-size: 14px;

            @include smaller-than(m) {
                flex-wrap: wrap;
            }

            @include bigger-than(l) {
                font-size: 19px;
            }
        }
    }

    &__text-area {
        padding: 14px 20px;

        @include bigger-than(m) {
            padding: 20px;
        }

        .-won-gold & {
            padding: 20px;

            @include smaller-than(m) {
                width: 100%;
                order: 2;
            }

            @include bigger-than(m) {
                padding: 20px;
            }

            @include bigger-than(l) {
                padding: 20px 40px;
            }

        }

        .-won-silver &,
        .-won-diploma & {
            @include smaller-than(m) {
                width: 100%;
                flex: 1 1 auto;
                order: 2;
                overflow: hidden;
                //border-bottom: 1px solid $colorWhite;
            }
        }
    }

    &__image-area {
        background: rgba(255, 255, 255, 0.2);
        overflow: hidden;

        .-won-gold & {
            @include smaller-than(m) {
                width: 100%;
                order: 1;
            }
        }

        .-won-silver &,
        .-won-diploma & {
            @include smaller-than(m) {
                flex: 0 0 auto;
                width: 120px;
                height: 120px;
                order: 1;
            }
        }

    }

    &__category,
    &__entity,
    &__project-name {
        .-won-silver &,
        .-won-diploma & {
            @include smaller-than(m) {
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }
    }

    &__entity,
    &__project-name {
        .-won-gold & {
            //-webkit-font-smoothing: antialiased;
            //-moz-osx-font-smoothing: grayscale;
            letter-spacing: 0.7px;
        }
    }

    &__award-category-wrap {
        @include bigger-than(l) {
            .-won-gold & {
                display: block;
                vertical-align: bottom;
                margin-bottom: 30px;
            }
        }
    }

    &__award {
        font-size: 16px;
        margin-bottom: 4px;

        .-won-gold & {
            font-size: 20px;
            letter-spacing: 1px;
        }

        @include bigger-than(m) {
            font-size: 20px;

            .-won-gold & {
                font-size: 28px;
                letter-spacing: 1px;
            }
        }

        @include bigger-than(l) {
            font-size: 24px;

            .-won-gold & {
                font-size: 48px;
                display: inline-block;
                padding-right: 6px;
                letter-spacing: 1px;
            }
        }
    }

    &__category {
        font-size: 12px;
        letter-spacing: 1px;
        margin-bottom: 10px;
        
        .-won-gold & {
            letter-spacing: 2px;
        }

        @include bigger-than(m) {
            font-size: 14px;
            margin-bottom: 20px;
        }
        @include bigger-than(l) {
            .-won-gold & {
                display: inline-block;
            }
        }
    }

    &__entity {

    }
    &__project-name {
        @include bigger-than(m) {
            margin-top: 10px;
            padding: 5px 10px 8px 10px;
            background: rgba(255, 255, 255, 0.8);

            .-won-gold & {
                margin-top: 14px;
                background: rgba(255, 255, 255, 0.15);;
            }
        }
    }
}

@include smaller-than(m) {
    .winner-item.-won-gold + .winner-item.-won-gold {
        margin-top: 40px;
    }
}
