/*
*   Event header
*
*/
.event-header {

    padding-top: 30px;
    padding-bottom: 20px;
    margin-bottom: 20px;
    hyphens: manual;

    &.-is-upcoming {
        padding-bottom: 85px;
    }

    @include bigger-than(m) {

        background-color: transparent !important;
        padding-top: 32px;
        padding-bottom: 40px;
        margin-bottom: 0;

        body.is-awards & {
            padding-top: $gutterMedium;
            padding-bottom: $gutterMedium;
        }

    }

    @include bigger-than(l) {

        padding-bottom: 80px;

        body.is-awards & {
            padding-top: 62px;
            padding-bottom: 50px;
        }
    }

    &__inner {
        width: 100%;
        position: relative;
        @include bigger-than(m) {
            display: block;
            body.is-awards & {
                background-color: $colorBackground;
                padding: 37px 18px 50px 13px;
            }
        }
        @include bigger-than(l) {
            width: grid(12, 16);
            body.is-awards & {
                width: 100%;
            }
        }
    }

    &__date {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        float: left;
        clear: left;
        order: 0;
        font-size: 16px;
        line-height: 1.2;
        letter-spacing: 0.043em;
        padding-bottom: 14px;
        .details {
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            border-bottom: 1px solid $colorGreyLight;
            padding-bottom: 9px;
            > * {
                display: inline-block;
                padding-left: 7px;
                &:first-child {
                    padding-left: 0;
                }
            }
        }
        .date {
            font-weight: $fontWeightMedium;
            letter-spacing: (0.7 / 16) + 0em;
        }
        .time {
            margin-left: auto;
        }

        @include smaller-than(m) {
            .location {
                @include screenreader;
            }
        }

        @include bigger-than(m) {
            width: grid(10, 12);
            margin-left: auto;
            float: right;
            padding-bottom: 17px;
            .details {
                padding-bottom: 15px;
                border-bottom: 2px solid $colorGreenDark;
            }
            body.is-awards & {
                padding-left: 25px;
                width: calc(100% - 170px);
                .details {
                    border-bottom-color: $colorGreyLight;
                }
                .time {
                    margin-left: 0;
                }
            }
        }
    }

    &__image {
        width: 110px;
        float: left;
        clear: left;
        order: 1;
        margin-top: -3px;
        .image {
            display: block;
            width: 100%;
            background-color: $colorGreyLight;
            background-size: cover;
            border-radius: 100%;
            overflow: hidden;
            position: relative;
            @include gpu;
            &::before {
                content: "";
                display: block;
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0; left: 0;
                background-color: rgba($colorBlack, 0.03);
            }
            img {
                display: block;
                width: 100%;
            }
        }

        @include smaller-than(sm) {
            width: 100%;
            padding-bottom: 5px;
            .image {
                max-width: 90px;
            }
        }

        @include bigger-than(sm) {
            @include smaller-than(m) {
                width: 110px;
                height: 110px;
                position: absolute;
                top: 50px; left: 0;
            }
        }

        @include bigger-than(m) {
            width: grid(2, 12);
            margin-top: 0;
            padding-bottom: 5px;
            .image {
                width: 100%;
                max-width: 130px;
                margin-left: auto;
            }
            body.is-awards & {
                width: 170px;
            }
        }

        @include bigger-than(l) {
            .image {
                width: calc(100% + 16px);
            }
        }
    }

    &__text {
        width: calc(100% - 110px);
        margin-left: auto;
        order: 2;

        @include smaller-than(sm) {
            width: 100%;
            margin: 0;
            padding-left: 8px;
        }

        @include bigger-than(sm) {
            @include smaller-than(m) {
                width: 100%;
            }
        }

        @include bigger-than(m) {
            width: grid(10, 12);
            margin-left: auto;
            body.is-awards & {
                width: calc(100% - 170px);
                padding-left: 25px;
            }
        }

    }

    &__heading {
        font-size: 20px;
        @include line-height(24, 20);

        @include bigger-than(sm) {
            @include smaller-than(m) {
                display: flex;
                width: 100%;
                align-items: center;
                float: left;
                clear: both;
                min-height: 100px;
                padding-left: 110px;
            }
        }

        @include bigger-than(m) {
            font-size: 36px;
            line-height: 1.2;
            font-weight: $fontWeightMedium;
            margin: 0 0 10px;
        }
        
        @include bigger-than(lp) {
            font-size: 48px;
        }
    }

    &__leading {
        padding-top: 10px;
        .excerpt {
            font-size: 16px;
            line-height: 1.4;
            padding-bottom: 10px;
        }
        .excerpt a {
            border-bottom: 2px solid currentColor;
            transition: color 0.15s $easeOutCubic, border-color 0.15s $easeOutCubic;
            &:hover,
            &:active {
                color: $colorGreenDark;
            }
            body.is-awards & {
                color: $colorBlack;
                &:hover,
                &:active {
                    border-color: $colorYellow;
                }
            }
        }

        .actions {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            /*position: absolute;
            left: 0;
            top: 100%;*/
            padding-top: 20px;
            //@extend %grid-item;
            @include bigger-than(m) {
                padding-left: 0;
                padding-right: 0;
            }
        }

        @include bigger-than(sm) {
            @include smaller-than(m) {
                width: 100%;
                float: left;
                clear: both;
                padding-top: 10px;
            }
        }

        @include bigger-than(m) {
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: flex-start;
            padding-top: 10px;
            position: relative;
            .excerpt {
                flex: 1 1 auto;
                font-size: 20px;
                padding-right: 10px;
            }
            .actions {
                display: flex;
                align-items: flex-start;
                flex: 0 0 auto;
                position: relative;
                width: auto;
                height: 0;
                padding: 0;
                top: 8px;
            }
            body.is-awards & {
                padding-top: 15px;
            }
        }
    }

    &__download {
        display: flex;
        align-items: center;
        width: auto;
        height: 25px;
        font-size: 14px;
        line-height: 1;
        position: relative;
        padding-left: 34px;
        .label {
            display: inline-block;
            width: auto;
        }
        .icon {
            display: inline-block;
            position: absolute;
            top: 0; left: 0;
            svg {
                display: block;
                width: 23px;
                height: 25px;
            }
        }
        &:hover,
        &:active {
            .label {
                text-decoration: underline;
            }
        }

        @include bigger-than(m) {
            @include screenreader;
        }
    }

    // Add a white frame for awards
    body.is-awards & {
        background-color: $colorBackground;
    }

    // Hack to remove outer padding for the frame on smaller screens
    body.is-awards &__outer {
        @extend %grid-item;
        @include smaller-than(m) {
            padding-left: 0;
            padding-right: 0;
        }
    }

    // Hide type navigation for small screens, and awards
    &__typenav {
        body.is-awards & {
            @include screenreader;
        }
        @include smaller-than(m) {
            @include screenreader;
        }
        @include bigger-than(m) {
            width: grid(10, 12);
            margin-left: auto;
            padding-bottom: 11px;
        }
    }

}