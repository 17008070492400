.block-program {

    &__program + &__program {
        padding-top: 10px;
    }

    @include bigger-than(m) {
        &__program + &__program {
            padding-top: 40px;
        }
    }

    @include bigger-than(l) {
        &__program + &__program {
            padding-top: 60px;
        }
    }

}

.program {

    padding-top: 22px;
    padding-bottom: 17px;

    .block & {
        padding-top: 0;
        padding-bottom: 10px;
    }

    &__header {
        padding-bottom: 24px;
    }

    &__heading {
        font-size: 20px;
        @include line-height(23, 20);
    }

    &__byline {
        font-size: 13px;
        @include line-height(19, 13);
        padding-top: 5px;
        .link {
            text-decoration: underline;
            &:hover,
            &:active {
                text-decoration: none;
            }
        }
    }

    &__actions {
        padding-top: 10px;
    }

    @include bigger-than(s) {

        padding-top: 30px;
        padding-bottom: 30px;

        &__header {
            padding-bottom: 20px;
        }

        &__header-wrap {
            display: flex;
            width: 100%;
            justify-content: flex-start;
            align-items: baseline;
        }

        &__details {
            display: flex;
            width: auto;
            flex-wrap: wrap;
            align-items: baseline;
            flex: 0 1 auto;
        }

        &__heading {
            width: auto;
            font-size: 24px;
            flex: 0 1 auto;
            padding-right: 0;
        }

        &__byline {
            width: auto;
            display: flex;
            flex-wrap: wrap;
            flex: 0 1 auto;
            .description {
                padding-right: 12px;
            }
        }

        &__actions {
            width: auto;
            flex: 0 0 auto;
            margin-left: auto;
            text-align: right;
        }
    }

    @include bigger-than(l) {

        padding-top: 40px;
        padding-bottom: 40px;

    }

    /*
    *   Modifiers
    *
    */
    $this: #{&};

    .-nested-article & {
        @include bigger-than(l) {

            &__header-wrap {
                width: grid(9, 12);
            }

        }
    }

    /*
    *   Override the button hover colors for particular contexts
    *
     */
    body.is-awards & .u-button:hover {
        background-color: $colorYellowButtonHover;
        color: $colorGreyDarkest;
    }

    body.is-page & .u-button:hover {
        background-color: $colorRedButtonHover;
        color: $colorWhite;
    }

    body.is-r21 & .u-button:hover {
        background-color: $colorRedR21;
    }

    /*
    *   Awards index context, increase heading size
    *
     */
    body.is-awards-index &__heading {

        font-weight: $fontWeightLight;
        text-transform: uppercase;
        font-size: 20px;

        @include bigger-than(l) {
            font-size: 30px;
        }

    }

}
