.entry-type-menu {

    &__type {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: baseline;
        line-height: 1;
        .type,
        .date {
            display: inline-block;
            font-size: 12px;
            border-bottom: 1px solid transparent;
            margin-right: 3px;
            @include letter-spacing(0.7, 12);
            order: 0;
        }
        .type {
            font-weight: $fontWeightBold;
        }
        .link {
            display: inline-block;
            text-transform: none;
            font-size: 14px;
            border-bottom: 1px solid transparent;
            transition: border-color 0.3s $easeOutCubic;
            cursor: pointer;
            &:hover {
                border-color: currentColor;
            }
        }
        .link.toggle {
            display: none;
        }
    }

    &__type-toggles {
        order: 1;
        margin-left: auto;
    }

    /*
    *   Dropdown design
    *
     */
    @include smaller-than(m) {

        &__wrapper {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 100%;
        }

        &__type {
            order: 1;
            &-toggles .link {
                padding-bottom: 2px;
            }
        }

        &__nav {
            width: 100%;
            position: relative;
            order: 0;
            margin-bottom: 15px;
            border-bottom: 1px solid $colorGreyLight;

            &-heading {
                @include screenreader;
            }
        }

        &__items {
            width: 100%;
        }

        &__item {

            display: block;
            width: 100%;
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 1;

            border-left: 1px solid $colorGreyLight;
            border-right: 1px solid $colorGreyLight;
            border-top: 1px solid $colorGreyLight;

            color: $colorBlack;
            background-color: $colorWhite;

            cursor: pointer;

            a {
                display: block;
                width: 100%;
                color: currentColor;
                padding: 10px 19px;
                text-align: center;
                font-size: 18px;
                position: relative;
                z-index: 1;
                white-space: nowrap;
                overflow: hidden;
                @include letter-spacing(0.89, 18);
                @include line-height(24, 18);
            }

            .icon {
                position: absolute;
                top: 50%;
                right: 19px;
                width: 14px;
                height: 7px;
                margin-top: -3px;
                transition: transform .3s;
                transform-origin: center center;
                svg {
                    display: block;
                    width: 100%;
                    height: 100%;
                    path {
                        stroke: currentColor;
                    }
                }
            }

            .toggle {
                display: block;
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0; left: 0;
            }

            &.-toggler {
                position: relative;
                z-index: 2;
            }

            &.-current {
                @include screenreader;
            }

            &:hover {
                background-color: $colorBlue;
                color: $colorWhite;
                border-color: $colorBlue;
            }

            body.is-awards &:hover {
                background-color: $colorYellowButtonHover;
                color: $colorGreyDarkest;
                border-color: $colorYellowButtonHover;
            }

        }

        /*
        *   Open state
        *
        */
        html.no-js &:target &__item {
            position: relative;
            .icon {
                transform: rotate(180deg);
            }
            .toggle {
                z-index: 2;
            }
        }

        &.-open &__item .icon {
            transform: rotate(180deg);
        }

    }

    /*
    *   Horizontal buttons design
    *
     */
    @include bigger-than(m) {

        &__wrapper {
            display: flex;
            align-items: center;
        }

        .toggle {
            display: none;
        }

        &__type {

            flex-wrap: nowrap;
            align-items: center;
            width: auto;
            height: 30px;
            position: relative;
            white-space: nowrap;
            padding-right: 10px;
            z-index: 2;

            > * {
                display: inline-block;
            }

            &::after {
                content: "";
                display: block;
                width: 0;
                height: 0;
                border-top: 4px solid transparent;
                border-bottom: 4px solid transparent;
                border-left: 8px solid currentColor;
                position: relative;
                margin-left: 10px;
                top: 0;
                order: 2;
                @include gpu;
                transition: transform .3s;
            }

        }

        .type {
            transition: color 0.3s $easeOutCubic, border-bottom-color 0.3s $easeOutCubic;
        }

        &__type-toggles {
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0; left: 0;
            cursor: pointer;
            .label {
                @include screenreader;
            }
            &:hover + .type {
                color: $colorBlue;
                border-color: $colorBlue;
            }
            .link,
            .link.toggle {
                display: block;
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0; left: 0;
                border: none !important;
                z-index: 1;
                padding-bottom: 0;
            }
            .link.toggle.-open {
                z-index: 2;
            }
            html.js & .link.index {
                z-index: 3;
            }
        }

        &__nav {
            width: 100%;
            position: relative;
            z-index: 1;

            * {
                transition: border-color 0.3s $easeOutCubic, color 0.3s $easeOutCubic, background-color 0.3s $easeOutCubic;
            }

            .-buttons-enabled &-heading {
                @include screenreader;
            }

            .-buttons-disabled &-heading {

                display: inline-block;
                padding-left: 10px;
                padding-right: 10px;
                order: 2;

                @include bigger-than(l) {
                    padding-left: 20px;
                    padding-right: 20px;
                }
            }

            .-buttons-disabled.-align-buttons-right &-heading {
                padding-left: 0;
            }

        }

        &__items {

            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: 100%;
            position: relative;

            .-buttons-disabled & {
                justify-content: center;
            }

            .-align-buttons-right.-buttons-disabled & {
                justify-content: flex-start;
            }

        }

        &__item {

            display: inline-block;
            flex: 0 1 auto;
            margin: 5px;

            html.js .-buttons-enabled & {
                opacity: 0;
                pointer-events: none;
            }

            .-buttons-disabled &:nth-child(3) {
                order: 0;
            }

            .-buttons-disabled &:nth-child(4) {
                order: 1;
            }

            .-buttons-disabled &:nth-child(5) {
                order: 3;
            }

            .-buttons-disabled &:nth-child(6) {
                order: 4;
            }


            .-align-buttons-right.-buttons-disabled &:nth-child(3) {
                order: 2;
            }

            .-align-buttons-right.-buttons-disabled &:nth-child(4) {
                order: 3;
            }

            .-align-buttons-right.-buttons-disabled &:nth-child(5) {
                order: 4;
            }

            .-align-buttons-right.-buttons-disabled &:nth-child(6) {
                order: 5;
            }


            .-buttons-disabled &:nth-child(n+7) {
                @include screenreader;
            }

            .icon {
                display: none;
            }

            a {
                display: block;
                font-weight: $fontWeightMedium;
                font-size: 12px;
                color: $colorGreyDark;
                border: 1px solid $colorGreyMegamenu;
                padding: 7px 18px 8px;
                background-color: $colorWhite;
                @include letter-spacing(0.69, 12);
                @include line-height(13, 12);
                &:hover {
                    background-color: $colorBlue;
                    border-color: $colorBlue;
                    color: $colorWhite;
                }
                html.js .-buttons-enabled & {
                    pointer-events: none;
                }

                @media (max-width: 840px) {
                    font-size: 10px;
                }
            }

            &.-event a:hover {
                background-color: $colorGreenDark;
                border-color: $colorGreenDark;
            }

            &.-marketplace a:hover {
                background-color: $colorGreyDarkest;
                border-color: $colorGreyDarkest;
            }

            &.-toggler {
                @include screenreader;
            }

        }

        /*
        *   Open state
        *
         */
        html.no-js &:target &__type {
            &::before {
                transform: rotate(-180deg);
            }
            .type {
                border-color: currentColor;
            }
            .link.toggle.-open {
                z-index: 1;
            }
            .link.toggle.-close {
                z-index: 2;
            }
        }

        html.no-js &:target &__nav {
            width: 100%;
        }

        html.no-js &:target &__item a {
            pointer-events: auto;
        }

        &.-open &__item a {
            pointer-events: auto !important;
        }

        &.-open .type {
            color: $colorBlue;
            border-color: $colorBlue;
        }

        &.-open &__type {
            &::after {
                transform: rotate(-180deg);
            }
            .type {
                border-color: currentColor;
            }
        }

    }

}