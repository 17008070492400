.aside-event {

    padding-bottom: 50px;

    &__items > * {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    @include smaller-than(m) {

        padding-left: 0 !important;
        padding-right: 0 !important;

        // Total hack!
        .events-grid.-total-1 {
            width: calc(100% - #{$gutterSmall * 2});
            margin-left: $gutterSmall;
            margin-right: $gutterSmall;
            overflow: visible;
        }

        &__header {
            padding-left: $gutterSmall / 2;
            padding-right: $gutterSmall / 2;
        }

    }

    @include bigger-than(l) {

        padding-bottom: 0;

        .events-grid {
            width: 100% !important;
        }

        .events-grid__item {
            width: 100% !important;
            left: 0 !important;
            &:not(.count-1):not(.count-2):not(.count-3) {
                @include screenreader;
            }
        }

    }

    /*
    *   Awards context
    *
     */
    body.is-awards & {
        padding-top: 30px;

        @include bigger-than(l) {
            padding-top: 46px;

            .events-grid {
                margin-top: -8px;
            }
        }
    }

}