@mixin fontGraphik {
    font-family: $fontGraphik;
}

@mixin fontCaslon {
    font-family: $fontCaslon;
}

@mixin scrollable {
    overflow: hidden;
    overflow-y: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    -webkit-overflow-scrolling: touch;
}

@mixin scrollableY {
    @include scrollable;
}

@mixin scrollableX {
    overflow: hidden;
    overflow-x: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    -webkit-overflow-scrolling: touch;
}


@mixin unscrollable {
    overflow: visible;
    overflow-y: visible;
    overflow-x: visible;
    -ms-overflow-style: none;
    -webkit-overflow-scrolling: none;
}
