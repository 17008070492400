.block-table {

    .block-text + & {
        padding-top: 0;
    }

    &__table {

        width: 100%;
        border-top: 1px solid $colorGreyMedium;
        overflow: hidden;

        @include bigger-than(m) {
            border-top: 0;
        }

        &-row {
            width: 100%;
            border-bottom: 1px solid $colorGreyMedium;
        }

        &-header {
            text-align: left;
            vertical-align: top;
            font-size: 14px;
            padding: 15px $gutterSmall 13px 0;
            @include line-height(18, 14);

            @include bigger-than(m) {
                font-weight: $fontWeightRegular;
                font-size: 20px;
                padding: 12px $gutterMedium 15px 0;
                @include line-height(22, 20);
            }

            @include bigger-than(l) {
                padding-right: $gutterLarge;
            }

        }

        &-cell {
            text-align: left;
            vertical-align: top;
            font-size: 14px;
            padding: 15px $gutterSmall 13px 0;
            @include line-height(18, 14);

            &.-last {
                padding-right: 0 !important;
            }

            @include bigger-than(m) {
                padding-right: $gutterMedium;
            }

            @include bigger-than(l) {
                padding-right: $gutterLarge;
            }

        }
    }

}