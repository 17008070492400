.page-calendar {

    padding-bottom: 50px;

    &__header {

        padding-top: 10px;
        padding-bottom: 15px;

        @include bigger-than(m) {
            padding-top: 20px;
            padding-bottom: 25px;
        }

        &-inner {

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;

            @include bigger-than(m) {

            }

            @include bigger-than(l) {
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: space-between;
                &::before {
                    content: "";
                    display: block;
                    width: 108px;
                }
            }
        }

        &-entrytypemenu {
            width: 100%;
            flex: 1 1 auto;

            @include bigger-than(m) {
                padding-left: 65px;
            }

            @include bigger-than(l) {
                width: auto;
                padding: 10px 0;
            }
        }

        &-viewmenu {

            width: 108px;
            flex: 0 0 auto;

            padding: 5px 0 7px;

            @include bigger-than(m) {
                padding: 10px 0;
            }

        }

    }

    &__heading,
    .entry-type-menu__nav-heading { // This is dirty, but the entry type menu doubles as the listing heading for some contexts - I think this is easier than adding identical styles to _entry-type-menu.sccs (that file is sort of a clusterfuck)
        font-size: 36px;
        padding-bottom: 20px;
        @include letter-spacing(1.12, 36);
        @include line-height(48, 36);

        @include bigger-than(m) {
            padding-bottom: 5px;
        }
    }

    &.-has-entry-type-menu &__heading {
        @include screenreader;
    }

}

.calendar-viewmenu {

    display: block;
    width: auto;
    user-select: none;
    
    &__item {
        display: block;
        width: 48px;
        height: 48px;
        float: left;
        margin: 0 6px;
        position: relative;
        &::before {
            content: "";
            display: block;
            width: 100%;
            height: 100%;
            background-color: $colorGreyLight;
            position: absolute;
            top: 0; left: 0;
            transform-origin: center center;
            transition: background-color .3s $easeOutCubic, transform 0.3s $easeOutBack;
            will-change: transform, background-color;
            border-radius: 100%;
            overflow: hidden;
        }
        a {
            display: block;
            width: 100%;
            height: 100%;
            position: relative;
        }
        svg {
            display: block;
            position: absolute;
            left: 50%; top: 50%;
            transform: translate(-50%, -50%);
            g {
                fill: $colorGreyDark;
                transition: fill .2s $easeOutCubic;
            }
        }
        &.is-last {
            margin-right: 0;
        }
        &.is-first {
            margin-left: 0;
        }
    }

    &__item--list {
        svg {
            width: 24px;
            height: 20px;
        }
    }

    &__item--grid {
        svg {
            width: 23px;
            height: 23px;
        }
    }

    &__item.is-active {
        pointer-events: none;
        cursor: default;
    }

    &__item:hover {
        &::before {
            transform: scale(1.1, 1.1);
            background-color: $colorBlack;
        }
    }

    &__item.is-active::before {
        background-color: $colorGreenLighter !important;
    }
    &__item.is-active &__link {
        svg g {
            fill: $colorGreenDark !important;
        }
    }

    $this: #{&};
    &__link:hover,
    &__link:active {
        color: $colorWhite;
        svg g {
            fill: currentColor !important;
        }
    }

}