.block-video {

    overflow: hidden;

    img {
        display: block;
        width: 100%;
    }
    
    iframe {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        &.-hidden {
            width: 0;
            height: 0;
        }
    }

    &__player {
        width: 100%;
        position: relative;
        @include aspect(16, 9);
        background-color: $colorGreyLight;
    }

    &__trigger {
        display: block;
        position: absolute;
        top: 0; left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        .icon {
            display: block;
            position: absolute;
            top: 50%; left: 50%;
            width: 28px;
            height: 22px;
            margin-top: -10px;
            margin-left: -12px;
            svg {
                display: block;
                width: 100%;
                height: 100%;
            }
            path {
                fill: $colorWhite;
                transition: fill 0.3s $easeOutCubic;
            }
        }
        &::before {
            content: "";
            display: block;
            width: 64px;
            height: 64px;
            position: absolute;
            top: 50%;
            left: 50%;
            margin: -32px 0 0 -32px;
            border-radius: 100%;
            background-color: $colorGreyDarkest;
            transition-property: transform, background-color;
            transition-duration: 0.4s, 0.2s;
            transition-timing-function: $easeOutQuint, ease;
        }
        &:hover::before,
        &:active::before {
            background-color: $colorBlue;
            body.is-r21 & {
                background-color: $colorRedR21;
            }
            body.is-page & {
                background-color: $colorRedButtonHover;
            }
        }
        &:hover::before {
            //@include beatAnimation;
            transform: scale(1.2);
        }
        body.is-awards &:hover,
        body.is-awards &:active {
            path {
                fill: $colorBlack;
            }
            &::before {
                background-color: $colorYellowButtonHover;
            }
        }
    }

    &__image {
        width: 100%;
        height: 100%;
        top: 0; left: 0;
        position: absolute;
        background-color: $colorGreyLight;
    }
    
    &__meta {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        position: absolute;
        left: 0;
        bottom: 0;
        color: $colorWhite;
        z-index: 1;
        padding: 0 10px 15px 15px;
        &::before {
            content: "";
            display: block;
            position: absolute;
            bottom: 0; left: 0;
            z-index: 1;
            width: 100%;
            height: 100%;
            min-height: 75px;
            opacity: 0.42;
            background-image: linear-gradient(-180deg, rgba(0,0,0,0.00) 2%, #000000 100%);
        }
    }

    &__meta-title {
        display: inline-block;
        font-size: 16px;
        line-height: 1;
        position: relative;
        z-index: 2;
    }

    &__meta-duration {
        font-size: 16px;
        line-height: 1;
        padding-left: 5px;
        @include letter-spacing(0.73, 16);
        position: relative;
        z-index: 2;
    }

    @include bigger-than(m) {

        &__trigger {
            .icon {
                width: 40px;
                height: 54px;
                margin-top: -26px;
                margin-left: -14px;
            }
            &::before {
                width: 120px;
                height: 120px;
                margin: -60px 0 0 -60px;
            }
        }

        &__meta {
            padding: 0 20px 18px;
        }

        &__meta-title {
            font-size: 22px;
            @include letter-spacing(0.5, 22);
        }

        &__meta-duration {
            font-size: 22px;
            @include letter-spacing(1, 22);
            padding-left: 10px;
        }

    }

    & + &,
    .block-images + & {
        padding-top: 0;
    }

    @include bigger-than(m) {

        & + &,
        .block-images + & {
            margin-top: -($gutterMedium/2);
        }

    }

    /*
    *   Modifiers
    *
    */
    $this: #{&};

    &.-nested-article {

        @include bigger-than(l) {

            #{$this}__wrapper {
                width: grid(12, 16);
            }

        }

    }

}