.profile {

    &__header {

        padding-top: 25px;
        padding-bottom: 35px;

        @include bigger-than(m) {
            display: flex;
            padding-top: 40px;
            padding-bottom: 40px;
        }

        &-text {
            > div {
                display: flex;
                width: 100%;
                flex-wrap: wrap;
                align-items: flex-start;
            }
        }

        &-personalia {
            flex: 1 1 auto;
            padding-top: 5px;
            align-self: flex-start;

            @include bigger-than(m) {
                padding-top: 30px;
            }

            p {
                font-size: 16px;
                line-height: 22px;

                @include bigger-than(m) {
                    font-size: 20px;
                    line-height: 26px;
                }
            }
            a {
                color: $colorRedMain;
                text-decoration: underline;
                &:hover,
                &:active {
                    text-decoration: none;
                }
            }
            .heading {
                font-size: 18px;
                line-height: 26px;

                @include bigger-than(m) {
                    font-size: 24px;
                    line-height: 36px;
                }
            }
            .contact {
                padding-top: 5px;

                @include bigger-than(m) {
                    padding-top: 35px;
                }
            }
            .toggle {
                opacity: 0;
                visibility: hidden;
                transition: opacity 0.3s;
                > * {
                    margin-top: 15px;
                }

                @include bigger-than(m) {
                    display: none !important;
                }
            }
        }

        &-image {
            width: 100px;
            height: 100px;
            flex: 0 0 auto;
            margin-right: 20px;

            @include bigger-than(m) {
                width: 177px;
                height: 177px;
                margin-right: 50px;
            }

            .image {
                width: 100%;
                height: 100%;
                border-radius: 100%;
                overflow: hidden;
                background-size: cover;
                @include gpu;
            }
        }

        &-bio {

            font-size: 16px;
            line-height: 22px;
            overflow: hidden;

            @include bigger-than(m) {
                font-size: 18px;
                line-height: 24px;
            }

            em, i {
                font-style: italic;
            }

            strong {
                font-weight: $fontWeightMedium;
            }

            .text {
                overflow: hidden;
                @include smaller-than(m) {
                    max-height: 100%;
                }
                @include bigger-than(m) {
                    padding-top: 15px;
                }
            }

            .text-item {
                overflow: hidden;
                p {
                    margin: 24px 0 0;
                }
                &:first-child {
                    p {
                        margin: 0;
                    }
                    @include bigger-than(m) {
                        display: block !important;
                        height: auto !important;
                        opacity: 1 !important;
                        visibility: visible !important;
                    }
                }
            }

            .toggle {
                margin: 10px 0 0;
                opacity: 0;
                transition: opacity 0.3s;
                visibility: hidden;
                @include smaller-than(m) {
                    display: none !important;
                }
            }
        }
    }

    &__toggle {
        display: flex;
        align-items: center;
        font-size: 16px;
        line-height: 24px;
        color: $colorRedMain;
        width: auto;
        float: left;
        clear: both;
        text-decoration: none !important;
        .icon {
            display: block;
            flex: 0 0 auto;
            width: 14px;
            height: 7px;
            margin-right: 8px;
            position: relative;
            top: 2px;
            svg {
                display: block;
                width: 100%;
                height: 100%;
            }
        }
        &--expand .icon {
            transform: rotate(-180deg);
        }
    }

    &__content {

        overflow: hidden;
        position: relative;

        @include bigger-than(m) {
            top: -24px;
        }

        &-blocks {

            padding-bottom: 25px;

            @include bigger-than(m) {
                padding-bottom: 40px;
            }

            .block.-first {
                padding-top: 0 !important;
            }
            .block.-last {
                padding-bottom: 0 !important;
            }
        }

        &-toggle {
            width: 100%;
            padding-bottom: 25px;
            .grid-item {
                display: flex;
                justify-content: center;
            }
            @include bigger-than(m) {
                padding-bottom: 35px;
                .grid-item {
                    display: block;
                    float: right;
                }
            }
        }
    }

    &__footer {
        height: 0;
        > div {
            border-bottom: 1px solid $colorGreyMedium;
        }
    }

    /*
    *   Collapsed state
    *
     */
    $this: &;
    &:not(:target):not(.js-is-expanded) {

        #{$this}__header {
            @include smaller-than(m) {
                &-personalia .toggle {
                    visibility: visible;
                    opacity: 1 !important;
                }
                &-bio {
                    height: 0;
                }
            }
            @include bigger-than(m) {
                &-bio {
                    .text-item {
                        height: 0;
                        overflow: hidden;
                        visibility: hidden;
                    }
                    .toggle {
                        visibility: visible;
                        opacity: 1 !important;
                    }
                }
            }
        }

        #{$this}__content {
            height: 0;
        }

    }

}
