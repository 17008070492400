.r21-page {

    &.-frontpage {
        padding-top: 35px;
    }

    &__header {
        padding-top: 16px;
        padding-bottom: 20px;
    }

    &__heading {
        font-size: 30px;
        @include letter-spacing(0.93, 30);
        @include line-height(48, 30);
    }

    @include bigger-than(m) {

        &.-frontpage {
            padding-top: 25px;
        }

        &__header {
            padding-top: 45px;
            padding-bottom: 55px;
        }

        &__heading {
            font-size: 48px;
            @include letter-spacing(1.5, 48);
            line-height: 1;
        }

    }

}