// This block should have different color themes based on context
$themes: (
    default: (
        color: $colorWhite,
        background-color: $colorRedMain,
    ),
    awards: (
        color: $colorBlack,
        background-color: $colorYellow,
    )
);

.block-text-nav {

    a {

        display: block;
        color: currentColor;

        @include smaller-than(m) {
            &:hover,
            &:active {
                .label {
                    border-bottom-color: currentColor;
                }
            }
        }

        @include bigger-than(m) {

            html.no-js &:hover .arrow {
                @include bounceArrow;
            }
        }
    }

    &.-first {
        padding-top: 0 !important;
    }

    &.-last {
        padding-bottom: 0 !important;
    }

    .block-banner + & {
        padding-top: 0 !important;
        margin-top: -24px;

        @include bigger-than(m) {
            margin-top: -34px;
        }
    }

    &__inner {
        padding-top: 20px;
        padding-bottom: 33px;
        // Theme it
        @each $key, $theme in $themes {
            @if $key == 'default' {
                @include print($theme);
            }
            @else {
                body.is-#{$key} & {
                    @include print($theme);
                }
            }
        }
        body.is-404 & {
            background-color: transparent;
        }
    }

    &__items {
        width: 100%;
    }

    &__item-inner {
        display: block;
        position: relative;
        &::before {
            content: "";
            display: block;
            width: 100%;
            height: 1px;
            background-color: currentColor;
            opacity: 0.3;
            pointer-events: none;
            position: absolute;
            bottom: 0;
            left: 0;
        }
    }

    &__heading {

        display: block;
        width: 100%;
        font-size: 20px;
        position: relative;
        padding: 14px 60px 20px 0;

        @include letter-spacing(0.28, 20);
        @include line-height(22, 20);

        .label {
            transform-origin: center center;
            border-bottom: 1px solid transparent;
            transition: border-bottom-color .3s $easeOutCubic;
        }

        .arrow {

            display: block;
            width: 40px;
            height: 16px;
            position: absolute;
            top: 50%; right: 2px;
            margin-top: -8px;

            @include smaller-than(m) {
                transform: none !important;
            }

            .arrow__line {
                display: block;
                width: 100%;
                height: 2px;
                background-color: currentColor;
                position: absolute;
                top: 50%;
                margin-top: -1px;
                left: 0;

                @include smaller-than(m) {
                    transform: none !important;
                }
            }
            &__point {
                display: block;
                width: 10px;
                height: 2px;
                position: absolute;
                right: -2px;
                background-color: currentColor;
                &.-upper {
                    transform: rotate(45deg);
                    top: 4px;
                }
                &.-lower {
                    transform: rotate(-45deg);
                    top: 10px;
                }
            }
        }

    }

    @include smaller-than(m) {

        &__description {
            @include screenreader;
        }

    }

    @include bigger-than(m) {

        /*&.-last {
            padding-bottom: 32px !important;
        }*/

        &__inner {
            padding-top: 35px;
            padding-bottom: 50px;
        }

        &__item {
            padding-top: 25px;
            padding-bottom: 25px;
        }

        &__item-inner::before {
            content: none;
        }

        &__heading {

            padding: 0 0 10px;
            font-size: 32px;

            @include letter-spacing(0.44, 32);
            @include line-height(38, 32);

            .arrow {
                width: 50px;
                height: 18px;
                position: relative;
                top: auto;
                right: auto;
                margin-bottom: 10px;
                &__point {
                    width: 12px;
                    right: -4px;
                    &.-upper {
                        transform: rotate(55deg);
                        top: 4px;
                    }
                    &.-lower {
                        transform: rotate(-55deg);
                        top: 12px;
                    }
                }
            }

        }

        &__description {
            font-size: 20px;
            @include line-height(24, 20);
        }

    }

}
