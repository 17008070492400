.awards-index {

    @include bigger-than(l) {

        &__blocks {
            //padding-top: 40px;
            padding-bottom: 40px;
        }

    }

}