.highlighted-video {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: (9/16)*100%;

    &:after {
        content: ' ';
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.0) 40%, rgba(0, 0, 0, 0.5) 100%);
        z-index: 2;
    }
    
    &__image {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 1;

    }

    &__text {
        position: absolute;
        width: 100%;
        padding: 15px;
        bottom: 0;
        left: 0;
        z-index: 3;
        color: $colorWhite;

        @include bigger-than(m) {
            padding: 30px;
        }
    }

    &__meta {
        padding-bottom: 4px;
    }

    &__heading {
        @include bigger-than(m) {
            font-size: 20px;
            line-height: (24/20);
        }
    }

    &__trigger {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 4;
        background: rgba(0, 0, 0, 0.3);
        transition: background 0.3s ease;

        &:hover {
            background: rgba(0, 0, 0, 0.1);
        }

        .icon {
            display: block;
            position: absolute;
            top: 40%;
            left: 50%;
            width: 16px;
            height: 16px;
            margin-top: -6px;
            margin-left: -4px;
            
            @include bigger-than(s) {
                top: 50%;
                width: 20px;
                height: 20px;
                margin-top: -8px;
                margin-left: -6px;
            }
            
            @include bigger-than(m) {
                width: 30px;
                height: 30px;
                margin-top: -13px;
                margin-left: -11px;
            }

            svg {
                display: block;
                width: 100%;
                height: 100%;
            }
            path {
                fill: $colorWhite;
            }
        }
        &::before {
            content: "";
            display: block;
            width: 40px;
            height: 40px;
            position: absolute;
            top: 40%;
            left: 50%;
            margin: -20px 0 0 -20px;
            border-radius: 100%;
            background-color: transparent;
            border: 2px solid $colorWhite;
            
            @include bigger-than(s) {
                top: 50%;
                width: 50px;
                height: 50px;
                margin: -25px 0 0 -25px;
            }
            
            @include bigger-than(m) {
                width: 70px;
                height: 70px;
                margin: -35px 0 0 -35px;
            }
        }
    }
    
    iframe {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 5;
    }
}