.r21-sidebar {
    position: relative;
    //background: url('/assets/build/img/R21_mockup_170330.jpg') no-repeat center center;
    background-size: cover;
    width: 100%;
    height: 0;
    padding-bottom: (470/377) * 100%;

    @include bigger-than(s) {
        padding-bottom: (430/377) * 100%;
    }
    
    @include bigger-than(sp) {
        padding-bottom: (500/770) * 100%;
    }
    
    @include bigger-than(l) {
        width: 100%;
        height: 100%;
    }

    &__link {
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        background: #f0f0f0;
        
        &:hover {
            .r21-sidebar__logo {
                .using-mouse & {
                    transform: scale(1.07);
                }
            }
        }
    }

    &__logo {
        position: absolute;
        width: 40px;
        height: 40px;
        top: -10px;
        left: $gutterSmall - 2px;
        z-index: 4;
        transform: scale(1);
        transition: transform 0.6s $easeOutQuart;

        @include bigger-than(m) {
            left: $gutterMedium - 2px;
            width: 60px;
            height: 60px;
            top: -20px;
        }
        @include bigger-than(mp) {
            left: $gutterMediumPlus - 2px;
        }
        @include bigger-than(l) {
            left: $gutterLarge - 2px;
            top: -10px;
        }

        @include bigger-than(lp) {
            left: $gutterLargePlus - 2px;
            top: -20px;
            width: 75px;
            height: 75px;
        }
        
        svg {
            width: 100%;
            height: 100%;
        }
    }

    &__top-text {
        position: relative;
        z-index: 4;

        padding: 50px $gutterSmall 0 $gutterSmall;
        width: 100%;

        @include bigger-than(m) {
            padding: 60px $gutterMedium 0 $gutterMedium;
        }
        @include bigger-than(l) {
            padding: 70px $gutterLarge 0 $gutterLarge;
        }
    }

    &__bottom-text {
        display: none;

        @include bigger-than(l) {
            display: block;
            width: 100%;
            position: absolute;
            z-index: 4;
            bottom: 0;
            left: 0;
            padding: 0 $gutterLarge 30px $gutterLarge;
            color: #fff;
            font-size: 14px;
            line-height: (18/14);

            > p {
                width: 50%;
                float: left;
                margin: 0;
            }
        }
    }

    &__heading {
        font-size: 18px;
        line-height: (22/18);

        @include bigger-than(m) {
            font-size: 30px;
            line-height: (39/30);
        }
        @include bigger-than(l) {
            font-size: 26px;
            line-height: (32/26);
        }
        
        @include bigger-than(xl) {
            font-size: 32px !important;
            line-height: (42/32) !important;;
        }
        
        @media (min-width: #{$widthL}px) and (max-height: 900px) {
            font-size: 22px;
            line-height: (26/22);
        }
        
        @media (min-width: #{$widthLPlus}px) and (max-height: 900px) {
            font-size: 24px;
            line-height: (28/24);
        }
        @media (min-width: #{$widthLPlus}px) and (min-height: 1200px) {
            font-size: 28px;
            line-height: (36/28);
        }
    }

    &__date-text {
        font-size: 12px;
        line-height: (16/12);
        margin-top: 5px;

        @include bigger-than(m) {
            font-size: 16px;
            line-height: (20/16);
            margin-top: 10px;
        }
        @include bigger-than(l) {
            font-size: 14px;
            line-height: (18/14);
            margin-top: 5px;
        }
        
        @include bigger-than(xl) {
            font-size: 16px;
            line-height: (20/16);
            margin-top: 10px;
        }
    }

    &__opening-hours {
        margin-bottom: 1em;
    }

    &__image-wrapper {
        position: absolute;
        z-index: 1;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: #f0f0f0;
        overflow: hidden;
        opacity: 0;
    }

    &__image {
        position: absolute;
        z-index: 1;
        top: 64%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
        height: 60%;
        width: auto;
        max-width: none;
        
        @include bigger-than(s) {
            top: 60%;
        }

        @include bigger-than(l) {
            width: 70%;
            height: auto;
            max-width: 300px;
            top: 54%;
            left: 50%;
            transform: translateY(-50%) translateX(-50%);
        }

        @include bigger-than(lp) {
            width: 65%;
        }
        
        @media (min-width: #{$widthL}px) and (max-height: 900px) {
            max-width: 180px;
        }
        @media (min-width: #{$widthLPlus}px) and (max-height: 900px) {
            max-width: 220px;
        }
    }

    &__image-inner-shadow {
        position: absolute;
        display: block;
        z-index: 2;
        box-shadow: inset 0 1px 10px rgba(0, 0, 0, 0.3);
    }
    &__image-overlay {
        position: absolute;
        z-index: 3;
        max-width: none;
    }

    &__mask-wrapper {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 4;
        filter: blur(4px);
    }
    
    &__svg-mask {
        position: absolute;
        width: 0;
        height: 0;
    }

    &__svg-masked-element {
        position: absolute;
        display: block;
        z-index: 4;
        top: 60%;
        left: 50%;
        transform: translateY(50%) translateX(-50%) scaleY(-1);
        height: 60%;
        width: auto;
        //margin-top: -4px;
        max-width: none;
        opacity: 0.15;
        
        

        @include bigger-than(l) {
            width: 70%;
            height: auto;
            max-width: 400px;
            top: 50%;
            left: 50%;
            transform: translateY(50%) translateX(-50%) scaleY(-1);
        }

        @include bigger-than(lp) {
            width: 60%;
        }
    }

    &__svg-masked-element image {
        mask: url(#r21SidebarMask);
        width: 100% !important;
        height: auto !important;
    }
}
