// Breakpoints
$widthSMinus: 320;
$widthS: 440;
$widthSPlus: 600;
$widthM: 750;
$widthMPlus: 980;
$widthL: 1200;
$widthLPlus: 1420;
$widthXL: 1800;

$breakpointMap: (
        sm: $widthSMinus,
        s: $widthS,
        sp: $widthSPlus,
        m: $widthM,
        mp: $widthMPlus,
        l: $widthL,
        lp: $widthLPlus,
        xl: $widthXL
);

$gridColumns: 16;

// Sizes
$maxContentWidth: 1500px;

$gutterSmall: 20px;
$gutterMedium: 30px;
$gutterMediumPlus: 30px;
$gutterLarge: 40px;
$gutterLargePlus: 40px;
$gutterXLarge: 40px;

$outerMarginSmall: 20px;
$outerMarginMedium: 30px;
$outerMarginMediumPlus: 30px;
$outerMarginLarge: 60px;
$outerMarginLargePlus: 100px;
$outerMarginXLarge: 150px;

// Header
$headerHeightSmall: 65px;
$headerHeightMediumAward: 72px;
$headerHeightMedium: 90px;

// Color variables
$colorWhite: #fff;
$colorBlack: #373737;
$colorMainText: #373737;
$colorRedMain: #FF5C5C;
$colorRedR21: #FF4747;
$colorRedMegamenu: #FF4747;
$colorRedButtonHover: #FF3333;
$colorBlue: #3333FF;
$colorGreenLight: #39EB9F;
$colorGreenSemiLight: #99EDCD;
$colorGreenLighter: #C3F9E2;
$colorGreenLightest: #D4F5EA;
$colorGreenDark: #2ECF97;
$colorYellow: #FFFF4D;
$colorYellowLight: #FEFECE;
$colorYellowButtonHover: #FFFF00;
$colorBlackButtonHover: #000000;
$colorGreyBackground: #F0F0F0;
$colorGreyLight: #EAEAEA;
$colorGreyMegamenu: #E6E6E6;
$colorGreyMedium: #C8C8C8;
$colorGreyDark: #969696;
$colorGreyDarkest: #373737;

$colorText: $colorMainText;
$colorBackground: $colorWhite;
$colorLink: $colorMainText;

$colorBackgroundAwards: $colorGreyBackground;
$colorBackgroundR21: $colorGreyBackground;

// Color map - added by mmikkel
$colorMap: (
    white: (
            base: $colorWhite,
    ),
    black: (
            base: $colorBlack,
            buttonHover: $colorBlackButtonHover,
    ),
    red: (
            base: $colorRedMain,
            r21: $colorRedR21,
            buttonHover: $colorRedButtonHover,
    ),
    blue: (
            base: $colorBlue,
    ),
    green: (
            base: $colorGreenLight,
            light: $colorGreenLight,
            dark: $colorGreenDark,
    ),
    yellow: (
            base: $colorYellow,
            buttonHover: $colorYellowButtonHover,
    ),
    grey: (
            base: $colorGreyBackground,
            background: $colorGreyBackground,
            megamenu: $colorGreyMegamenu,
    ),
    text: (
            base: $colorMainText,
            link: $colorMainText, // Just use currentColor?
    ),
    background: (
            base: $colorBackground,
    )
);

// Color helper helper - added by mmikkel
@function color($color, $tone: 'base') {
    @if not map-has-key($colorMap, $color) {
        @warn "No colors found for `#{$color}` in $colorMap. Property omitted.";
    }
    @return map-get(map-get($colorMap, $color), $tone);
}

// Fonts
$fontGraphik: 'Graphik', Helvetica, Arial, sans-serif;
$fontCaslon: 'CaslonPro', Georgia, Times, serif;
$fontDefault: $fontGraphik;

$fontWeightLight: 300;
$fontWeightRegular: 400;
$fontWeightMedium: 500;
$fontWeightSemibold: 600;
$fontWeightBold: 700;

// grid
$grid-breakpoints: (s,sp,m,mp,l);
