.block-callout {

    display: flex;
    flex-direction: column;
    align-items: center;

    max-width: 700px + ($outerMarginSmall*2);
    margin: 0 auto;

    position: relative;
    z-index: 1;

    > * {
        z-index: 2;
    }

    @include bigger-than(lp) {
        max-width: 700px + ($outerMarginLargePlus*2);
    }

    @include bigger-than(xl) {
        max-width: 700px + ($outerMarginXLarge*2);
    }

    a {
        &:active,
        html.using-mouse &:hover {
            text-decoration: underline;
        }
    }

    .-last & {
        margin-bottom: 30px;
    }

    &__heading {
        font-size: 28px;
        @include letter-spacing(0.5, 28);
        @include line-height(34, 28);
        padding: 3px 20px;

        @include bigger-than(m) {
            font-size: 32px;
            @include letter-spacing(0.5, 32);
            @include line-height(38, 32);
        }
    }

    &__subtitle {
        font-size: 13px;
        @include letter-spacing(1, 13);
        @include line-height(25, 13);
        padding: 3px 20px;
        @include bigger-than(m) {
            font-size: 14px;
            @include letter-spacing(1.75, 14);
            @include line-height(25, 14);
        }
    }

    &.-colored &__bg {
        width: 150vw;
        height: 100%;
        position: absolute;
        top: 0; left: -50vw;
        z-index: 1;
        background-color: $colorGreyLight;

        body.is-awards &,
        body.is-r21 & {
            background-color: darken($colorGreyLight, 5%);
        }
    }

    body.is-r21 & {
        color: $colorRedR21;
    }

}
