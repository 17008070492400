.global-search {
    &__top {
        padding-top: 30px;
        background: #fff;
        margin-bottom: 30px;
    
        @include bigger-than(m) {
            padding-top: 50px;
            margin-bottom: 60px;
        }
        @include bigger-than(l) {
            padding-top: 60px;
        }
    }
    
    &__heading {
        font-size: 30px;
        line-height: 1;
        letter-spacing: 0.5px;
        margin-bottom: 20px;

        @include bigger-than(m) {
            font-size: 36px;
            margin-bottom: 22px;
        }
        @include bigger-than(l) {
            font-size: 48px;
            letter-spacing: 1.5px;
        }
    }
    
    
    &__form {
        padding-bottom: 30px;

        &-line {
            position: relative;
        }
        
        &-label {
            margin-bottom: 12px;
        }
        
        &-input {
            height: 50px;
            width: 100%;
            border: 1px solid $colorGreyLight;
            padding-right: 70px;
            
            @include bigger-than(m) {
                font-size: 20px;
                padding-left: 20px;
            }
        }

        &-button {
            position: absolute;
            display: block;
            width: 50px;
            height: 50px;
            background: transparent;
            right: 0;
            top: 0;
            
            @include bigger-than(m) {
                right: 5px;
            }
            
            svg {
                position: absolute;
                display: block;
                width: 20px;
                height: 20px;
                left: 15px;
                top: 15px;
            }
        }
    }

    &__no-results {
        padding: 30px 0 200px 0; 
    }
    
    &__winner-search-info {
        font-size: 14px;
        font-style: italic;
        margin-bottom: 40px;
        color: $colorGreyDark;
        
        @include bigger-than(m) {
            font-size: 16px;
        }
        
        a {
            box-shadow: inset 0 -1px 0 currentColor;
        	transition: color 0.2s ease;

            &:hover {
                color: $colorRedMain;
            }
        }
    }
}