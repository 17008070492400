.newsletter-page {

    &__form {

        margin-bottom: 60px;

        &-form {
            display: flex;
            width: 100%;
            flex-wrap: wrap;
            position: relative;
            margin-top: 13px;
            max-width: 550px;
        }

        &-input {
            border: 1px solid $colorGreyMedium;
            font-size: 16px;
            width: auto;
            height: 40px;
            margin-bottom: 15px;
            margin-right: 10px;
            flex: 1 1 auto;
        }

        &-button {
            background: $colorBlack;
            color: $colorWhite;
            display: block;
            height: 40px;
            border-radius: 20px;
            transition: background 0.2s ease;

            padding-left: 30px;
            padding-right: 30px;
            flex: 0 0 auto;

            &:hover {
                background: $colorRedMain;
            }
        }

    }

}
