.fixed-sidebar {
    @include smaller-than(l) {
        position: relative;
        z-index: 2;
        background: #fff;
    }
    
    &__column {

        @include smaller-than(l) {
            display: none;

            padding-right: 0 !important;

            &.-always-show-r21 {
                display: block;
                padding-bottom: 30px;
            }

            .grid-wrap {
                padding-left: 0 !important;
                padding-right: 0 !important;
            }
        }

        @include bigger-than(l) {
            position: fixed;
            width: 100%;
            left: 0;
            top: 0;
            z-index: 90;
        }

        &-inner {
            @include smaller-than(l) {
                padding-left: 0 !important;
                padding-right: 0 !important;

            }
            
            @include bigger-than(l) {
                position: relative;
                height: 0;
            }
        }

        &-content {
            display: none;
            
            &.-is-open {
                display: block;
            }

            @include bigger-than(l) {
                background-color: #fff;
                position: relative;
                top: 115px;
                height: calc(100vh - 115px);
                width: calc(100% + #{$outerMarginLarge});
            }

            @include bigger-than(lp) {
                width: calc(100% + #{$outerMarginLargePlus});
            }

            @include bigger-than(xl) {
                width: calc(100% + #{$outerMarginXLarge});
            }
        }
    }

    &__menu {
        text-align: center;
        margin-top: 76px;
        margin-bottom: 25px;

        @include bigger-than(m) {
            margin-top: 97px;
            margin-bottom: 30px;
        }

        @include smaller-than(l) {
            padding-right: 0 !important;
        }

        @include bigger-than(l) {
            margin-top: 0;
            margin-bottom: 0;
            text-align: left;
            position: fixed;
            width: 100%;
            left: 0;
            top: 46px;
            z-index: 100;
            backface-visibility: hidden;
        }

        &-inner {
            @include bigger-than(l) {
                position: relative;
                height: 0;
                background: #ff0;
            }
        }

        &-list {
            @include bigger-than(l) {
                width: 85%; // keep it from overlapping menu toggle button
            }
        }

        &-item {
            display: inline-block;

            @include bigger-than(l) {
                position: relative;
                padding-right: 12px;
                line-height: 1;

                &:after {
                    content: ' ';
                    display: block;
                    position: absolute;
                    width: 1px;
                    height: 12px;
                    background: $colorBlack;
                    right: 4px;
                    top: 7px;
                }

                &:last-child {
                    padding-right: 0;
                    &:after {
                        display: none;
                    }
                }
            }
        }

        &-link {
            display: inline-block;
            font-size: 13px;
            line-height: 15px;
            letter-spacing: 0.5px;
            border: 2px solid $colorBlack;
            padding: 8px 13px;
            margin: 0 2px;

            @include bigger-than(s) {
                padding: 10px 20px;
                margin: 0 8px;
            }

            @include bigger-than(l) {
                padding: 0;
                margin: 0;
                border: 0;
                font-size: 12px;
                letter-spacing: 1px;
                transition: color 0.2s ease;

                &.-selected,
                &:hover {
                    color: $colorRedMain;
                }
            }

            &.-r21 {
                @include smaller-than(l) {
                    color: $colorRedR21;
                    border-color: $colorRedR21;
                }
            }
            &.-calendar {
                @include smaller-than(l) {
                    color: $colorGreenDark;
                    border-color: $colorGreenDark;
                }
            }
            &.-grafill {

            }

            &-short-text {
                display: none;

                @include bigger-than(l) {
                    display: inline-block;
                }
            }
            &-long-text {
                @include bigger-than(l) {
                    display: none;
                }
            }
        }
    }
}