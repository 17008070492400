/**
* --- Base setup and resets --------------------------------------------------------------------------------------------
*/

* {
    box-sizing: border-box;
}

html {
    font-size: 100%;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    height: 100%;
}

body {
    @include fontGraphik;
    font-variant-ligatures: none;
    font-weight: normal;
    font-size: 16px;
    line-height: (22/16);
    color: $colorText;

    background: $colorBackground;

    position: relative;
    overflow-x: hidden;

    &.is-404 {
        background-color: $colorRedMegamenu;
        min-height: 100vh;
    }

    &.-js-scrolling,
    &.-js-scrolling * {
        pointer-events: none !important;
    }

    @include bigger-than($widthM) {
        font-size: 18px;
        line-height: (25/18);
    }

}

@include breakpointer($breakpointMap);

h1, h2, h3, h4, h5, h6 {
    font-size: 1em;
    display: block;
    margin: 0;
    font-weight: 400;
}

p, small, li {
    display: block;
    margin: 0;
}

ul, ol {
    list-style: none;
    list-style-image: none;
    margin: 0;
    padding: 0;
}

img {
    max-width: 100%;
    border: 0;
    -ms-interpolation-mode: bicubic;
    vertical-align: middle;
    display: inline-block;
    width: 100%;
    height: auto;
}

figure {
    margin: 0;
}

a, input, button {
    -ms-touch-action: none !important;
}

input, textarea {
    -webkit-appearance: none;
    border-radius: 0;
}

input[type="radio"] {
    -webkit-appearance: radio;
}

input[type="checkbox"] {
    -webkit-appearance: checkbox;
}

textarea {
    resize: none;
}

select {
    border-radius: 0;
}

/* input:-webkit-autofill { -webkit-box-shadow: 0 0 0 1000px $colorInputBackground inset; } */
input::-ms-clear {
    display: none;
}

input[type="search"], input[type="text"] {
    -webkit-appearance: none;
    border-radius: 0;
    box-sizing: border-box;
}

fieldset {
    border: 0;
    padding: 0;
    margin: 0;
}

picture {
    display: block;
}

address {
    font-style: normal;
}

a {
    color: inherit;
    text-decoration: none;
}

a:hover {
}

b, strong {
    font-weight: $fontWeightMedium;
}

i, em {
    font-style: normal;
}

button:not(:focus) {
    outline: 0;
}

.no-outline {
    button, a, input, label, textarea, select, option {
        outline: none !important;
    }
}

.page-content {

    position: relative;
    z-index: 1;
    background: $colorBackground;
    padding-top: 65px;
    overflow: hidden;
    //min-height: 900px;

    body.is-404 & {
        background-color: $colorRedMegamenu;
        color: $colorWhite;
    }

    body.is-award-winner &,
    body.is-awards & {
        background: $colorBackgroundAwards;
    }

    body.is-r21 & {
        background: $colorBackgroundR21;
    }
    
    body.is-homepage & {
        padding-top: 0;
    }
    
    @include bigger-than(m) {
        padding-top: $headerHeightMedium;
        
        .is-awards & {
            padding-top: $headerHeightMediumAward;
        }
    }
    
    @include bigger-than(l) {
        body.is-homepage & {
            padding-top: $headerHeightMedium;
        }
    }
}

/* fade image in after load */
.lazyload,
.lazyloading {
    opacity: 0;
    transform: translateZ(0);
}

.lazyloaded {
    opacity: 1;
    transition: opacity 0.3s $easeOutQuad;
}

.no-js {
    img.lazyload {
        display: none;
    }
}

.wysiwyg {
    p {
        margin-bottom: (22/15) * 1em;

        &:last-child {
            margin-bottom: 0;
        }
    }

    a {

    }
}
    

